import {
  ReactHookFormFieldError,
  useReactHookForm
} from '../../../../../../../common/hooks/base/useReactHookForm';

import {
  CertificationFields,
  CertificationName,
  CertificationValidity,
  CertificationStartDate,
  CertificationEndDate,
  CertificationCredentialsId,
  CertificationIssueDate,
  CertificationCompanyId,
  CertificationCityId
} from '../../../../../certificationsTypes';

import { useAddEditCertificationFormValidationRules } from '../useAddEditCertificationFormValidationRules';

import {
  AddEditCertificationFormData,
  OnAddEditCertificationForm
} from '../../AddEditCertificationForm.types';
import { DateUtils } from '../../../../../../../utils/DateUtils';

const defaultAddEditCertificationFormValues: AddEditCertificationFormData = {
  name: '' as CertificationName,
  validity: null,
  startDate: null,
  endDate: null,
  credentialsId: '' as CertificationCredentialsId,
  issueDate: null,
  companyId: null,
  cityId: null
};

interface AddEditCertificationFormOptions {
  defaultValues?: Partial<AddEditCertificationFormData>;
  onAddEditCertificationForm: OnAddEditCertificationForm;
}

function useAddEditCertificationForm({
  defaultValues = {},
  onAddEditCertificationForm
}: AddEditCertificationFormOptions) {
  const {
    control,
    watch,
    errors,
    isLoading,
    isValid,
    isSubmitted,
    isSubmitSuccessful,
    errorMessage,
    register,
    resetForm,
    handleSubmitReactHookForm,
    setValue
  } = useReactHookForm<AddEditCertificationFormData>({
    defaultValues: {
      ...defaultAddEditCertificationFormValues,
      ...defaultValues
    }
  });

  const { nameRules } = useAddEditCertificationFormValidationRules();

  return {
    control,
    watch,
    validationErrors: {
      nameValidationError: (errors.name as ReactHookFormFieldError)?.message,
      validityValidationError: (errors.validity as ReactHookFormFieldError)
        ?.message,
      startDateValidationError: (errors.startDate as ReactHookFormFieldError)
        ?.message,
      endDateValidationError: (errors.endDate as ReactHookFormFieldError)
        ?.message,
      credentialsIdValidationError: (
        errors.credentialsId as ReactHookFormFieldError
      )?.message,
      issueDateValidationError: (errors.issueDate as ReactHookFormFieldError)
        ?.message,
      companyIdValidationError: (errors.companyId as ReactHookFormFieldError)
        ?.message,
      cityIdValidationError: (errors.cityId as ReactHookFormFieldError)?.message
    },
    addEditCertificationFormIsLoading: isLoading,
    addEditCertificationFormIsValid: isValid,
    addEditCertificationFormIsSubmitted: isSubmitted,
    addEditCertificationFormIsSubmitSuccessful: isSubmitSuccessful,
    addEditCertificationFormErrorMessage: errorMessage,
    resetAddEditCertificationForm: resetForm,
    setAddEditCertificationFormValue: setValue,
    registerFields: {
      registerName: register(CertificationFields.NAME, nameRules),
      registerValidity: register(CertificationFields.VALIDITY),
      registerStartDate: register(CertificationFields.START_DATE),
      registerEndDate: register(CertificationFields.END_DATE),
      registerCredentialsId: register(CertificationFields.CREDENTIALS_ID),
      registerIssueDate: register(CertificationFields.ISSUE_DATE),
      registerCompanyId: register(CertificationFields.COMPANY_ID),
      registerCityId: register(CertificationFields.CITY_ID)
    },
    handleAddEditCertificationForm: handleSubmitReactHookForm({
      onSubmit: (data) =>
        onAddEditCertificationForm?.({
          name: data.name as CertificationName,
          validity: data.validity as CertificationValidity,
          startDate: DateUtils.formatDate(
            data.startDate
          ) as CertificationStartDate,
          endDate: DateUtils.formatDate(data.endDate) as CertificationEndDate,
          credentialsId: data.credentialsId as CertificationCredentialsId,
          companyId: data.companyId as CertificationCompanyId,
          issueDate: DateUtils.formatDate(
            data.issueDate
          ) as CertificationIssueDate,
          cityId: data.cityId as CertificationCityId
        })
    })
  };
}

export default useAddEditCertificationForm;

import { Icon, IconProps } from '@chakra-ui/icon';

export const SmileySmileIcon = ({
  w = '16px',
  h = '16px',
  fill = 'currentColor',
  ...props
}: IconProps) => (
  <Icon viewBox="0 0 24 24" w={w} h={h} fill={fill} {...props}>
    <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z" />
    <path d="M14.829 14.828a4.055 4.055 0 01-1.272.858 4.002 4.002 0 01-4.875-1.45l-1.658 1.119a6.063 6.063 0 001.621 1.62 5.963 5.963 0 002.148.903 6.042 6.042 0 002.415 0 5.972 5.972 0 002.148-.903c.313-.212.612-.458.886-.731.272-.271.52-.571.734-.889l-1.658-1.119a4.017 4.017 0 01-.489.592z" />
    <circle cx="8.5" cy="10.5" r="1.5" />
    <circle cx="15.493" cy="10.493" r="1.493" />
  </Icon>
);

import React from 'react';
import { Flex, SimpleGrid } from '@chakra-ui/react';

import {
  FetchCertificationsCacheKey,
  CertificationFields,
  CertificationNanoId,
  MayBeCertificationStartDate,
  MayBeCertificationEndDate,
  MayBeCertificationIssueDate,
  MayBeCertificationValidity,
  CertificationUserId,
  CertificationJobCandidateId,
  CertificationJobResumeRecruiterId
} from '../../../certificationsTypes';

import { JobCandidateId } from '../../../../jobCandidates/jobCandidatesTypes';

import { JobResumeRecruiterId } from '../../../../jobResumeRecruiters/jobResumeRecruitersTypes';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { useCreateCertification } from '../../../hooks/useCreateCertification';
import { useUpdateCertification } from '../../../hooks/useUpdateCertification';

import { useAddEditCertificationForm } from './hooks/useAddEditCertificationForm';

import { SelectCompanyFormField } from '../../../../common/components/formFields/SelectCompanyFormField';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { CollapseFormBox } from '../../../../../helpers/CollapseFormBox';
import { Form } from '../../../../../helpers/Form';
import { InputField } from '../../../../../helpers/forms/formFields/InputField';
import { PureButtonHelper } from '../../../../../helpers/buttons/PureButtonHelper';
import { PureSubmitButtonHelper } from '../../../../../helpers/buttons/PureSubmitButtonHelper';

import { generateNanoId } from '../../../../../utils/generateNanoId';
import { DateUtils } from '../../../../../utils/DateUtils';

import { AddEditCertificationFormCertification } from './AddEditCertificationForm.types';
import { DateInputField } from '../../../../../helpers/forms/formFields/DateInputField';

interface AddEditCertificationFormProps {
  jobCandidateId?: JobCandidateId;
  jobResumeRecruiterId?: JobResumeRecruiterId;
  certification?: AddEditCertificationFormCertification;
  certificationsCacheKey: FetchCertificationsCacheKey;
  type: 'new' | 'edit';
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
}

function AddEditCertificationForm({
  jobCandidateId,
  jobResumeRecruiterId,
  certification,
  certificationsCacheKey,
  type,
  isOpen,
  onClose,
  onSave
}: AddEditCertificationFormProps) {
  const currentUser = useCurrentUser();

  const {
    createCertification,
    createCertificationErrorMessage,
    createCertificationIsLoading
  } = useCreateCertification({
    cacheKeys: [certificationsCacheKey]
  });

  const {
    updateCertification,
    updateCertificationErrorMessage,
    updateCertificationIsLoading
  } = useUpdateCertification({
    certificationNanoId: certification?.nanoId || ('0' as CertificationNanoId),
    cacheKeys: [certificationsCacheKey]
  });

  const {
    control,
    registerFields,
    validationErrors,
    addEditCertificationFormIsLoading,
    addEditCertificationFormErrorMessage,
    handleAddEditCertificationForm
  } = useAddEditCertificationForm({
    defaultValues: certification
      ? {
          name: certification.name,
          validity: DateUtils.toMonthInput(
            certification.validity
          ) as MayBeCertificationValidity,
          startDate: DateUtils.toMonthInput(
            certification.startDate
          ) as MayBeCertificationStartDate,
          endDate: DateUtils.toMonthInput(
            certification.endDate
          ) as MayBeCertificationEndDate,
          credentialsId: certification.credentialsId,
          issueDate: DateUtils.toMonthInput(
            certification.issueDate
          ) as MayBeCertificationIssueDate,
          companyId: certification.companyId,
          cityId: certification.cityId
        }
      : {},
    onAddEditCertificationForm: async (data) => {
      if (certification) {
        await updateCertification(data);
      } else {
        await createCertification({
          ...data,
          nanoId: generateNanoId<CertificationNanoId>(),
          userId: currentUser.id as CertificationUserId,
          jobCandidateId: jobCandidateId as CertificationJobCandidateId,
          jobResumeRecruiterId:
            jobResumeRecruiterId as CertificationJobResumeRecruiterId
        });
      }

      onSave();
    }
  });

  return (
    <CollapseFormBox
      title={type === 'new' ? 'Add Certification' : null}
      isOpen={isOpen}
      titleLevel="h5"
      stackSpacing={4}
    >
      <Form onSubmit={handleAddEditCertificationForm}>
        <SimpleGrid columns={[1, 2]} spacing={6}>
          <InputField
            label="Name"
            placeholder="Name"
            name={CertificationFields.NAME}
            errorMessage={validationErrors.nameValidationError}
            ref={registerFields.registerName.ref}
            onChange={registerFields.registerName.onChange}
          />
          {certification ? (
            <SelectCompanyFormField
              control={control}
              label="Issuing Organization"
              item={certification}
              errorMessage={validationErrors.companyIdValidationError}
            />
          ) : (
            <SelectCompanyFormField
              control={control}
              label="Issuing Organization"
              errorMessage={validationErrors.companyIdValidationError}
            />
          )}
          <InputField
            label="Credentials ID"
            placeholder="Credentials ID"
            name={CertificationFields.CREDENTIALS_ID}
            errorMessage={validationErrors.credentialsIdValidationError}
            ref={registerFields.registerCredentialsId.ref}
            onChange={registerFields.registerCredentialsId.onChange}
          />
          {/*<InputField*/}
          {/*  label="Validity"*/}
          {/*  placeholder="Validity"*/}
          {/*  type="date"*/}
          {/*  name={CertificationFields.VALIDITY}*/}
          {/*  errorMessage={validationErrors.validityValidationError}*/}
          {/*  ref={registerFields.registerValidity.ref}*/}
          {/*  onChange={registerFields.registerValidity.onChange}*/}
          {/*/>*/}
          {/*{certification ? (*/}
          {/*  <SelectCityFormField*/}
          {/*    control={control}*/}
          {/*    item={certification}*/}
          {/*    errorMessage={validationErrors.cityIdValidationError}*/}
          {/*  />*/}
          {/*) : (*/}
          {/*  <SelectCityFormField*/}
          {/*    control={control}*/}
          {/*    errorMessage={validationErrors.cityIdValidationError}*/}
          {/*  />*/}
          {/*)}*/}
          <DateInputField
            label="Issue Date"
            placeholder="Issue Date"
            type="month"
            name={CertificationFields.ISSUE_DATE}
            errorMessage={validationErrors.issueDateValidationError}
            ref={registerFields.registerIssueDate.ref}
            onChange={registerFields.registerIssueDate.onChange}
          />
          {/*<InputField*/}
          {/*  label="From"*/}
          {/*  placeholder="From"*/}
          {/*  type="date"*/}
          {/*  name={CertificationFields.START_DATE}*/}
          {/*  errorMessage={validationErrors.startDateValidationError}*/}
          {/*  ref={registerFields.registerStartDate.ref}*/}
          {/*  onChange={registerFields.registerStartDate.onChange}*/}
          {/*/>*/}
          {/*<InputField*/}
          {/*  label="To"*/}
          {/*  placeholder="To"*/}
          {/*  type="date"*/}
          {/*  name={CertificationFields.END_DATE}*/}
          {/*  errorMessage={validationErrors.endDateValidationError}*/}
          {/*  ref={registerFields.registerEndDate.ref}*/}
          {/*  onChange={registerFields.registerEndDate.onChange}*/}
          {/*/>*/}
        </SimpleGrid>
        <SimpleGrid columns={1} spacing={6}>
          <AlertMessage
            message={
              addEditCertificationFormErrorMessage ||
              createCertificationErrorMessage ||
              updateCertificationErrorMessage
            }
          />
        </SimpleGrid>
        <Flex justifyContent="end" gap={4}>
          <PureButtonHelper
            hierarchy="tertiary"
            size="small"
            px={4}
            i18nText="Cancel"
            onClick={onClose}
          />
          <PureSubmitButtonHelper
            hierarchy="primary"
            size="small"
            px={4}
            i18nText={type === 'new' ? 'Add' : 'Update'}
            isLoading={
              addEditCertificationFormIsLoading ||
              createCertificationIsLoading ||
              updateCertificationIsLoading
            }
          />
        </Flex>
      </Form>
    </CollapseFormBox>
  );
}

export default AddEditCertificationForm;

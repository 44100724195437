import { Icon, IconProps } from '@chakra-ui/icon';

export const SmileySadIcon = ({
  w = '16px',
  h = '16px',
  fill = 'currentColor',
  ...props
}: IconProps) => (
  <Icon viewBox="0 0 24 24" w={w} h={h} fill={fill} {...props}>
    <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z" />
    <path d="M14.829 16.408A4.002 4.002 0 008.682 17l-1.658-1.119a6.063 6.063 0 011.62-1.62c.65-.44 1.38-.746 2.149-.903a6.043 6.043 0 012.415 0 5.97 5.97 0 013.034 1.634c.272.271.52.571.734.889L15.318 17a4.013 4.013 0 00-.49-.592zM8.5 12a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM15.493 11.986a1.493 1.493 0 100-2.986 1.493 1.493 0 000 2.986z" />
  </Icon>
);

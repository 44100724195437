import {
  Collapse,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
  useDisclosure
} from '@chakra-ui/react';
import { MouseEvent, ReactNode } from 'react';
import { ChevronDownIcon } from '../../icons/ChevronDownIcon';
import { Button } from '../Button';
import { TextareaField } from '../forms/formFields/TextareaField';
import { Text } from '../Text';
import { renderCandidateOverallEvaluation } from './utils/renderCandidateOverallEvaluation';
import {
  CandidateOverallEvaluationType,
  CandidateOverallEvaluationEnums
} from './CandidateOverallEvaluation.types';

interface CandidateOverallEvaluationProps {
  label?: ReactNode;
  placeholder?: string;
  commentLabel?: string;
  showCommentLabel?: boolean;
  commentPlaceholder?: string;
  showComment?: 'always' | 'on-selection' | 'never';
  layout?: 'row' | 'column';
  overallEvaluation: CandidateOverallEvaluationType;
  setOverallEvaluation: (
    overallEvaluation: CandidateOverallEvaluationType
  ) => void;
  isDisabled?: boolean;
}

export default function CandidateOverallEvaluation({
  label,
  placeholder,
  commentLabel,
  commentPlaceholder,
  showCommentLabel = true,
  showComment = 'always',
  layout = 'row',
  overallEvaluation,
  setOverallEvaluation,
  isDisabled
}: CandidateOverallEvaluationProps) {
  const {
    isOpen: isOpenDropdown,
    onToggle: onToggleDropdown,
    onClose: onCloseDropdown
  } = useDisclosure();

  const overallEvaluationArray = Object.values(
    CandidateOverallEvaluationEnums
  ) as CandidateOverallEvaluationType[];

  const changeEvaluation = (e: MouseEvent<HTMLButtonElement>) => {
    const { value } = e.currentTarget;

    setOverallEvaluation(value as CandidateOverallEvaluationType);

    onCloseDropdown();
  };

  return (
    <Stack spacing={2}>
      <Popover
        isOpen={isOpenDropdown}
        onClose={onCloseDropdown}
        placement="bottom-start"
        isLazy
      >
        <Flex flex={1} flexDir={layout} gap={2}>
          {label ? label : null}
          <PopoverTrigger>
            <Flex>
              <Button
                size="medium"
                onClick={(e) => {
                  e.stopPropagation();
                  !isDisabled && onToggleDropdown();
                }}
                hierarchy="tertiary"
                aria-label="Dropdown"
              >
                <Flex gap={1} alignItems="center" fontSize="inherit">
                  <Text
                    fontWeight="medium"
                    color={
                      !label ?? overallEvaluation ? 'gray.700' : 'gray.600'
                    }
                  >
                    {overallEvaluation
                      ? renderCandidateOverallEvaluation(overallEvaluation)
                      : placeholder ?? 'Select overall evaluation'}
                  </Text>
                  <ChevronDownIcon fill="gray.500" />
                </Flex>
              </Button>
            </Flex>
          </PopoverTrigger>
        </Flex>

        <Portal>
          <PopoverContent minW={60} w={60} p={1} maxH={60} overflow="hidden">
            <Flex flexDir="column" overflow="auto">
              {overallEvaluationArray.map((value) => (
                <Button
                  px={2}
                  py={1}
                  h="auto"
                  size="small"
                  color="inherit"
                  textAlign="left"
                  lineHeight={1.5}
                  fontSize="inherit"
                  borderRadius="none"
                  fontWeight="inherit"
                  hierarchy="unstyled"
                  isDisabled={overallEvaluation === value}
                  onClick={(e) => {
                    e.stopPropagation();
                    changeEvaluation(e);
                  }}
                  value={value}
                  key={value}
                  _hover={{
                    bg: 'gray.100'
                  }}
                >
                  {renderCandidateOverallEvaluation(value)}
                </Button>
              ))}
            </Flex>
          </PopoverContent>
        </Portal>
      </Popover>

      {showComment && (
        <Collapse
          in={
            showComment === 'always' ||
            (showComment === 'on-selection' && !!overallEvaluation)
          }
          unmountOnExit
        >
          <Stack spacing={2} p={0.5}>
            {showCommentLabel && (
              <Text textStyle="body1Medium">
                {commentLabel ?? 'Add a comment'}
              </Text>
            )}

            <TextareaField
              rows={5}
              bg="white"
              autoFocus
              placeholder={commentPlaceholder}
            />
          </Stack>
        </Collapse>
      )}
    </Stack>
  );
}

import { Box, Flex, HStack, Stack, Tooltip } from '@chakra-ui/react';
import { AvatarHelper } from '../../../../../../helpers/AvatarHelper';
import { Button } from '../../../../../../helpers/Button';
import { ButtonWithRef } from '../../../../../../helpers/ButtonWithRef';
import { Heading } from '../../../../../../helpers/Heading';
import { NextLinkHelper } from '../../../../../../helpers/NextLinkHelper';
import { RelativeDateHelper } from '../../../../../../helpers/RelativeDateHelper';
import { Text } from '../../../../../../helpers/Text';
import { WorldGlobeIcon } from '../../../../../../icons/WorldGlobeIcon';
import { JobLocationHelper } from '../../../../../jobs/helpers/JobLocationHelper';
import { JobCompany, JobNanoId } from '../../../../../jobs/jobsTypes';
import { FetchJobsResponse } from '../../../../../jobs/queries/fetchJobs.query';
import { ApplyNowButton } from '../../../CareerSiteJobsPage/helpers/ApplyNowButton';

export type CareerSiteApplyPageMoreJobsType = {
  jobTitle: string;
  company: JobCompany;
  jobType: string;
  date: string;
  department: string;
  job: FetchJobsResponse;
  applied?: boolean;
  isSelected?: boolean;
  jobNanoId: JobNanoId;
  goToJobDetails?: (nanoId: JobNanoId) => void;
  jobDetailsUrl?: (nanoId: JobNanoId) => string;
};

const CareerSiteApplyPageMoreJobs = ({
  job,
  applied,
  company,
  jobType,
  jobTitle,
  jobNanoId,
  department,
  isSelected,
  jobDetailsUrl,
  goToJobDetails
}: CareerSiteApplyPageMoreJobsType) => {
  return (
    <Stack
      spacing={0}
      borderRadius={4}
      border={isSelected ? '2px solid' : '1px solid'}
      borderColor={isSelected ? 'primary.200' : 'gray.200'}
      bg={applied ? 'white' : 'primary.100'}
    >
      <Stack spacing={3} p={3}>
        <Flex gap={2} alignItems="center">
          <AvatarHelper image={company.image} name={company.name} size="sm" />

          <Text textStyle="body1Medium" fontWeight="bold" color="gray.700">
            {company.name}
          </Text>
        </Flex>

        <Stack spacing={4}>
          <Stack spacing={1}>
            <Text textStyle="upperCase1Regular" color="gray.600">
              {department} - {jobType}
            </Text>

            <Tooltip label={jobTitle}>
              <Box>
                <Heading level="h4" color="gray.700" noOfLines={1}>
                  {jobTitle}
                </Heading>
              </Box>
            </Tooltip>
          </Stack>

          {job?.cities && (
            <HStack spacing={3}>
              <JobLocationHelper job={job} />
            </HStack>
          )}

          <Stack direction="row" spacing={4}>
            {jobDetailsUrl ? (
              <NextLinkHelper href={jobDetailsUrl(jobNanoId)} passHref>
                <ButtonWithRef
                  as="a"
                  w="full"
                  size="small"
                  hierarchy="secondary"
                >
                  View {applied ? 'Job Profile' : 'Details'}
                </ButtonWithRef>
              </NextLinkHelper>
            ) : (
              <Button
                size="small"
                w="full"
                hierarchy="secondary"
                onClick={() => goToJobDetails && goToJobDetails(jobNanoId)}
              >
                View Details
              </Button>
            )}

            {!applied && (
              <Box w="full">
                <ApplyNowButton jobNanoId={jobNanoId} width="full" />
              </Box>
            )}
          </Stack>
        </Stack>
      </Stack>

      <OtherJobsFooter status={applied ? 'applied' : 'published'} date="" />
    </Stack>
  );
};

export default CareerSiteApplyPageMoreJobs;

type OtherJobsFooterType = {
  status: 'applied' | 'published';
  date: string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const OtherJobsFooter = ({ status, date }: OtherJobsFooterType) => (
  <Flex
    as="footer"
    alignItems="center"
    borderTop="1px solid"
    borderTopColor="gray.200"
    px={4}
    py={2}
    gap={4}
  >
    <Flex textTransform="capitalize" alignItems="center" gap={2}>
      <WorldGlobeIcon color={'gray.500'} />
      {status}
    </Flex>

    {date && (
      <Flex ml="auto">
        <RelativeDateHelper date={date} />
      </Flex>
    )}
  </Flex>
);

import { SimpleGrid, Stack } from '@chakra-ui/react';
import size from 'lodash/size';
import take from 'lodash/take';
import { AlertMessage } from '../../../../../../helpers/AlertMessage';
import { Heading } from '../../../../../../helpers/Heading';
import { LoadingSkeleton } from '../../../../../../helpers/LoadingSkeleton';
import { JobsCache } from '../../../../../jobs/JobsCache';
import { usePaginatedJobs } from '../../../../../jobs/hooks/usePaginatedJobs';
import {
  FetchJobsCountType,
  FetchJobsPageSize,
  JobNanoId
} from '../../../../../jobs/jobsTypes';
import { fetchJobQuery } from '../../../../../jobs/queries/fetchJob.query';
import {
  FetchJobsResponse,
  fetchJobsQuery
} from '../../../../../jobs/queries/fetchJobs.query';
import { renderJobTitle } from '../../../../../jobs/utils/renderJobTitle';
import { CareerSiteApplyPageMoreJobs } from '../CareerSiteApplyPageMoreJobs';

interface CareerSiteApplyPageAppliedJobsProps {
  jobsToShow?: number;
  currentJobNanoId: JobNanoId;
  goToJobDetails?: (nanoId: JobNanoId) => void;
  jobDetailsUrl?: (nanoId: JobNanoId) => string;
}

const CareerSiteApplyPageAppliedJobs = ({
  jobDetailsUrl,
  goToJobDetails,
  currentJobNanoId,
  jobsToShow = 2
}: CareerSiteApplyPageAppliedJobsProps) => {
  const { jobs, jobsErrorMessage, jobsIsFetched } =
    usePaginatedJobs<FetchJobsResponse>({
      cacheKey: JobsCache.indexCacheKey(),
      query: fetchJobsQuery,
      countType: 'exact' as FetchJobsCountType,
      placeholderJobs: [],
      initialPageSize: jobsToShow as FetchJobsPageSize,
      fetchItemCacheKey: JobsCache.showCacheKey(),
      itemQuery: fetchJobQuery
    });

  const truncatedJobs = take(jobs, jobsToShow);

  return (
    <LoadingSkeleton loaded={jobsIsFetched}>
      <AlertMessage message={jobsErrorMessage} />

      {size(jobs) ? (
        <Stack w="100%" pt={4}>
          <Heading level="h4">Jobs Applied</Heading>

          <SimpleGrid columns={{ base: 1, sm: 2, lg: 1 }} spacing={2}>
            {truncatedJobs.map((job) => (
              <CareerSiteApplyPageMoreJobs
                key={job.nanoId}
                jobTitle={renderJobTitle(job)}
                date={job.createdAt}
                company={job.company}
                department={job.department?.name || '-'}
                job={job}
                jobNanoId={job.nanoId}
                jobType={''}
                goToJobDetails={goToJobDetails}
                jobDetailsUrl={jobDetailsUrl}
                isSelected={job.nanoId === currentJobNanoId}
                applied
              />
            ))}
          </SimpleGrid>
        </Stack>
      ) : null}
    </LoadingSkeleton>
  );
};

export default CareerSiteApplyPageAppliedJobs;

import { useCallback, useState } from 'react';
import { Stack, SystemProps } from '@chakra-ui/react';

import { FetchCertificationsCacheKey } from '../../../certificationsTypes';

import { useDeleteCertification } from '../../../hooks/useDeleteCertification';

import { EditDeleteCertificationItem } from '../../../helpers/EditDeleteCertificationItem';

import { AddEditCertificationForm } from '../../forms/AddEditCertificationForm';

import { AlertMessage } from '../../../../../helpers/AlertMessage';

import { EditCertificationListItemCertification } from './EditCertificationListItem.types';

interface EditCertificationListItemProps {
  certification: EditCertificationListItemCertification;
  certificationsCacheKey: FetchCertificationsCacheKey;
  stackSpacing?: SystemProps['margin'];
  editable?: boolean;
  deletable?: boolean;
}

function EditCertificationListItem({
  certification,
  certificationsCacheKey,
  stackSpacing = '6',
  editable = true,
  deletable = true
}: EditCertificationListItemProps) {
  const {
    deleteCertification,
    deleteCertificationErrorMessage,
    deleteCertificationIsLoading
  } = useDeleteCertification({
    certificationNanoId: certification.nanoId,
    cacheKeys: [certificationsCacheKey]
  });

  const [editCertificationFormIsOpen, setEditCertificationFormIsOpen] =
    useState<boolean>(false);

  const handleEditCertificationFormOpen = useCallback(
    () => setEditCertificationFormIsOpen(true),
    [setEditCertificationFormIsOpen]
  );

  const handleEditCertificationFormClose = useCallback(
    () => setEditCertificationFormIsOpen(false),
    [setEditCertificationFormIsOpen]
  );

  const handleDeleteCertification = useCallback<() => void>(
    () => deleteCertification({}),
    [deleteCertification]
  );

  return (
    <Stack spacing={stackSpacing}>
      <AlertMessage message={deleteCertificationErrorMessage} />
      <EditDeleteCertificationItem
        certification={certification}
        deleteCertificationIsLoading={deleteCertificationIsLoading}
        onDeleteCertification={handleDeleteCertification}
        onEditCertification={handleEditCertificationFormOpen}
        editable={editable}
        deletable={deletable}
      />
      {editCertificationFormIsOpen && (
        <AddEditCertificationForm
          type="edit"
          jobCandidateId={certification.jobCandidateId}
          certification={certification}
          certificationsCacheKey={certificationsCacheKey}
          isOpen={editCertificationFormIsOpen}
          onClose={handleEditCertificationFormClose}
          onSave={handleEditCertificationFormClose}
        />
      )}
    </Stack>
  );
}

export default EditCertificationListItem;

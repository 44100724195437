import { HStack } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { FilesIcon } from '../../../../../../../../icons/FilesIcon';
import { RecruitersIcon } from '../../../../../../../../icons/RecruitersIcon';
import { JobResumeRecruiterNanoId } from '../../../../../../../jobResumeRecruiters/jobResumeRecruitersTypes';
import { JobNanoId } from '../../../../../../../jobs/jobsTypes';
import { ViewJobCandidateDetailsPageNavItem } from '../../../../../../../jobs/pages/ViewJobCandidateDetailsPage/components/ViewJobCandidateDetailsPageNavItem';
import { CareerSiteRoutes } from '../../../../../../CareerSiteRoutes';

interface CareerSiteApplyProfilePageNavProps {
  jobNanoId: JobNanoId;
  jobResumeRecruiterNanoId: JobResumeRecruiterNanoId;
}

export default function CareerSiteApplyProfilePageNav({
  jobNanoId,
  jobResumeRecruiterNanoId
}: CareerSiteApplyProfilePageNavProps) {
  const router = useRouter();

  const isProjects = router.pathname.includes('/projects');
  const isFiles = router.pathname.includes('/files');

  const isProfile =
    router.pathname.includes('/profile') && !isProjects && !isFiles;

  return (
    <HStack
      h={16}
      py={3}
      top={0}
      zIndex={9}
      spacing={2}
      overflow="auto"
      pos="sticky"
      bg={{ base: 'white', lg: 'gray.50' }}
      px={0}
    >
      <ViewJobCandidateDetailsPageNavItem
        label="Profile"
        icon={RecruitersIcon}
        isActive={isProfile}
        url={CareerSiteRoutes.profile(jobNanoId, jobResumeRecruiterNanoId)}
      />

      <ViewJobCandidateDetailsPageNavItem
        icon={FilesIcon}
        label="Files"
        isActive={isFiles}
        url={CareerSiteRoutes.profileFiles(jobNanoId, jobResumeRecruiterNanoId)}
      />
    </HStack>
  );
}

import React, { ReactNode } from 'react';
import { JobSatisfactionFieldControl } from './components/JobSatisfactionFieldControl';
import { Controller, FieldValues } from 'react-hook-form';
import { FlexProps, UseRadioGroupProps, ColorProps } from '@chakra-ui/react';
import {
  SelectFieldReactHookFormControl,
  SelectFieldReactHookFormFieldPath,
  SelectFieldReactHookFormRules
} from '../SelectField';

interface JobSatisfactionFieldProps<T extends FieldValues>
  extends Omit<FlexProps, 'children' | 'defaultValue' | 'onChange'>,
    UseRadioGroupProps {
  options?: {
    label?: ReactNode;
    value: string;
  }[];
  label: string;
  errorMessage?: string;
  getColor?: (value: string) => ColorProps['color'];
  control: SelectFieldReactHookFormControl<T>;
  name: SelectFieldReactHookFormFieldPath<T>;
  rules?: SelectFieldReactHookFormRules<T>;
}

export default function JobSatisfactionField<T extends FieldValues>({
  name,
  rules,
  control,
  errorMessage,
  ...props
}: JobSatisfactionFieldProps<T>) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value, ref } }) => (
        <JobSatisfactionFieldControl
          errorMessage={errorMessage}
          onChange={onChange}
          value={value}
          ref={ref}
          {...props}
        />
      )}
    />
  );
}

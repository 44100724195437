import {
  FetchCertificationsCacheKey,
  FetchCertificationCacheKey,
  UpdateCertificationNanoId,
  UpdateCertificationErrorMessage,
  UpdateCertificationIsLoading
} from '../../certificationsTypes';

import {
  CertificationsBffRequests,
  UpdateCertificationRequestData,
  UpdateCertificationErrorResponse
} from '../../CertificationsBffRequests';

import { useUpdateItem } from '../../../common/hooks/useUpdateItem';

interface UpdateCertificationOptions {
  certificationNanoId: UpdateCertificationNanoId;
  cacheKeys?: (FetchCertificationsCacheKey | FetchCertificationCacheKey)[];
}

function useUpdateCertification({
  certificationNanoId,
  cacheKeys = []
}: UpdateCertificationOptions) {
  const {
    updateItemData,
    updateItemError,
    updateItemErrorMessage,
    updateItemIsLoading,
    updateItem,
    updateItemReset
  } = useUpdateItem<
    UpdateCertificationRequestData,
    UpdateCertificationErrorResponse
  >({
    mutationFn: (queryInput) =>
      CertificationsBffRequests.updateCertification(
        certificationNanoId,
        queryInput
      ),
    cacheKeys
  });

  return {
    updateCertificationData: updateItemData,
    updateCertificationError: updateItemError,
    updateCertificationErrorMessage:
      updateItemErrorMessage as UpdateCertificationErrorMessage,
    updateCertificationIsLoading:
      updateItemIsLoading as UpdateCertificationIsLoading,
    updateCertification: updateItem,
    updateCertificationReset: updateItemReset
  };
}

export default useUpdateCertification;

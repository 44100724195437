import React from 'react';
import { TalentPoolFilterPopupSection } from '../../TalentPoolFilterPopupSection';
import { TalentPoolFilterPopupSectionItem } from '../../TalentPoolFilterPopupSectionItem';

export default function TalentPoolFilterPopupHiringManagerSection() {
  return (
    <TalentPoolFilterPopupSection title="Hiring Manager">
      <TalentPoolFilterPopupSectionItem
        label="Users"
        fontSize="sm"
        fontWeight="medium"
      />
    </TalentPoolFilterPopupSection>
  );
}

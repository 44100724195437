import { Avatar, Flex, HStack, Stack } from '@chakra-ui/react';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';

import { IsLoading } from '../../../../types';

import { DotIcon } from '../../../../icons/DotIcon';
import { EducationIcon } from '../../../../icons/Placeholders/EducationIcon';

import { Heading } from '../../../../helpers/Heading';
import { Text } from '../../../../helpers/Text';
import { EditDeleteButtonGroup } from '../../../../helpers/EditDeleteButtonGroup';

import { renderLocation } from '../../../common/utils/renderLocation';

import { DateUtils } from '../../../../utils/DateUtils';

import { EditDeleteEducationItemEducation } from './EditDeleteEducationItem.types';

interface EditDeleteEducationItemProps {
  education: EditDeleteEducationItemEducation;
  deleteEducationIsLoading: IsLoading;
  editable?: boolean;
  deletable?: boolean;
  onEditEducation: () => void;
  onDeleteEducation: () => void;
}

function EditDeleteEducationItem({
  education,
  deleteEducationIsLoading,
  editable,
  deletable,
  onEditEducation,
  onDeleteEducation
}: EditDeleteEducationItemProps) {
  const location = renderLocation(education);
  return (
    <Flex gap={4} alignItems="center">
      <Avatar size="md" icon={<EducationIcon />} />

      <Stack spacing={1}>
        <HStack spacing={4}>
          <Heading
            flex={1}
            w="100%"
            level="h5"
            color="gray.900"
            textTransform="capitalize"
            fontWeight={500}
          >
            {compact([education.degree, education.studyField]).join(', ')}
          </Heading>

          <Text textStyle="body2Regular" color="gray.600" ml="auto">
            {compact([
              DateUtils.formatMonth(education.startDate),
              DateUtils.formatMonth(education.endDate)
            ]).join(' - ')}
          </Text>
        </HStack>

        <HStack
          spacing={2}
          divider={<DotIcon h="4px" w="4px" color="gray.600" />}
        >
          {isEmpty(education.schoolName) ? null : (
            <Text textStyle="body1Regular" color="gray.600">
              {education.schoolName}
            </Text>
          )}
          {location ? (
            <Text textStyle="body1Regular" color="gray.600">
              {location}
            </Text>
          ) : null}
        </HStack>
      </Stack>

      <Flex ml="auto">
        {editable && deletable ? (
          <EditDeleteButtonGroup
            deleteDisabled={deleteEducationIsLoading}
            onEdit={onEditEducation}
            onDelete={onDeleteEducation}
          />
        ) : null}
        {editable && !deletable ? (
          <EditDeleteButtonGroup onEdit={onEditEducation} withoutDeleteButton />
        ) : null}
        {deletable && !editable ? (
          <EditDeleteButtonGroup
            deleteDisabled={deleteEducationIsLoading}
            onDelete={onDeleteEducation}
            withoutEditButton
          />
        ) : null}
      </Flex>
    </Flex>
  );
}

export default EditDeleteEducationItem;

import { Badge, Flex, SimpleGrid, Stack } from '@chakra-ui/react';
import size from 'lodash/size';
import take from 'lodash/take';
import { AlertMessage } from '../../../../../../helpers/AlertMessage';
import { Heading } from '../../../../../../helpers/Heading';
import { LoadingSkeleton } from '../../../../../../helpers/LoadingSkeleton';
import { JobsCache } from '../../../../../jobs/JobsCache';
import { usePaginatedJobs } from '../../../../../jobs/hooks/usePaginatedJobs';
import {
  FetchJobsCountType,
  FetchJobsFilters,
  FetchJobsPageSize,
  JobFields,
  JobNanoId,
  JobStatuses
} from '../../../../../jobs/jobsTypes';
import { fetchJobQuery } from '../../../../../jobs/queries/fetchJob.query';
import {
  FetchJobsResponse,
  fetchJobsQuery
} from '../../../../../jobs/queries/fetchJobs.query';
import { renderJobTitle } from '../../../../../jobs/utils/renderJobTitle';
import { CareerSiteApplyPageMoreJobs } from '../CareerSiteApplyPageMoreJobs';

interface CareerSiteApplyPageNewJobsProps {
  jobsToShow?: number;
  goToJobDetails?: (nanoId: JobNanoId) => void;
  jobDetailsUrl?: (nanoId: JobNanoId) => string;
}

const CareerSiteApplyPageNewJobs = ({
  jobsToShow = 2,
  goToJobDetails,
  jobDetailsUrl
}: CareerSiteApplyPageNewJobsProps) => {
  const { jobs, jobsTotalCount, jobsErrorMessage, jobsIsFetched } =
    usePaginatedJobs<FetchJobsResponse>({
      cacheKey: JobsCache.indexCacheKey(),
      query: fetchJobsQuery,
      countType: 'exact' as FetchJobsCountType,
      placeholderJobs: [],
      initialPageSize: 5 as FetchJobsPageSize,
      initialFilters: {
        [JobFields.STATUS]: {
          operator: 'eq',
          value: JobStatuses.PUBLISHED
        }
      } as unknown as FetchJobsFilters,
      fetchItemCacheKey: JobsCache.showCacheKey(),
      itemQuery: fetchJobQuery
    });

  const truncatedJobs = take(jobs, jobsToShow);

  return (
    <LoadingSkeleton loaded={jobsIsFetched}>
      <AlertMessage message={jobsErrorMessage} />

      {size(jobs) ? (
        <Stack w="100%" pb={4}>
          <Heading level="h4" alignItems="center">
            <Flex gap={1}>
              New Jobs{' '}
              <Badge
                p={0}
                w={7}
                h={7}
                size="sm"
                variant="solid"
                colorScheme="red"
                textAlign="center"
                borderRadius="full"
              >
                {jobsTotalCount}
              </Badge>
            </Flex>
          </Heading>

          <SimpleGrid columns={{ base: 1, sm: 2, lg: 1 }} spacing={2}>
            {truncatedJobs.map((job) => (
              <CareerSiteApplyPageMoreJobs
                job={job}
                key={job.nanoId}
                date={job.createdAt}
                jobTitle={renderJobTitle(job)}
                company={job.company}
                department={job.department?.name || '-'}
                jobNanoId={job.nanoId}
                goToJobDetails={goToJobDetails}
                jobDetailsUrl={jobDetailsUrl}
                jobType={''}
              />
            ))}
          </SimpleGrid>
        </Stack>
      ) : null}
    </LoadingSkeleton>
  );
};

export default CareerSiteApplyPageNewJobs;

import { Stack } from '@chakra-ui/react';
import { useState } from 'react';
import { Heading } from '../../../../../../helpers/Heading';
import { useViewJobCandidateDetailsSubmissionContext } from '../../contexts/ViewJobCandidateDetailsSubmissionContext';
import { JobScorecardQuestion } from '../../../../../jobScorecards/components/JobScorecardQuestion';
import {
  JobScorecardComments,
  JobScorecardOverallEvaluation
} from '../../../../../jobScorecards/jobScorecardsTypes';
import {
  CandidateOverallEvaluation,
  CandidateOverallEvaluationType
} from '../../../../../../helpers/CandidateOverallEvaluation';
import { TextareaField } from '../../../../../../helpers/forms/formFields/TextareaField';

export default function CustomiseCandidateEvaluation() {
  const { assessmentScorecard } = useViewJobCandidateDetailsSubmissionContext();
  const [overallEvaluation, setOverallEvaluation] = useState(
    assessmentScorecard?.overallEvaluation
  );
  const [comment, setComment] = useState(assessmentScorecard?.comments);

  return (
    <Stack spacing={5}>
      <Heading level="h5" textStyle="body1Medium" fontWeight="semibold">
        Recruiter Assessment Review
      </Heading>

      <Stack spacing={2}>
        {assessmentScorecard?.jobScorecardQuestions.map((question, index) => (
          <JobScorecardQuestion
            key={index}
            {...question}
            state={'view'}
            edit={true}
            jobId={assessmentScorecard.jobId}
          />
        ))}
      </Stack>

      <CandidateOverallEvaluation
        showCommentLabel={false}
        showComment="never"
        overallEvaluation={
          overallEvaluation as unknown as CandidateOverallEvaluationType
        }
        setOverallEvaluation={(overallEval: CandidateOverallEvaluationType) =>
          setOverallEvaluation(
            overallEval as unknown as JobScorecardOverallEvaluation
          )
        }
        isDisabled={false}
      />

      <TextareaField
        bg="white"
        height="200px"
        label="Add a comment"
        value={comment}
        isReadOnly={false}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          setComment(e.target.value as JobScorecardComments);
        }}
      />
    </Stack>
  );
}

import { Icon, IconProps } from '@chakra-ui/icon';

const EllipsisIcon = ({
  w = '16px',
  h = '16px',
  fill = '#98A5A9',
  ...props
}: IconProps) => (
  <Icon
    viewBox="0 0 16 16"
    w={w}
    h={h}
    fill={fill || 'currentColor'}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.133 12.67h-8.2a.666.666 0 0 1-.533-1.066l1.467-2.267c.266-.333.733-.4 1-.133l1.466 1.466L9.4 8.07c.267-.333.867-.333 1.067.067l2.2 3.533c.333.4 0 1-.534 1ZM6.666 3.336C7.4 3.336 8 3.936 8 4.669c0 .734-.6 1.334-1.334 1.334-.733 0-1.333-.6-1.333-1.334 0-.733.6-1.333 1.333-1.333ZM15.333 0H.667C.267 0 0 .267 0 .667v14.666c0 .4.267.667.667.667h14.666c.4 0 .667-.267.667-.667V.667c0-.4-.267-.667-.667-.667Z"
    />
  </Icon>
);
export default EllipsisIcon;

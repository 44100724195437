import { Stack, StackDivider, SystemProps } from '@chakra-ui/react';

import { JobCandidateId } from '../../../../../../../../../jobCandidates/jobCandidatesTypes';

import { SkillsCache } from '../../../../../../../../../skills/SkillsCache';

import { SkillKinds } from '../../../../../../../../../skills/skillsTypes';

import { JobCandidateSelectSkillBadgesField } from '../../../../../../../formFields/JobCandidateSelectSkillBadgesField';

interface EditJobCandidateSkillsFormProps {
  jobCandidate: {
    id: JobCandidateId;
  };
  spacing?: SystemProps['margin'];
}

function EditJobCandidateSkillsForm({
  jobCandidate,
  spacing = 8
}: EditJobCandidateSkillsFormProps) {
  return (
    <Stack spacing={spacing} divider={<StackDivider borderColor="gray.200" />}>
      <JobCandidateSelectSkillBadgesField
        jobCandidate={jobCandidate}
        label="Computer Skills"
        skillKind={SkillKinds.COMPUTER}
        skillsCacheKey={SkillsCache.computerCacheKey()}
        newSkillsCacheKey={SkillsCache.computerNewSkillsCacheKey()}
      />
      <JobCandidateSelectSkillBadgesField
        jobCandidate={jobCandidate}
        label="Industry Knowledge"
        skillKind={SkillKinds.INDUSTRY}
        skillsCacheKey={SkillsCache.industryCacheKey()}
        newSkillsCacheKey={SkillsCache.industryNewSkillsCacheKey()}
      />
    </Stack>
  );
}

export default EditJobCandidateSkillsForm;

import { Avatar, Box, HStack, Stack, StackDivider } from '@chakra-ui/react';
import compact from 'lodash/compact';
import size from 'lodash/size';
import { AlertMessage } from '../../../../../../../../helpers/AlertMessage';
import { Heading } from '../../../../../../../../helpers/Heading';
import { Loader } from '../../../../../../../../helpers/Loader';
import { Text } from '../../../../../../../../helpers/Text';
import { EducationIcon } from '../../../../../../../../icons/Placeholders/EducationIcon';
import { DateUtils } from '../../../../../../../../utils/DateUtils';
import { renderLocation } from '../../../../../../../common/utils/renderLocation';
import { EducationsListForm } from '../../../../../../../educations/components/forms/EducationsListForm';
import { EducationsCache } from '../../../../../../../educations/EducationsCache';
import {
  EducationDegree,
  EducationFields,
  EducationSchoolName,
  EducationStudyField,
  FetchEducationsFilters,
  FetchEducationsSort,
  MayBeEducationCity,
  MayBeEducationEndDate,
  MayBeEducationStartDate,
  MayBeEducationState
} from '../../../../../../../educations/educationsTypes';
import { usePaginatedEducations } from '../../../../../../../educations/hooks/usePaginatedEducations';
import {
  FetchEducationsResponse,
  fetchEducationsQuery
} from '../../../../../../../educations/queries/fetchEducations.query';
import { JobCandidateId } from '../../../../../../../jobCandidates/jobCandidatesTypes';
import { JobResumeRecruiterId } from '../../../../../../../jobResumeRecruiters/jobResumeRecruitersTypes';
import { ViewJobCandidateDetailsPageTabSection } from '../../../../components/ViewJobCandidateDetailsPageTabSection';
import { ViewCandidateDetailsReactions } from '../ViewCandidateDetailsReactions';

interface ViewCandidateDetailsEducationJobCandidateProps {
  jobCandidateId: JobCandidateId;
  jobResumeRecruiterId?: never;
}

interface ViewCandidateDetailsEducationJobResumeRecruiterProps {
  jobCandidateId?: never;
  jobResumeRecruiterId: JobResumeRecruiterId;
}

type ViewCandidateDetailsEducationProps = {
  isEditing?: boolean;
  updatedAt?: string;
} & (
  | ViewCandidateDetailsEducationJobCandidateProps
  | ViewCandidateDetailsEducationJobResumeRecruiterProps
);

function ViewCandidateDetailsEducation({
  updatedAt,
  isEditing,
  jobCandidateId,
  jobResumeRecruiterId
}: ViewCandidateDetailsEducationProps) {
  const educationsCacheKey =
    (jobCandidateId
      ? EducationsCache.jobCandidateListCacheKey(jobCandidateId)
      : null) ||
    (jobResumeRecruiterId
      ? EducationsCache.jobResumeRecruiterListCacheKey(jobResumeRecruiterId)
      : null) ||
    EducationsCache.indexCacheKey();

  const { educations, educationsIsFetched, educationsErrorMessage } =
    usePaginatedEducations<FetchEducationsResponse>({
      query: fetchEducationsQuery,
      cacheKey: educationsCacheKey,
      initialFilters: {
        ...(jobCandidateId
          ? {
              [EducationFields.JOB_CANDIDATE_ID]: {
                operator: 'eq',
                value: jobCandidateId
              }
            }
          : {}),
        ...(jobResumeRecruiterId
          ? {
              [EducationFields.JOB_RESUME_RECRUITER_ID]: {
                operator: 'eq',
                value: jobResumeRecruiterId
              }
            }
          : {})
      } as unknown as FetchEducationsFilters,
      initialSort: {
        [EducationFields.CREATED_AT]: {
          ascending: false
        }
      } as unknown as FetchEducationsSort
    });
  return (
    <ViewJobCandidateDetailsPageTabSection
      title="Education"
      updatedDate={updatedAt}
    >
      {isEditing ? (
        <>
          {jobCandidateId && (
            <EducationsListForm
              withoutHeading
              withStackDivider
              alignButtonToRight
              jobCandidateId={jobCandidateId}
            />
          )}

          {jobResumeRecruiterId && (
            <EducationsListForm
              withoutHeading
              withStackDivider
              alignButtonToRight
              jobResumeRecruiterId={jobResumeRecruiterId}
            />
          )}
        </>
      ) : (
        <>
          {educationsErrorMessage && (
            <AlertMessage message={educationsErrorMessage} />
          )}

          <Loader loaded={educationsIsFetched}>
            {size(educations) > 0 && (
              <Stack spacing={4} divider={<StackDivider />}>
                {educations.map((education) => (
                  <ViewCandidateDetailsEducationItem
                    key={education.id}
                    education={education}
                  />
                ))}

                {jobCandidateId && (
                  <ViewCandidateDetailsReactions hasComments />
                )}
              </Stack>
            )}
          </Loader>
        </>
      )}
    </ViewJobCandidateDetailsPageTabSection>
  );
}

export default ViewCandidateDetailsEducation;

type ViewCandidateDetailsEducationItemType = {
  degree: EducationDegree;
  studyField: EducationStudyField;
  schoolName: EducationSchoolName;
  startDate: MayBeEducationStartDate;
  endDate: MayBeEducationEndDate;
  state: MayBeEducationState;
  city: MayBeEducationCity;
};

interface ViewCandidateDetailsEducationItemProps {
  education: ViewCandidateDetailsEducationItemType;
}

function ViewCandidateDetailsEducationItem({
  education
}: ViewCandidateDetailsEducationItemProps) {
  const location = renderLocation(education);

  return (
    <HStack as="article" spacing={4} alignItems="flex-start">
      <Avatar icon={<EducationIcon />} />

      <Stack spacing={0}>
        <Heading level="h5" fontWeight="medium" fontSize="md">
          {compact([education.degree, education.studyField]).join(', ')}
        </Heading>

        <Text color="gray.600" textStyle="body1Regular">
          {education.schoolName}
        </Text>

        <HStack
          divider={
            <Box border="none" color="gray.600">
              •
            </Box>
          }
        >
          {location && (
            <Text color="gray.600" textStyle="body2Regular">
              {location}
            </Text>
          )}

          {(education.startDate || education.endDate) && (
            <Text color="gray.600" textStyle="body2Regular">
              {compact([
                DateUtils.formatMonth(education.startDate),
                DateUtils.formatMonth(education.endDate)
              ]).join(' - ')}
            </Text>
          )}
        </HStack>
      </Stack>
    </HStack>
  );
}

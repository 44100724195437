import {
  FetchEducationsCacheKey,
  FetchEducationCacheKey,
  DeleteEducationErrorMessage,
  DeleteEducationIsLoading,
  DeleteEducationNanoId
} from '../../educationsTypes';

import { useDeleteItem } from '../../../common/hooks/useDeleteItem';

import {
  EducationsBffRequests,
  DeleteEducationErrorResponse
} from '../../EducationsBffRequests';

interface DeleteEducationOptions {
  educationNanoId: DeleteEducationNanoId;
  cacheKeys?: (FetchEducationsCacheKey | FetchEducationCacheKey)[];
}

function useDeleteEducation({
  educationNanoId,
  cacheKeys = []
}: DeleteEducationOptions) {
  const {
    deleteItemData,
    deleteItemError,
    deleteItemErrorMessage,
    deleteItemIsLoading,
    deleteItem,
    deleteItemReset
  } = useDeleteItem<unknown, DeleteEducationErrorResponse>({
    mutationFn: () => EducationsBffRequests.deleteEducation(educationNanoId),
    cacheKeys
  });

  return {
    deleteEducationData: deleteItemData,
    deleteEducationError: deleteItemError,
    deleteEducationErrorMessage:
      deleteItemErrorMessage as DeleteEducationErrorMessage,
    deleteEducationIsLoading: deleteItemIsLoading as DeleteEducationIsLoading,
    deleteEducation: deleteItem,
    deleteEducationReset: deleteItemReset
  };
}

export default useDeleteEducation;

import { Flex } from '@chakra-ui/react';
import { JobNanoId } from '../../../../../jobs/jobsTypes';
import { CareerSiteApplyPageAppliedJobs } from '../CareerSiteApplyPageAppliedJobs';
import { CareerSiteApplyPageNewJobs } from '../CareerSiteApplyPageNewJobs';

export type ViewJobDetailsType = (nanoId: JobNanoId) => void;

interface CareerSiteApplyPageRightSidebarProps {
  goToNewJobDetails?: (nanoId: JobNanoId) => void;
  goToJobAppliedDetails?: (nanoId: JobNanoId) => void;
  appliedJobDetailsUrl?: (nanoId: JobNanoId) => string;
  newJobDetailsUrl?: (nanoId: JobNanoId) => string;
  currentJobNanoId: JobNanoId;
}

const CareerSiteApplyPageRightSidebar = ({
  goToJobAppliedDetails,
  goToNewJobDetails,
  newJobDetailsUrl,
  currentJobNanoId,
  appliedJobDetailsUrl
}: CareerSiteApplyPageRightSidebarProps) => {
  return (
    <>
      <Flex
        display={{ xl: 'block' }}
        gap={6}
        flexDir="column"
        w={{ base: '100%', lg: '272px' }}
        h={{ base: 'auto', lg: '100%' }}
        position={{ base: 'relative', lg: 'sticky' }}
        top={{ base: 'auto', lg: 0 }}
        overflow={{ base: 'unset', lg: 'auto' }}
      >
        <CareerSiteApplyPageNewJobs
          goToJobDetails={goToNewJobDetails}
          jobDetailsUrl={newJobDetailsUrl}
        />

        <CareerSiteApplyPageAppliedJobs
          goToJobDetails={goToJobAppliedDetails}
          jobDetailsUrl={appliedJobDetailsUrl}
          currentJobNanoId={currentJobNanoId}
        />
      </Flex>
    </>
  );
};

export default CareerSiteApplyPageRightSidebar;

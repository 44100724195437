import { Flex } from '@chakra-ui/react';
import { Text } from '../../../../../helpers/Text';
import { CalendarIcon } from '../../../../../icons/CalendarIcon';
import { HeartIcon } from '../../../../../icons/HeartIcon';
import { RecruitersIcon } from '../../../../../icons/RecruitersIcon';
import { ShareIcon } from '../../../../../icons/ShareIcon';
import { UserVoiceIcon } from '../../../../../icons/UserVoiceIcon';
import { TalentPoolFilterPopupSection } from './components/TalentPoolFilterPopupSection';
import { TalentPoolFilterPopupSectionItem } from './components/TalentPoolFilterPopupSectionItem';
import { TalentPoolFilterPopupHiringManagerSection } from './components/sections/TalentPoolFilterPopupHiringManagerSection';
import { TalentPoolFilterPopupJobStatusSection } from './components/sections/TalentPoolFilterPopupJobStatusSection';
import { TalentPoolFilterPopupLocationsSection } from './components/sections/TalentPoolFilterPopupLocationsSection';
import { TalentPoolFilterPopupRecruitersSection } from './components/sections/TalentPoolFilterPopupRecruitersSection';
import { TalentPoolFilterPopupStagesSection } from './components/sections/TalentPoolFilterPopupStagesSection';

interface TalentPoolFilterPopupProps {
  isOpen: boolean;
}

function TalentPoolFilterPopup({ isOpen }: TalentPoolFilterPopupProps) {
  return (
    <Flex
      w="272px"
      h="100%"
      top={0}
      zIndex={9}
      display={isOpen ? 'flex' : 'none'}
      pos="absolute"
      left={{
        base: '290px',
        xl: '320px'
      }}
    >
      <Flex
        p={4}
        gap={4}
        w="full"
        bg="white"
        overflowY="auto"
        flexDir="column"
        borderTop="1px solid"
        borderColor="gray.200"
      >
        <TalentPoolFilterPopupSection>
          <TalentPoolFilterPopupSectionItem
            label={
              <Flex gap={2} alignItems="center">
                <RecruitersIcon w={4} h={4} />
                <Text>New</Text>
              </Flex>
            }
          />
          <TalentPoolFilterPopupSectionItem
            label={
              <Flex gap={2} alignItems="center">
                <HeartIcon w={4} h={4} fill="red.500" />
                <Text>Liked</Text>
              </Flex>
            }
          />
          <TalentPoolFilterPopupSectionItem
            label={
              <Flex gap={2} alignItems="center">
                <Flex
                  w={4}
                  h={4}
                  p={0}
                  minW={0}
                  display="flex"
                  border="1px solid"
                  alignItems="center"
                  justifyContent="center"
                  borderColor="green.200"
                  borderRadius="full"
                  aria-label="Share"
                  cursor="pointer"
                  bg="green.100"
                >
                  <ShareIcon w={2.5} h={2.5} fill={'green.500'} />
                </Flex>
                <Text>Sharing</Text>
              </Flex>
            }
          />
          <TalentPoolFilterPopupSectionItem
            label={
              <Flex gap={2} alignItems="center">
                <UserVoiceIcon fill="green.500" w={4} h={4} />
                <Text>Applicants</Text>
              </Flex>
            }
          />
          <TalentPoolFilterPopupSectionItem
            label={
              <Flex gap={2} alignItems="center">
                <UserVoiceIcon
                  w={4}
                  h={4}
                  scaleX={-1}
                  fill="blue.500"
                  transform="auto"
                />
                <Text>Recruts</Text>
              </Flex>
            }
          />
          <TalentPoolFilterPopupSectionItem
            label={
              <Flex gap={2} alignItems="center">
                <CalendarIcon w={4} h={4} />
                <Text>Scheduled</Text>
              </Flex>
            }
          />
        </TalentPoolFilterPopupSection>

        <TalentPoolFilterPopupStagesSection />

        <TalentPoolFilterPopupLocationsSection />

        <TalentPoolFilterPopupRecruitersSection />

        <TalentPoolFilterPopupHiringManagerSection />

        <TalentPoolFilterPopupJobStatusSection />
      </Flex>
    </Flex>
  );
}

export default TalentPoolFilterPopup;

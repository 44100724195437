import React, { useState } from 'react';
import { TalentPoolFilterPopupSection } from '../../TalentPoolFilterPopupSection';
import { TalentPoolFilterPopupSectionItem } from '../../TalentPoolFilterPopupSectionItem';

interface StageOption {
  label: string;
  value: string;
}
const stageOptions: StageOption[] = [
  { label: '1st Interview', value: 'first_interview' },
  { label: '2nd Interview', value: 'second_interview' },
  { label: 'Applied', value: 'applied' },
  { label: 'Disqualified', value: 'disqualified' },
  { label: 'Hired', value: 'hired' },
  { label: 'Offer', value: 'offer' }
];

export default function TalentPoolFilterPopupStagesSection() {
  const [checkedItems, setCheckedItems] = useState<Record<string, boolean>>(
    stageOptions.reduce(
      (acc, { value }) => ({ ...acc, [value]: false }),
      {} as Record<string, boolean>
    )
  );

  const allChecked = Object.values(checkedItems).every(Boolean);

  const isIndeterminate =
    Object.values(checkedItems).some(Boolean) && !allChecked;

  const handleToggleAll = (checked: boolean) => {
    const updatedItems = stageOptions.reduce(
      (acc, { value }) => ({ ...acc, [value]: checked }),
      {}
    );
    setCheckedItems(updatedItems);
  };

  const handleToggleItem = (value: string, checked: boolean) => {
    setCheckedItems((prev) => ({ ...prev, [value]: checked }));
  };

  return (
    <TalentPoolFilterPopupSection
      title="Stages"
      isSearchable
      isChecked={allChecked}
      isIndeterminate={isIndeterminate}
      onChange={(e) => handleToggleAll(e.target.checked)}
    >
      {stageOptions.map(({ label, value }) => (
        <TalentPoolFilterPopupSectionItem
          key={value}
          label={label}
          value={value}
          isChecked={checkedItems[value]}
          onChange={(e) => handleToggleItem(value, e.target.checked)}
        />
      ))}
    </TalentPoolFilterPopupSection>
  );
}

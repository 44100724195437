import { FetchEducationsCacheKey } from '../../educationsTypes';

import { useCreateItem } from '../../../common/hooks/useCreateItem';

import {
  EducationsBffRequests,
  CreateEducationRequestData,
  CreateEducationErrorResponse
} from '../../EducationsBffRequests';

interface CreateEducationOptions {
  cacheKeys?: FetchEducationsCacheKey[];
}

function useCreateEducation({ cacheKeys = [] }: CreateEducationOptions) {
  const {
    createItemData,
    createItemError,
    createItemErrorMessage,
    createItemIsLoading,
    createItem,
    createItemReset
  } = useCreateItem<CreateEducationRequestData, CreateEducationErrorResponse>({
    mutationFn: (queryInput) =>
      EducationsBffRequests.createEducation(queryInput),
    cacheKeys
  });

  return {
    createEducationData: createItemData,
    createEducationError: createItemError,
    createEducationErrorMessage: createItemErrorMessage,
    createEducationIsLoading: createItemIsLoading,
    createEducation: createItem,
    createEducationReset: createItemReset
  };
}

export default useCreateEducation;

import {
  FetchJobScorecardQuestionsCacheKey,
  FetchJobScorecardQuestionCacheKey,
  DeleteJobScorecardQuestionErrorMessage,
  DeleteJobScorecardQuestionIsLoading,
  DeleteJobScorecardQuestionID
} from '../../jobScorecardQuestionsTypes';

import { useDeleteItem } from '../../../common/hooks/useDeleteItem';

import {
  JobScorecardQuestionsBffRequests,
  DeleteJobScorecardQuestionErrorResponse
} from '../../JobScorecardQuestionsBffRequests';

interface DeleteJobScorecardQuestionOptions {
  jobScorecardQuestionId:
    | DeleteJobScorecardQuestionID
    | DeleteJobScorecardQuestionID[];
  cacheKeys?: (
    | FetchJobScorecardQuestionsCacheKey
    | FetchJobScorecardQuestionCacheKey
  )[];
}

function useDeleteJobScorecardQuestion({
  jobScorecardQuestionId,
  cacheKeys = []
}: DeleteJobScorecardQuestionOptions) {
  const {
    deleteItemData,
    deleteItemError,
    deleteItemErrorMessage,
    deleteItemIsLoading,
    deleteItem,
    deleteItemReset
  } = useDeleteItem<unknown, DeleteJobScorecardQuestionErrorResponse>({
    mutationFn: async () => {
      if (Array.isArray(jobScorecardQuestionId)) {
        for (const id of jobScorecardQuestionId) {
          await JobScorecardQuestionsBffRequests.deleteJobScorecardQuestion(id);
        }
        return;
      }
      return JobScorecardQuestionsBffRequests.deleteJobScorecardQuestion(
        jobScorecardQuestionId
      );
    },
    cacheKeys
  });

  return {
    deleteJobScorecardQuestionData: deleteItemData,
    deleteJobScorecardQuestionError: deleteItemError,
    deleteJobScorecardQuestionErrorMessage:
      deleteItemErrorMessage as DeleteJobScorecardQuestionErrorMessage,
    deleteJobScorecardQuestionIsLoading:
      deleteItemIsLoading as DeleteJobScorecardQuestionIsLoading,
    deleteJobScorecardQuestion: deleteItem,
    deleteJobScorecardQuestionReset: deleteItemReset
  };
}

export default useDeleteJobScorecardQuestion;

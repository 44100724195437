import {
  FetchEmploymentsCacheKey,
  FetchEmploymentCacheKey,
  DeleteEmploymentErrorMessage,
  DeleteEmploymentIsLoading,
  DeleteEmploymentNanoId
} from '../../employmentsTypes';

import { useDeleteItem } from '../../../common/hooks/useDeleteItem';

import {
  EmploymentsBffRequests,
  DeleteEmploymentErrorResponse
} from '../../EmploymentsBffRequests';

interface DeleteEmploymentOptions {
  employmentNanoId: DeleteEmploymentNanoId;
  cacheKeys?: (FetchEmploymentsCacheKey | FetchEmploymentCacheKey)[];
}

function useDeleteEmployment({
  employmentNanoId,
  cacheKeys = []
}: DeleteEmploymentOptions) {
  const {
    deleteItemData,
    deleteItemError,
    deleteItemErrorMessage,
    deleteItemIsLoading,
    deleteItem,
    deleteItemReset
  } = useDeleteItem<unknown, DeleteEmploymentErrorResponse>({
    mutationFn: () => EmploymentsBffRequests.deleteEmployment(employmentNanoId),
    cacheKeys
  });

  return {
    deleteEmploymentData: deleteItemData,
    deleteEmploymentError: deleteItemError,
    deleteEmploymentErrorMessage:
      deleteItemErrorMessage as DeleteEmploymentErrorMessage,
    deleteEmploymentIsLoading: deleteItemIsLoading as DeleteEmploymentIsLoading,
    deleteEmployment: deleteItem,
    deleteEmploymentReset: deleteItemReset
  };
}

export default useDeleteEmployment;

import { FieldBrand } from '../../types';

export enum CandidateOverallEvaluationEnums {
  HireNow = 'hire_now',
  GreatFit = 'great_fit',
  GoodFit = 'good_fit',
  OkFit = 'ok_fit',
  NotAFit = 'not_fit'
}

export type CandidateOverallEvaluationType = FieldBrand<
  CandidateOverallEvaluationEnums,
  'CandidateOverallEvaluationType'
>;

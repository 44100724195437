import {
  FetchJobScorecardQuestionsCacheKey,
  FetchJobScorecardQuestionCacheKey,
  UpdateJobScorecardQuestionErrorMessage,
  UpdateJobScorecardQuestionIsLoading,
  UpdateJobScorecardQuestionID
} from '../../jobScorecardQuestionsTypes';

import {
  JobScorecardQuestionsBffRequests,
  UpdateJobScorecardQuestionRequestData,
  UpdateJobScorecardQuestionErrorResponse
} from '../../JobScorecardQuestionsBffRequests';

import { useUpdateItem } from '../../../common/hooks/useUpdateItem';

interface UpdateJobScorecardQuestionOptions {
  jobScorecardQuestionId: UpdateJobScorecardQuestionID;
  cacheKeys?: (
    | FetchJobScorecardQuestionsCacheKey
    | FetchJobScorecardQuestionCacheKey
  )[];
}

function useUpdateJobScorecardQuestion({
  jobScorecardQuestionId,
  cacheKeys = []
}: UpdateJobScorecardQuestionOptions) {
  const {
    updateItemData,
    updateItemError,
    updateItemErrorMessage,
    updateItemIsLoading,
    updateItem,
    updateItemReset
  } = useUpdateItem<
    UpdateJobScorecardQuestionRequestData,
    UpdateJobScorecardQuestionErrorResponse
  >({
    mutationFn: (queryInput) =>
      JobScorecardQuestionsBffRequests.updateJobScorecardQuestion(
        jobScorecardQuestionId,
        queryInput
      ),
    cacheKeys
  });

  return {
    updateJobScorecardQuestionData: updateItemData,
    updateJobScorecardQuestionError: updateItemError,
    updateJobScorecardQuestionErrorMessage:
      updateItemErrorMessage as UpdateJobScorecardQuestionErrorMessage,
    updateJobScorecardQuestionIsLoading:
      updateItemIsLoading as UpdateJobScorecardQuestionIsLoading,
    updateJobScorecardQuestion: updateItem,
    updateJobScorecardQuestionReset: updateItemReset
  };
}

export default useUpdateJobScorecardQuestion;

import { Flex, Grid, Stack, StackDivider } from '@chakra-ui/react';
import filter from 'lodash/filter';
import find from 'lodash/find';
import size from 'lodash/size';
import { useEffect, useRef, useState } from 'react';
import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { IconButton } from '../../../../../helpers/buttons/IconButton';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { Search } from '../../../../../helpers/Search';
import { FilterIcon } from '../../../../../icons/FilterIcon';
import { ErrorMessage, IsFetched } from '../../../../../types';
import { DepartmentsCache } from '../../../../departments/DepartmentsCache';
import {
  DepartmentFields,
  DepartmentId,
  DepartmentNanoId,
  FetchDepartmentsFilters,
  FetchDepartmentsSort
} from '../../../../departments/departmentsTypes';
import { usePaginatedDepartments } from '../../../../departments/hooks/usePaginatedDepartments';
import {
  FetchDepartmentsResponse,
  fetchDepartmentsQuery
} from '../../../../departments/queries/fetchDepartments.query';
import { JobCandidateNanoId } from '../../../../jobCandidates/jobCandidatesTypes';
import { JobNanoId } from '../../../../jobs/jobsTypes';
import { UserId } from '../../../../users/usersTypes';
import { NoTalentPlaceholder } from '../../helpers/NoTalentPlaceholder';
import {
  TalentPoolCandidateListItem,
  TalentPoolJobCandidate
} from '../TalentPoolCandidateListItem';

interface TalentPoolCandidatesListProps {
  jobCandidates: TalentPoolJobCandidate[];
  jobCandidatesTotalCount: number;
  jobCandidatesIsFetched: IsFetched;
  jobCandidatesErrorMessage: ErrorMessage;
  department?: {
    nanoId: DepartmentNanoId;
    id: DepartmentId;
  };
  personNanoId?: JobCandidateNanoId;
  currentUser: {
    id: UserId;
  };
  jobCandidateRoute: (
    departmentNanoId: DepartmentNanoId,
    personNanoId: JobCandidateNanoId,
    jobNanoId: JobNanoId
  ) => string;
  isDisabled?: boolean;
  fullHeight?: boolean;
  withoutProfileShare?: boolean;
  isHiringPortal?: boolean;
  filterCandidatesByDepartment: (departmentNanoId: DepartmentId) => void;
  filterParams?: string[];
  toggleFilter?: () => void;
}

function TalentPoolCandidatesList({
  department,
  currentUser,
  personNanoId,
  toggleFilter,
  jobCandidates,
  jobCandidatesIsFetched,
  jobCandidatesErrorMessage,
  jobCandidateRoute,
  isDisabled,
  isHiringPortal,
  withoutProfileShare
}: TalentPoolCandidatesListProps) {
  const { companyId } = useCurrentUser();
  const [departmentId] = useState<DepartmentId>(department?.id as DepartmentId);

  const containerRef = useRef<HTMLDivElement>(null);

  const { departments } = usePaginatedDepartments<FetchDepartmentsResponse>({
    query: fetchDepartmentsQuery,
    cacheKey: DepartmentsCache.indexCacheKey(),
    initialSort: {
      [DepartmentFields.NAME]: { ascending: true }
    } as unknown as FetchDepartmentsSort,
    initialFilters: {
      ...(companyId
        ? {
            [DepartmentFields.COMPANY_ID]: {
              operator: 'eq',
              value: companyId
            }
          }
        : {})
    } as unknown as FetchDepartmentsFilters
  });

  const activeJobCandidate = find(
    jobCandidates,
    (jC) => jC.nanoId === personNanoId
  );

  const otherJobCandidates = filter(
    jobCandidates,
    (jC) => jC.nanoId !== personNanoId
  );

  useEffect(() => {
    if (personNanoId) {
      containerRef.current?.scrollIntoView();
    }
  }, [personNanoId]);

  const selectedDepartment = find(
    departments,
    (dept) => String(dept.id) === String(departmentId)
  );

  return (
    <Grid
      gap={3}
      w={{
        base: '290px',
        xl: '320px'
      }}
      bg="white"
      pos="fixed"
      overflowY="auto"
      borderRight="1px solid"
      borderColor="gray.200"
      h={'calc(100vh - 130px)'}
      gridTemplateRows="max-content 1fr"
    >
      <Flex
        pl={4}
        pr={2}
        pt={3}
        pb={3.5}
        top={0}
        gap={2}
        zIndex={10}
        bg="gray.50"
        pos="sticky"
        alignItems="center"
      >
        <Flex flex={1} gap={1.5} alignItems="center">
          <Search
            isClickableRightElement
            rightElement={
              <IconButton
                p={0}
                size="small"
                color="gray.600"
                aria-label="Filter"
                hierarchy="unstyled"
                icon={<FilterIcon w={5} h={5} />}
                onClick={toggleFilter}
              />
            }
          />
        </Flex>
      </Flex>

      <LoadingSkeleton loaded={jobCandidatesIsFetched}>
        <Stack
          spacing={0}
          overflow="auto"
          divider={<StackDivider borderColor="gray.100" />}
        >
          <AlertMessage message={jobCandidatesErrorMessage} />
          {size(jobCandidates) ? (
            <>
              {activeJobCandidate ? (
                <TalentPoolCandidateListItem
                  jobCandidate={activeJobCandidate}
                  department={selectedDepartment || department}
                  currentUser={currentUser}
                  isActive
                  jobCandidateRoute={jobCandidateRoute}
                  isDisabled={isDisabled}
                  withoutProfileShare={withoutProfileShare}
                  itemRef={containerRef}
                />
              ) : null}
              {otherJobCandidates.map((jobCandidate) => (
                <TalentPoolCandidateListItem
                  key={jobCandidate.nanoId}
                  jobCandidate={jobCandidate}
                  department={selectedDepartment || department}
                  currentUser={currentUser}
                  isActive={false}
                  jobCandidateRoute={jobCandidateRoute}
                  isDisabled={isDisabled}
                  withoutProfileShare={withoutProfileShare}
                  isHiringPortal={isHiringPortal}
                />
              ))}
            </>
          ) : (
            <NoTalentPlaceholder profileType="prospect" />
          )}
        </Stack>
      </LoadingSkeleton>
    </Grid>
  );
}

export default TalentPoolCandidatesList;

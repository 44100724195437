import { JobJobTitle, JobName } from '../../jobsTypes';

interface RenderJobTitleProps {
  jobTitle?: JobJobTitle;
  name: JobName;
}

function renderJobTitle(job: RenderJobTitleProps) {
  // return job.jobTitle?.name || job.name;
  return job.name;
}

export default renderJobTitle;

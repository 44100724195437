import React from 'react';
import { Flex, SimpleGrid } from '@chakra-ui/react';

import {
  FetchEducationsCacheKey,
  EducationFields,
  EducationNanoId,
  MayBeEducationStartDate,
  MayBeEducationEndDate,
  EducationUserId,
  EducationJobCandidateId,
  EducationJobResumeId,
  EducationJobResumeRecruiterId
} from '../../../educationsTypes';

import { JobCandidateId } from '../../../../jobCandidates/jobCandidatesTypes';

import { JobResumeId } from '../../../../jobResumes/jobResumesTypes';

import { JobResumeRecruiterId } from '../../../../jobResumeRecruiters/jobResumeRecruitersTypes';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { useCreateEducation } from '../../../hooks/useCreateEducation';
import { useUpdateEducation } from '../../../hooks/useUpdateEducation';

import { useAddEditEducationForm } from './hooks/useAddEditEducationForm';

import { SelectCityFormField } from '../../../../common/components/formFields/SelectCityFormField';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { CollapseFormBox } from '../../../../../helpers/CollapseFormBox';
import { Form } from '../../../../../helpers/Form';
import { InputField } from '../../../../../helpers/forms/formFields/InputField';
import { PureButtonHelper } from '../../../../../helpers/buttons/PureButtonHelper';
import { PureSubmitButtonHelper } from '../../../../../helpers/buttons/PureSubmitButtonHelper';

import { generateNanoId } from '../../../../../utils/generateNanoId';
import { DateUtils } from '../../../../../utils/DateUtils';

import { AddEditEducationFormEducation } from './AddEditEducationForm.types';
import { DateInputField } from '../../../../../helpers/forms/formFields/DateInputField';

interface AddEditEducationFormProps {
  jobCandidateId?: JobCandidateId;
  jobResumeId?: JobResumeId;
  jobResumeRecruiterId?: JobResumeRecruiterId;
  education?: AddEditEducationFormEducation;
  educationsCacheKey: FetchEducationsCacheKey;
  type: 'new' | 'edit';
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
}

function AddEditEducationForm({
  jobCandidateId,
  jobResumeId,
  jobResumeRecruiterId,
  education,
  educationsCacheKey,
  type,
  isOpen,
  onClose,
  onSave
}: AddEditEducationFormProps) {
  const currentUser = useCurrentUser();

  const {
    createEducation,
    createEducationErrorMessage,
    createEducationIsLoading
  } = useCreateEducation({
    cacheKeys: [educationsCacheKey]
  });

  const {
    updateEducation,
    updateEducationErrorMessage,
    updateEducationIsLoading
  } = useUpdateEducation({
    educationNanoId: education?.nanoId || ('0' as EducationNanoId),
    cacheKeys: [educationsCacheKey]
  });

  const {
    control,
    registerFields,
    validationErrors,
    addEditEducationFormIsLoading,
    addEditEducationFormErrorMessage,
    handleAddEditEducationForm
  } = useAddEditEducationForm({
    defaultValues: education
      ? {
          degree: education.degree,
          schoolName: education.schoolName,
          studyField: education.studyField,
          startDate: DateUtils.toMonthInput(
            education.startDate
          ) as MayBeEducationStartDate,
          endDate: DateUtils.toMonthInput(
            education.endDate
          ) as MayBeEducationEndDate,
          cityId: education.cityId
        }
      : {},
    onAddEditEducationForm: async (data) => {
      if (education) {
        await updateEducation(data);
      } else {
        await createEducation({
          ...data,
          nanoId: generateNanoId<EducationNanoId>(),
          userId: currentUser.id as EducationUserId,
          jobCandidateId: jobCandidateId as EducationJobCandidateId,
          jobResumeId: jobResumeId as EducationJobResumeId,
          jobResumeRecruiterId:
            jobResumeRecruiterId as EducationJobResumeRecruiterId
        });
      }

      onSave();
    }
  });

  return (
    <CollapseFormBox
      title={type === 'new' ? 'Add Education' : null}
      isOpen={isOpen}
      titleLevel="h5"
      stackSpacing={4}
    >
      <Form onSubmit={handleAddEditEducationForm}>
        <SimpleGrid columns={[1, 2]} spacing={6}>
          <InputField
            label="Degree"
            placeholder="Degree"
            name={EducationFields.DEGREE}
            errorMessage={validationErrors.degreeValidationError}
            ref={registerFields.registerDegree.ref}
            onChange={registerFields.registerDegree.onChange}
          />
          <InputField
            label="School Name"
            placeholder="School Name"
            name={EducationFields.SCHOOL_NAME}
            errorMessage={validationErrors.schoolNameValidationError}
            ref={registerFields.registerSchoolName.ref}
            onChange={registerFields.registerSchoolName.onChange}
          />
          <InputField
            label="Study Field"
            placeholder="Study Field"
            name={EducationFields.STUDY_FIELD}
            errorMessage={validationErrors.studyFieldValidationError}
            ref={registerFields.registerStudyField.ref}
            onChange={registerFields.registerStudyField.onChange}
          />
          {education ? (
            <SelectCityFormField
              control={control}
              item={education}
              errorMessage={validationErrors.cityIdValidationError}
            />
          ) : (
            <SelectCityFormField
              control={control}
              errorMessage={validationErrors.cityIdValidationError}
            />
          )}
          <DateInputField
            label="From"
            placeholder="From"
            type="month"
            name={EducationFields.START_DATE}
            errorMessage={validationErrors.startDateValidationError}
            ref={registerFields.registerStartDate.ref}
            onChange={registerFields.registerStartDate.onChange}
          />
          <DateInputField
            label="To"
            placeholder="To"
            type="month"
            name={EducationFields.END_DATE}
            errorMessage={validationErrors.endDateValidationError}
            ref={registerFields.registerEndDate.ref}
            onChange={registerFields.registerEndDate.onChange}
          />
        </SimpleGrid>
        <SimpleGrid columns={1} spacing={6}>
          <AlertMessage
            message={
              addEditEducationFormErrorMessage ||
              createEducationErrorMessage ||
              updateEducationErrorMessage
            }
          />
        </SimpleGrid>
        <Flex justifyContent="end" gap={4}>
          <PureButtonHelper
            hierarchy="tertiary"
            size="small"
            px={4}
            i18nText="Cancel"
            onClick={onClose}
          />
          <PureSubmitButtonHelper
            hierarchy="primary"
            size="small"
            px={4}
            i18nText={type === 'new' ? 'Add' : 'Update'}
            isLoading={
              addEditEducationFormIsLoading ||
              createEducationIsLoading ||
              updateEducationIsLoading
            }
          />
        </Flex>
      </Form>
    </CollapseFormBox>
  );
}

export default AddEditEducationForm;

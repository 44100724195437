import { useDisclosure } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Control } from 'react-hook-form';
import { useChakraToast } from '../../../../../../helpers/useChakraToast';
import { AddJobCandidateAssessmentFormData } from '../../../../../jobCandidateAssessments/components/forms/AddJobCandidateAssessmentForm/AddJobCandidateAssessmentForm.types';
import { useAddJobCandidateAssessmentForm } from '../../../../../jobCandidateAssessments/components/forms/AddJobCandidateAssessmentForm/hooks/useAddJobCandidateAssessmentForm';
import { useCreateJobCandidateAssessment } from '../../../../../jobCandidateAssessments/hooks/useCreateJobCandidateAssessment';
import { CreateJobCandidateAssessmentRequestData } from '../../../../../jobCandidateAssessments/JobCandidateAssessmentsBffRequests';
import { JobCandidateAssessmentsCache } from '../../../../../jobCandidateAssessments/JobCandidateAssessmentsCache';
import {
  FetchJobCandidateAssessmentsCacheKey,
  JobCandidateAssessmentFields,
  JobCandidateAssessmentMessage,
  JobCandidateAssessmentPages,
  JobCandidateAssessmentSettings,
  JobCandidateAssessmentSettingsPages
} from '../../../../../jobCandidateAssessments/jobCandidateAssessmentsTypes';
import { useFetchJobCandidateByNanoId } from '../../../../../jobCandidates/hooks/useFetchJobCandidateByNanoId';
import { JobCandidatesCache } from '../../../../../jobCandidates/JobCandidatesCache';
import {
  FetchJobCandidateEnabled,
  JobCandidateId,
  JobCandidateNanoId
} from '../../../../../jobCandidates/jobCandidatesTypes';
import {
  FetchJobCandidateResponse,
  fetchJobCandidateQuery
} from '../../../../../jobCandidates/queries/fetchJobCandidate.query';
import { useFetchJobByNanoId } from '../../../../hooks/useFetchJobByNanoId';
import { JobsCache } from '../../../../JobsCache';
import { JobId, FetchJobEnabled } from '../../../../jobsTypes';
import {
  FetchJobResponse,
  fetchJobQuery
} from '../../../../queries/fetchJob.query';
import { ViewJobCandidateDetailsAssessmentNav } from '../../components/ViewJobCandidateDetailsSendAssessmentSidebar/components/ViewJobCandidateDetailsAssessmentNav';
import { useViewJobCandidateDetailsContext } from '../ViewJobCandidateDetailsContext';
import { useViewJobCandidateDetailsSubmissionContext } from '../ViewJobCandidateDetailsSubmissionContext';
import {
  ViewJobCandidateDetailsSendAssessmentContextType,
  ViewJobCandidateDetailsSendAssessmentFields,
  ViewJobCandidateDetailsSendAssessmentProviderProps
} from './ViewJobCandidateDetailsSendAssessmentContext.types';

const ViewJobCandidateDetailsSendAssessmentContext =
  createContext<ViewJobCandidateDetailsSendAssessmentContextType>({
    totalSteps: 2,
    currentStep: 1,
    assessmentFields: [],
    handleAssessmentField: function (): void {
      /*  */
    },
    isLastStep: false,
    isFirstStep: false,
    isEmailAssessmentSent: false,
    isSendEmailAssessmentOpen: false,
    isSendPhoneAssessmentOpen: false,
    openEmailAssessmentSent: function (): void {
      /*  */
    },
    cancelEmailAssessmentSent: function (): void {
      /*  */
    },
    openSendEmailAssessment: function (): void {
      /*  */
    },
    closeSendEmailAssessment: function (): void {
      /*  */
    },
    openSendPhoneAssessment: function (): void {
      /*  */
    },
    closeSendPhoneAssessment: function (): void {
      /*  */
    },
    goToPrevStep: function (): void {
      /*  */
    },
    goToNextStep: function (): void {
      /*  */
    },
    sendEmailAssessment: function (): void {
      /*  */
    },
    sendPhoneAssessment: function (): void {
      /*  */
    },
    control: {} as Control<AddJobCandidateAssessmentFormData>,
    errorMessage: '',
    message: '' as JobCandidateAssessmentMessage,
    validationErrors: {},
    registerFields:
      {} as ViewJobCandidateDetailsSendAssessmentContextType['registerFields']
  });

export function ViewJobCandidateDetailsSendAssessmentProvider({
  children,
  jobNanoId,
  jobCandidateNanoId
}: ViewJobCandidateDetailsSendAssessmentProviderProps) {
  const router = useRouter();
  const toast = useChakraToast();

  const defaultSubmissionFields: ViewJobCandidateDetailsSendAssessmentFields[] =
    Object.values(ViewJobCandidateDetailsSendAssessmentFields);

  const { closeCandidateSubmission } =
    useViewJobCandidateDetailsSubmissionContext();

  const { openProfileOverview, closeProfileOverview } =
    useViewJobCandidateDetailsContext();

  const totalSteps = 2;
  const [currentStep, setCurrentStep] = useState(1);
  const [assessmentFields, setAssessmentFields] = useState<
    ViewJobCandidateDetailsSendAssessmentFields[]
  >(defaultSubmissionFields);

  const getAssessmentPages = (
    fields: ViewJobCandidateDetailsSendAssessmentFields[]
  ): JobCandidateAssessmentSettingsPages => {
    const pages = {
      questions: {
        required: fields.includes(
          ViewJobCandidateDetailsSendAssessmentFields.AssessmentQuestions
        )
      },
      preferences: {
        required: fields.includes(
          ViewJobCandidateDetailsSendAssessmentFields.AssessmentPreferences
        )
      },
      references: {
        required: fields.includes(
          ViewJobCandidateDetailsSendAssessmentFields.AssessmentReferences
        )
      },
      projects: {
        required: fields.includes(
          ViewJobCandidateDetailsSendAssessmentFields.AssessmentProjects
        )
      },
      skills: {
        required: fields.includes(
          ViewJobCandidateDetailsSendAssessmentFields.AssessmentSkillsExpertise
        )
      },
      updatedResume: {
        required: fields.includes(
          ViewJobCandidateDetailsSendAssessmentFields.AssessmentUpdatedResume
        )
      }
    } as JobCandidateAssessmentSettingsPages;

    return pages;
  };

  const { jobCandidate } =
    useFetchJobCandidateByNanoId<FetchJobCandidateResponse>({
      jobCandidateNanoId: jobCandidateNanoId as JobCandidateNanoId,
      enabled: !!jobCandidateNanoId as FetchJobCandidateEnabled,
      cacheKey: JobCandidatesCache.showCacheKey(),
      query: fetchJobCandidateQuery
    });

  const { job, jobIsFetched } = useFetchJobByNanoId<FetchJobResponse>({
    query: fetchJobQuery,
    jobNanoId: jobNanoId,
    enabled: !!jobNanoId as FetchJobEnabled,
    cacheKey: JobsCache.showJobCacheKey(jobNanoId)
  });

  const {
    createJobCandidateAssessment,
    createJobCandidateAssessmentIsLoading,
    createJobCandidateAssessmentErrorMessage
  } = useCreateJobCandidateAssessment({
    cacheKeys: [
      JobCandidateAssessmentsCache.indexCacheKey(),
      JobCandidatesCache.jobIndexCacheKey(
        jobNanoId
      ) as unknown as FetchJobCandidateAssessmentsCacheKey
    ]
  });

  const {
    watch,
    control,
    registerFields,
    validationErrors,
    handleAddJobCandidateAssessment,
    setAddJobCandidateAssessmentForm,
    triggerJobCandidateAssessmentForm,
    resetAddJobCandidateAssessmentForm,
    addJobCandidateAssessmentFormIsLoading,
    addJobCandidateAssessmentFormErrorMessage
  } = useAddJobCandidateAssessmentForm({
    defaultValues: {
      message: '' as JobCandidateAssessmentMessage,
      pages: assessmentFields as JobCandidateAssessmentPages
    },
    onAddJobCandidateAssessment: async (data) => {
      const payload: CreateJobCandidateAssessmentRequestData = {
        jobId: job?.id as JobId,
        jobCandidateId: jobCandidate?.id as JobCandidateId,
        settings: {
          message: data.message,
          pages: getAssessmentPages(assessmentFields)
        } as JobCandidateAssessmentSettings
      };

      await createJobCandidateAssessment(payload);

      closeSendEmailAssessment();

      toast({
        title: 'Candidate email assessment sent',
        status: 'success'
      });
    }
  });

  const {
    isOpen: isSendEmailAssessmentOpen,
    onClose: closeSendEmailAssessment,
    onOpen: openSendEmailAssessment
  } = useDisclosure({
    onClose: () => {
      setAssessmentFields(defaultSubmissionFields);
      resetAddJobCandidateAssessmentForm();
      openProfileOverview();
      setCurrentStep(1);
    },
    onOpen: () => {
      closeCandidateSubmission();
      closeProfileOverview();
    }
  });

  const {
    isOpen: isEmailAssessmentSent,
    onOpen: openEmailAssessmentSent,
    onClose: cancelEmailAssessmentSent
  } = useDisclosure();

  const {
    isOpen: isSendPhoneAssessmentOpen,
    onClose: closeSendPhoneAssessment,
    onOpen: openSendPhoneAssessment
  } = useDisclosure();

  useEffect(() => {
    setAddJobCandidateAssessmentForm(
      'message',
      `Thank you for considering this job opportunity with ${
        job?.company?.name || 'us'
      }. Please, complete this job assessment by clicking the link below.` as JobCandidateAssessmentMessage
    );
  }, [
    job,
    jobIsFetched,
    isSendEmailAssessmentOpen,
    setAddJobCandidateAssessmentForm
  ]);

  useEffect(() => {
    const handleRouteChange = () => {
      setCurrentStep(1);
      closeSendEmailAssessment();
      closeSendPhoneAssessment();
      cancelEmailAssessmentSent();
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [
    cancelEmailAssessmentSent,
    closeSendEmailAssessment,
    closeSendPhoneAssessment,
    router.events
  ]);

  const goToNextStep = useCallback(async () => {
    if (currentStep === totalSteps) return;

    if (currentStep === 1) {
      const isValid = await triggerJobCandidateAssessmentForm([
        JobCandidateAssessmentFields.MESSAGE
      ]);

      if (!isValid) {
        return;
      }
    }

    setCurrentStep(currentStep + 1);
  }, [currentStep, triggerJobCandidateAssessmentForm]);

  const goToPrevStep = useCallback(() => {
    if (currentStep === 1) return;

    setCurrentStep(currentStep - 1);
  }, [currentStep]);

  const sendPhoneAssessment = useCallback(() => {
    closeSendPhoneAssessment();

    toast({
      title: 'Candidate phone assessment saved',
      status: 'success'
    });
  }, [closeSendPhoneAssessment, toast]);

  const sendEmailAssessment = useCallback(() => {
    handleAddJobCandidateAssessment();
  }, [handleAddJobCandidateAssessment]);

  const handleAssessmentField = useCallback(
    (field: ViewJobCandidateDetailsSendAssessmentFields) => {
      if (assessmentFields.includes(field)) {
        const updatedFields = assessmentFields.filter((f) => f !== field);

        setAssessmentFields(updatedFields);
      } else {
        setAssessmentFields([...assessmentFields, field]);
      }
    },
    [assessmentFields]
  );

  const { message } = watch();

  const values: ViewJobCandidateDetailsSendAssessmentContextType = useMemo(
    () => ({
      message,
      control,
      totalSteps,
      currentStep,
      goToNextStep,
      goToPrevStep,
      registerFields,
      validationErrors,
      assessmentFields,
      handleAssessmentField,
      sendEmailAssessment,
      sendPhoneAssessment,
      isEmailAssessmentSent,
      openEmailAssessmentSent,
      cancelEmailAssessmentSent,
      openSendEmailAssessment,
      openSendPhoneAssessment,
      closeSendEmailAssessment,
      closeSendPhoneAssessment,
      isSendEmailAssessmentOpen,
      isSendPhoneAssessmentOpen,
      isFirstStep: currentStep === 1,
      isLastStep: currentStep === totalSteps,
      errorMessage:
        createJobCandidateAssessmentErrorMessage ||
        addJobCandidateAssessmentFormErrorMessage
    }),
    [
      message,
      control,
      currentStep,
      goToNextStep,
      goToPrevStep,
      registerFields,
      validationErrors,
      assessmentFields,
      handleAssessmentField,
      sendEmailAssessment,
      sendPhoneAssessment,
      isEmailAssessmentSent,
      openEmailAssessmentSent,
      cancelEmailAssessmentSent,
      openSendEmailAssessment,
      openSendPhoneAssessment,
      closeSendEmailAssessment,
      closeSendPhoneAssessment,
      isSendEmailAssessmentOpen,
      isSendPhoneAssessmentOpen,
      createJobCandidateAssessmentErrorMessage,
      addJobCandidateAssessmentFormErrorMessage
    ]
  );

  return (
    <ViewJobCandidateDetailsSendAssessmentContext.Provider value={values}>
      {children}

      {values.isSendEmailAssessmentOpen && (
        <ViewJobCandidateDetailsAssessmentNav
          isLoading={
            createJobCandidateAssessmentIsLoading ||
            addJobCandidateAssessmentFormIsLoading
          }
        />
      )}
    </ViewJobCandidateDetailsSendAssessmentContext.Provider>
  );
}

export const useViewJobCandidateDetailsSendAssessmentContext = () =>
  useContext(ViewJobCandidateDetailsSendAssessmentContext);

import { Box, Collapse, Flex, GridItem, SimpleGrid } from '@chakra-ui/react';
import find from 'lodash/find';
import { useCallback, useState } from 'react';

import { IsRequired } from '../../../../../types';

import { MenuPlacement } from 'chakra-react-select';
import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { CollapseFormBox } from '../../../../../helpers/CollapseFormBox';
import { Form } from '../../../../../helpers/Form';
import { Text } from '../../../../../helpers/Text';
import { PureButtonHelper } from '../../../../../helpers/buttons/PureButtonHelper';
import { PureSubmitButtonHelper } from '../../../../../helpers/buttons/PureSubmitButtonHelper';
import { CheckboxField } from '../../../../../helpers/forms/formFields/CheckboxField';
import { DateInputField } from '../../../../../helpers/forms/formFields/DateInputField';
import { JobSatisfactionField } from '../../../../../helpers/forms/formFields/JobSatisfactionField';
import { SelectField } from '../../../../../helpers/forms/formFields/SelectField';
import { TextareaField } from '../../../../../helpers/forms/formFields/TextareaField';

import { DateUtils } from '../../../../../utils/DateUtils';
import { generateNanoId } from '../../../../../utils/generateNanoId';
import { SelectCityFormField } from '../../../../common/components/formFields/SelectCityFormField';
import { SelectCompanyFormField } from '../../../../common/components/formFields/SelectCompanyFormField';
import { SelectJobFunctionsFormField } from '../../../../common/components/formFields/SelectJobFunctionsFormField';
import { SelectJobTitleFormField } from '../../../../common/components/formFields/SelectJobTitleFormField';
import { SelectJobTitleSelectJobTitleAssociatesProps } from '../../../../common/components/formFields/SelectJobTitleFormField/SelectJobTitle.types';
import { SelectSpecializationsFormField } from '../../../../common/components/formFields/SelectSpecializationsFormField';
import { JobCandidateId } from '../../../../jobCandidates/jobCandidatesTypes';
import { JobResumeRecruiterId } from '../../../../jobResumeRecruiters/jobResumeRecruitersTypes';
import { JobResumeId } from '../../../../jobResumes/jobResumesTypes';
import {
  employmentOptions,
  reasonForLeavingOptions
} from '../../../../jobs/jobs.data';
import {
  EmploymentDiscipline,
  EmploymentFields,
  EmploymentIndustries,
  EmploymentJobCandidateId,
  EmploymentJobFunctionIds,
  EmploymentJobFunctions,
  EmploymentJobResumeId,
  EmploymentJobResumeRecruiterId,
  EmploymentJobTitle,
  EmploymentJobTitleId,
  EmploymentNanoId,
  EmploymentSpecializations,
  EmploymentUserId,
  FetchEmploymentsCacheKey,
  MayBeEmploymentCity,
  MayBeEmploymentCompany,
  MayBeEmploymentEndDate,
  MayBeEmploymentStartDate,
  MayBeEmploymentState
} from '../../../employmentsTypes';
import { useCreateEmployment } from '../../../hooks/useCreateEmployment';
import { useUpdateEmployment } from '../../../hooks/useUpdateEmployment';
import { AddEditEmploymentFormData } from './AddEditEmploymentForm.types';
import { useAddEditEmploymentForm } from './hooks/useAddEditEmploymentForm';

interface AddEditEmploymentFormProps {
  jobCandidateId?: JobCandidateId;
  jobResumeId?: JobResumeId;
  jobResumeRecruiterId?: JobResumeRecruiterId;
  employmentsCacheKey: FetchEmploymentsCacheKey;
  employment?: {
    nanoId: EmploymentNanoId;
    company: MayBeEmploymentCompany;
    state: MayBeEmploymentState;
    city: MayBeEmploymentCity;
    industries: EmploymentIndustries;
    specializations: EmploymentSpecializations;
    jobTitle: EmploymentJobTitle;
    discipline: EmploymentDiscipline;
    jobFunctions: EmploymentJobFunctions;
  } & AddEditEmploymentFormData;
  type: 'new' | 'edit';
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
}

function AddEditEmploymentForm({
  jobCandidateId,
  jobResumeId,
  jobResumeRecruiterId,
  employment,
  employmentsCacheKey,
  type,
  isOpen,
  onClose,
  onSave
}: AddEditEmploymentFormProps) {
  const currentUser = useCurrentUser();

  const {
    createEmployment,
    createEmploymentErrorMessage,
    createEmploymentIsLoading
  } = useCreateEmployment({
    cacheKeys: [employmentsCacheKey]
  });

  const {
    updateEmployment,
    updateEmploymentErrorMessage,
    updateEmploymentIsLoading
  } = useUpdateEmployment({
    employmentNanoId: employment?.nanoId || ('0' as EmploymentNanoId),
    cacheKeys: [employmentsCacheKey]
  });

  const {
    watch,
    control,
    registerFields,
    validationErrors,
    addEditEmploymentFormIsLoading,
    addEditEmploymentFormErrorMessage,
    handleAddEditEmploymentForm,
    setAddEditEmploymentFormValue
  } = useAddEditEmploymentForm({
    defaultValues: employment
      ? {
          jobTitleId: employment.jobTitleId,
          jobFunctionIds: employment.jobFunctionIds || [],
          employmentType: employment.employmentType,
          startDate: DateUtils.toMonthInput(
            employment.startDate
          ) as MayBeEmploymentStartDate,
          endDate: DateUtils.toMonthInput(
            employment.endDate
          ) as MayBeEmploymentEndDate,
          currently: employment.currently,
          companyId: employment.companyId,
          cityId: employment.cityId,
          industryIds: employment.industryIds,
          specializationIds: employment.specializationIds,
          reasonForLeaving: employment.reasonForLeaving,
          moreAboutLeaving: employment.moreAboutLeaving,
          jobSatisfaction: employment.jobSatisfaction
        }
      : {},
    onAddEditEmploymentForm: async (data) => {
      console.log({ data });

      const updatedData = {
        ...data,
        endDate: data.currently ? null : data.endDate,
        reasonForLeaving: data.currently ? null : data.reasonForLeaving
      };

      if (employment) {
        await updateEmployment(updatedData);
      } else {
        await createEmployment({
          ...updatedData,
          nanoId: generateNanoId<EmploymentNanoId>(),
          userId: currentUser.id as EmploymentUserId,
          jobCandidateId: jobCandidateId as EmploymentJobCandidateId,
          jobResumeId: jobResumeId as EmploymentJobResumeId,
          jobResumeRecruiterId:
            jobResumeRecruiterId as EmploymentJobResumeRecruiterId
        });
      }

      onSave();
    }
  });

  const [selectedJobTitleIsNew, setSelectedJobTitleIsNew] =
    useState<boolean>(false);

  const currently = watch(EmploymentFields.CURRENTLY);
  const reasonForLeaving = watch(EmploymentFields.REASON_FOR_LEAVING);
  const currentJobTitleId = watch(EmploymentFields.JOB_TITLE_ID);
  const currentJobFunctionIds = watch(EmploymentFields.JOB_FUNCTION_IDS);

  const handleJobTitleAssociates = useCallback<
    (jobTitleAssociates: SelectJobTitleSelectJobTitleAssociatesProps) => void
  >(
    ({ jobFunctionId, isNewJobTitle, jobTitleId }) => {
      setSelectedJobTitleIsNew(isNewJobTitle || false);
      if (jobTitleId) {
        setAddEditEmploymentFormValue(
          EmploymentFields.JOB_TITLE_ID,
          jobTitleId as EmploymentJobTitleId
        );
      }
      if (jobFunctionId) {
        setAddEditEmploymentFormValue(EmploymentFields.JOB_FUNCTION_IDS, [
          ...currentJobFunctionIds,
          jobFunctionId
        ] as EmploymentJobFunctionIds);
      }
    },
    [setAddEditEmploymentFormValue, currentJobFunctionIds]
  );

  return (
    <CollapseFormBox
      title={type === 'new' ? 'Add Experience' : null}
      isOpen={isOpen}
      titleLevel="h5"
      stackSpacing={4}
    >
      <Form onSubmit={handleAddEditEmploymentForm}>
        <SimpleGrid columns={[1, 2]} spacing={6}>
          <GridItem colSpan={[1, 2]}>
            <SelectJobTitleFormField
              isRequired={true as IsRequired}
              control={control}
              label="Job Title"
              placeholder="Job Title"
              item={employment}
              selectedJobTitleId={currentJobTitleId}
              selectJobTitleAssocaites={handleJobTitleAssociates}
              errorMessage={validationErrors.jobTitleIdValidationError}
            />
          </GridItem>
          <GridItem colSpan={[1, 2]}>
            <SelectJobFunctionsFormField
              isRequired={true as IsRequired}
              control={control}
              item={employment}
              selectedFunctionIds={currentJobFunctionIds}
              selectedJobTitleId={currentJobTitleId}
              isNewJobtitle={selectedJobTitleIsNew}
              selectJobTitleAssocaites={handleJobTitleAssociates}
              errorMessage={validationErrors.specializationIdsValidationError}
            />
          </GridItem>
          <GridItem colSpan={[1, 2]}>
            <SelectSpecializationsFormField
              isRequired={true as IsRequired}
              control={control}
              item={employment}
              errorMessage={validationErrors.specializationIdsValidationError}
            />
          </GridItem>

          {employment ? (
            <SelectCompanyFormField
              item={employment}
              control={control}
              errorMessage={validationErrors.cityIdValidationError}
              withImages
            />
          ) : (
            <SelectCompanyFormField
              control={control}
              errorMessage={validationErrors.cityIdValidationError}
              withImages
            />
          )}
          {employment ? (
            <SelectCityFormField
              control={control}
              item={employment}
              errorMessage={validationErrors.cityIdValidationError}
            />
          ) : (
            <SelectCityFormField
              control={control}
              errorMessage={validationErrors.cityIdValidationError}
            />
          )}

          <GridItem colSpan={[1, 2]}>
            <SelectField
              control={control}
              label="Employment type"
              placeholder="Select an employment"
              name={EmploymentFields.EMPLOYMENT_TYPE}
              options={employmentOptions}
              defaultValue={find(
                employmentOptions,
                (employmentOption) =>
                  employmentOption.value === employment?.employmentType
              )}
              menuPlacement={`top` as MenuPlacement}
              errorMessage={validationErrors.employmentTypeValidationError}
            />
          </GridItem>

          <DateInputField
            label="From"
            placeholder="From"
            type="month"
            name={EmploymentFields.START_DATE}
            errorMessage={validationErrors.startDateValidationError}
            ref={registerFields.registerStartDate.ref}
            onChange={registerFields.registerStartDate.onChange}
          />

          {currently ? null : (
            <DateInputField
              label="To"
              placeholder="To"
              type="month"
              name={EmploymentFields.END_DATE}
              errorMessage={validationErrors.endDateValidationError}
              ref={registerFields.registerEndDate.ref}
              onChange={registerFields.registerEndDate.onChange}
            />
          )}

          <GridItem colSpan={[1, 2]}>
            <CheckboxField
              name={EmploymentFields.CURRENTLY}
              ref={registerFields.registerCurrently.ref}
              onChange={registerFields.registerCurrently.onChange}
            >
              <Text textStyle="body2Regular" color="gray.600">
                I currently work here
              </Text>
            </CheckboxField>
          </GridItem>

          {currently ? null : (
            <>
              <GridItem display="grid" colSpan={[1, 2]}>
                <SelectField
                  isRequired
                  control={control}
                  menuPlacement="auto"
                  isSearchable={false}
                  placeholder="Reason for leaving"
                  name={EmploymentFields.REASON_FOR_LEAVING}
                  label="Reason for leaving"
                  errorMessage={
                    validationErrors.reasonForLeavingValidationError
                  }
                  options={reasonForLeavingOptions}
                />

                <Collapse in={!!reasonForLeaving} unmountOnExit>
                  <Box pt={4} px={0.5} pb={0.5}>
                    <TextareaField
                      rows={5}
                      maxLength={300}
                      countCharacters
                      label="More details (optional)"
                      placeholder="Tell us a little more"
                      name={EmploymentFields.MORE_ABOUT_LEAVING}
                      errorMessage={
                        validationErrors.moreAboutLeavingValidationError
                      }
                      ref={registerFields.registerMoreAboutLeaving.ref}
                      onChange={
                        registerFields.registerMoreAboutLeaving.onChange
                      }
                    />
                  </Box>
                </Collapse>
              </GridItem>
            </>
          )}
        </SimpleGrid>

        <JobSatisfactionField
          control={control}
          name={registerFields.registerJobSatisfaction.name}
          errorMessage={validationErrors.jobSatisfactionValidationError}
          label="Job Satisfaction"
          justifyItems="space-around"
          justifyContent="space-around"
        />

        {(addEditEmploymentFormErrorMessage ||
          createEmploymentErrorMessage ||
          updateEmploymentErrorMessage) && (
          <SimpleGrid columns={1} spacing={6}>
            <AlertMessage
              message={
                addEditEmploymentFormErrorMessage ||
                createEmploymentErrorMessage ||
                updateEmploymentErrorMessage
              }
            />
          </SimpleGrid>
        )}

        <Flex justifyContent="end" gap={4}>
          <PureButtonHelper
            hierarchy="tertiary"
            size="small"
            px={4}
            i18nText="Cancel"
            onClick={onClose}
          />
          <PureSubmitButtonHelper
            hierarchy="primary"
            size="small"
            px={4}
            i18nText={type === 'new' ? 'Add' : 'Update'}
            isLoading={
              addEditEmploymentFormIsLoading ||
              createEmploymentIsLoading ||
              updateEmploymentIsLoading
            }
          />
        </Flex>
      </Form>
    </CollapseFormBox>
  );
}

export default AddEditEmploymentForm;

import { Flex, FormLabel } from '@chakra-ui/react';
import { CheckboxField } from '../../../../../../../helpers/forms/formFields/CheckboxField';
import { CheckboxFieldProps } from '../../../../../../../helpers/forms/formFields/CheckboxField/CheckboxField';
import { Text } from '../../../../../../../helpers/Text';

export default function TalentPoolFilterPopupSectionItem({
  label,
  size = 'extra-small',
  fontWeight = 'normal',
  fontSize = 'sm',
  ...props
}: CheckboxFieldProps) {
  return (
    <Flex>
      <FormLabel m={0} p={0} gap={2} display="flex" cursor="pointer">
        <CheckboxField size={size} {...props} />

        <Text fontWeight={fontWeight} fontSize={fontSize}>
          {label}
        </Text>
      </FormLabel>
    </Flex>
  );
}

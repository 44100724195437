import {
  Flex,
  SystemStyleObject,
  useRadio,
  UseRadioProps
} from '@chakra-ui/react';
import { ReactNode } from 'react';

interface SelectJobSatisfactionRadioProps extends UseRadioProps {
  label: ReactNode;
  itemStyles?: SystemStyleObject;
  _checked?: SystemStyleObject;
}

function SelectJobSatisfactionRadio({
  label,
  itemStyles,
  _checked,
  ...props
}: SelectJobSatisfactionRadioProps) {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Flex flex={1} alignItems="flex-start" justifyContent="center">
      <Flex as="label">
        <input {...input} />

        <Flex
          w="full"
          {...checkbox}
          sx={itemStyles}
          h="full"
          cursor="pointer"
          alignItems="center"
          justifyContent="center"
          _checked={_checked}
          _focus={{
            boxShadow: 'outline'
          }}
        >
          {label}
        </Flex>
      </Flex>
    </Flex>
  );
}

export default SelectJobSatisfactionRadio;

import { IconProps } from '@chakra-ui/react';
import {
  SmileySadIcon,
  SmileyIndifferentIcon,
  SmileyHappyIcon,
  SmileyVeryHappyIcon
} from '../../../../icons/SmileyIcons';
import { SmileySmileIcon } from '../../../../icons/SmileyIcons/Smile';
import { JobSatisfactionFieldValues } from './JobSatisfactionField.types';

export const jobSatisfactionOptions = [
  {
    label: 'Stressful',
    color: 'red.500',
    value: JobSatisfactionFieldValues.STRESSFUL,
    icon: (props?: IconProps) => (
      <SmileySadIcon
        w={8}
        h={8}
        strokeWidth={1}
        transitionDuration="slow"
        {...props}
      />
    )
  },
  {
    label: 'Not Satisfied',
    color: 'orange.500',
    value: JobSatisfactionFieldValues.NOT_SATISFIED,
    icon: (props?: IconProps) => (
      <SmileyIndifferentIcon
        w={8}
        h={8}
        strokeWidth={1}
        transitionDuration="slow"
        {...props}
      />
    )
  },
  {
    label: 'Satisfied',
    color: 'yellow.500',
    value: JobSatisfactionFieldValues.SATISFIED,
    icon: (props?: IconProps) => (
      <SmileySmileIcon
        w={8}
        h={8}
        strokeWidth={1}
        transitionDuration="slow"
        {...props}
      />
    )
  },
  {
    label: 'Good',
    color: 'teal.500',
    value: JobSatisfactionFieldValues.GOOD,
    icon: (props?: IconProps) => (
      <SmileyHappyIcon
        w={8}
        h={8}
        strokeWidth={1}
        transitionDuration="slow"
        {...props}
      />
    )
  },
  {
    label: 'Great',
    color: 'green.500',
    value: JobSatisfactionFieldValues.GREAT,
    icon: (props?: IconProps) => (
      <SmileyVeryHappyIcon
        w={8}
        h={8}
        strokeWidth={1}
        transitionDuration="slow"
        {...props}
      />
    )
  }
];

import { Icon, IconProps } from '@chakra-ui/icon';

const LicenseIcon = ({ w = '48px', h = '48px', ...props }: IconProps) => (
  <Icon viewBox="0 0 48 48" w={w} h={h} fill="none" {...props}>
    <rect width={48} height={48} rx={24} fill="#E1EAED" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m14.438 33.196 3.14-.471 1.638 2.746c.196.33.548.528.925.528.022 0 .043 0 .066-.002.401-.024.756-.273.92-.643l2.614-5.913c-3.087-.083-5.814-1.624-7.54-3.968l-2.897 6.177c-.17.363-.13.793.108 1.116.238.324.636.487 1.026.43M24.259 29.442l2.614 5.913c.165.37.519.62.92.643.023.002.044.002.067.002.377 0 .729-.198.924-.528l1.639-2.747 3.14.472c.389.055.787-.106 1.025-.43a1.1 1.1 0 0 0 .108-1.117l-2.898-6.177c-1.724 2.344-4.451 3.886-7.54 3.969M24 17.454a2.17 2.17 0 0 1 2.16 2.182A2.17 2.17 0 0 1 24 21.818a2.17 2.17 0 0 1-2.16-2.182A2.17 2.17 0 0 1 24 17.455m0 9.818c4.167 0 7.56-3.426 7.56-7.637C31.56 15.426 28.166 12 24 12c-4.168 0-7.56 3.425-7.56 7.636s3.392 7.637 7.56 7.637"
      fill="#98A5A9"
    />
  </Icon>
);

export default LicenseIcon;

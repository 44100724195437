import { Flex, Stack } from '@chakra-ui/react';
import CustomiseCandidateEvaluation from './CustomiseCandidateEvaluation';

function ViewJobCandidateDetailsEvaluationSidebar() {
  return (
    <Stack
      spacing={0}
      top="73px"
      right={0}
      w="400px"
      zIndex={999}
      pos="fixed"
      bg="white"
      flexDir="column"
      pointerEvents="none"
      h="calc(100vh - 137px)"
      borderLeft="1px solid"
      borderLeftColor="gray.200"
    >
      <Flex
        px={6}
        pt={4}
        pb={6}
        mr={-6}
        gap={10}
        h="full"
        w="full"
        overflow="auto"
        flexDir="column"
        pointerEvents="all"
      >
        <CustomiseCandidateEvaluation />
      </Flex>
    </Stack>
  );
}

export default ViewJobCandidateDetailsEvaluationSidebar;

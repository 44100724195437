import {
  ReactHookFormFieldError,
  useReactHookForm
} from '../../../../../../../common/hooks/base/useReactHookForm';
import { DateUtils } from '../../../../../../../utils/DateUtils';

import {
  EducationFields,
  EducationDegree,
  EducationSchoolName,
  EducationStudyField,
  MayBeEducationCityId,
  MayBeEducationEndDate,
  MayBeEducationStartDate
} from '../../../../../educationsTypes';

// import { useAddEditEducationFormValidationRules } from '../useAddEditEducationFormValidationRules';

import {
  AddEditEducationFormData,
  OnAddEditEducationForm
} from '../../AddEditEducationForm.types';

const defaultAddEditEducationFormValues: AddEditEducationFormData = {
  degree: '' as EducationDegree,
  schoolName: '' as EducationSchoolName,
  studyField: '' as EducationStudyField,
  startDate: null,
  endDate: null,
  cityId: null
};

interface AddEditEducationFormOptions {
  defaultValues?: Partial<AddEditEducationFormData>;
  onAddEditEducationForm: OnAddEditEducationForm;
}

function useAddEditEducationForm({
  defaultValues = {},
  onAddEditEducationForm
}: AddEditEducationFormOptions) {
  const {
    control,
    watch,
    errors,
    isLoading,
    isValid,
    isSubmitted,
    isSubmitSuccessful,
    errorMessage,
    register,
    resetForm,
    handleSubmitReactHookForm,
    setValue
  } = useReactHookForm<AddEditEducationFormData>({
    defaultValues: {
      ...defaultAddEditEducationFormValues,
      ...defaultValues
    }
  });

  // const { nameRules } = useAddEditEducationFormValidationRules();

  return {
    control,
    watch,
    validationErrors: {
      degreeValidationError: (errors.degree as ReactHookFormFieldError)
        ?.message,
      schoolNameValidationError: (errors.schoolName as ReactHookFormFieldError)
        ?.message,
      studyFieldValidationError: (errors.studyField as ReactHookFormFieldError)
        ?.message,
      startDateValidationError: (errors.startDate as ReactHookFormFieldError)
        ?.message,
      endDateValidationError: (errors.endDate as ReactHookFormFieldError)
        ?.message,
      cityIdValidationError: (errors.cityId as ReactHookFormFieldError)?.message
    },
    addEditEducationFormIsLoading: isLoading,
    addEditEducationFormIsValid: isValid,
    addEditEducationFormIsSubmitted: isSubmitted,
    addEditEducationFormIsSubmitSuccessful: isSubmitSuccessful,
    addEditEducationFormErrorMessage: errorMessage,
    resetAddEditEducationForm: resetForm,
    setAddEditEducationFormValue: setValue,
    registerFields: {
      registerDegree: register(EducationFields.DEGREE),
      registerSchoolName: register(EducationFields.SCHOOL_NAME),
      registerStudyField: register(EducationFields.STUDY_FIELD),
      registerStartDate: register(EducationFields.START_DATE),
      registerEndDate: register(EducationFields.END_DATE),
      registerCityId: register(EducationFields.CITY_ID)
    },
    handleAddEditEducationForm: handleSubmitReactHookForm({
      onSubmit: (data) =>
        onAddEditEducationForm?.({
          degree: data.degree as EducationDegree,
          schoolName: data.schoolName as EducationSchoolName,
          studyField: data.studyField as EducationStudyField,
          startDate: DateUtils.formatDate(
            data.startDate
          ) as MayBeEducationStartDate,
          endDate: DateUtils.formatDate(data.endDate) as MayBeEducationEndDate,
          cityId: data.cityId as MayBeEducationCityId
        })
    })
  };
}

export default useAddEditEducationForm;

import {
  CandidateOverallEvaluationType,
  CandidateOverallEvaluationEnums
} from '../../CandidateOverallEvaluation.types';

function renderCandidateOverallEvaluation(
  candidateOverallEvaluation: CandidateOverallEvaluationType
) {
  switch (candidateOverallEvaluation) {
    case CandidateOverallEvaluationEnums.HireNow:
      return `Hire 'em now`;
    case CandidateOverallEvaluationEnums.GreatFit:
      return `Great fit`;
    case CandidateOverallEvaluationEnums.GoodFit:
      return `Good fit`;
    case CandidateOverallEvaluationEnums.OkFit:
      return `Ok fit`;
    case CandidateOverallEvaluationEnums.NotAFit:
      return `Not a fit`;
    default:
      return 'Unknown';
  }
}

export default renderCandidateOverallEvaluation;

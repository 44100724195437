import { Icon, IconProps } from '@chakra-ui/icon';

const EducationIcon = ({ w = '48px', h = '48px', ...props }: IconProps) => (
  <Icon viewBox="0 0 48 48" w={w} h={h} fill="none" {...props}>
    <circle cx={24} cy={24} r={24} fill="#E1EAED" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m36.617 19.37-12-7.2a1.206 1.206 0 0 0-1.234 0l-12 7.2a1.2 1.2 0 0 0-.07 2.012l11.999 8.4a1.202 1.202 0 0 0 1.376 0l8.912-6.238v6.454a1.2 1.2 0 1 0 2.4 0v-8.134l.688-.482c.333-.234.526-.619.511-1.026a1.197 1.197 0 0 0-.581-.986"
      fill="#98A5A9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m32.4 27.314-6.336 4.435c-.61.426-1.322.65-2.064.65a3.585 3.585 0 0 1-2.063-.65L15.6 27.315v3.885c0 2.736 3.61 4.8 8.4 4.8 4.79 0 8.4-2.064 8.4-4.8v-3.886Z"
      fill="#98A5A9"
    />
  </Icon>
);

export default EducationIcon;

const nameRules = {
  required: 'Name is required'
};

function useAddEditCertificationFormValidationRules() {
  return {
    nameRules
  };
}

export default useAddEditCertificationFormValidationRules;

import {
  FetchJobScorecardQuestionsQuery,
  FetchJobScorecardQuestionsCountType,
  FetchJobScorecardQuestionsPage,
  FetchJobScorecardQuestionsPageSize,
  FetchJobScorecardQuestionsFilters,
  FetchJobScorecardQuestionsSort,
  FetchJobScorecardQuestionsIndexQueryName,
  FetchJobScorecardQuestionsIndexCountType,
  FetchJobScorecardQuestionsIndexPage,
  FetchJobScorecardQuestionsIndexPageSize,
  FetchJobScorecardQuestionsIndexFilters,
  FetchJobScorecardQuestionsIndexSort,
  FetchJobScorecardQuestionQuery,
  FetchJobScorecardQuestionIndexQueryName,
  CreateJobScorecardQuestionError,
  UpdateJobScorecardQuestionError,
  DeleteJobScorecardQuestionError,
  JobScorecardQuestionId,
  JobScorecardQuestionName,
  JobScorecardQuestionUuid,
  JobScorecardQuestionJobScorecardId
} from './jobScorecardQuestionsTypes';

import { BaseBffRequests } from '../BaseBffRequests';

import { JobScorecardQuestionsBffRoutes } from './JobScorecardQuestionsBffRoutes';

interface FetchJobScorecardQuestionsRequestParams {
  query: FetchJobScorecardQuestionsQuery;
  countType?: FetchJobScorecardQuestionsCountType;
  page?: FetchJobScorecardQuestionsPage;
  pageSize?: FetchJobScorecardQuestionsPageSize;
  filters?: FetchJobScorecardQuestionsFilters;
  sort?: FetchJobScorecardQuestionsSort;
}

interface FetchJobScorecardQuestionsIndexRequestParams {
  queryName: FetchJobScorecardQuestionsIndexQueryName;
  countType?: FetchJobScorecardQuestionsIndexCountType;
  page?: FetchJobScorecardQuestionsIndexPage;
  pageSize?: FetchJobScorecardQuestionsIndexPageSize;
  filters?: FetchJobScorecardQuestionsIndexFilters;
  sort?: FetchJobScorecardQuestionsIndexSort;
}

interface FetchJobScorecardQuestionRequestParams {
  query: FetchJobScorecardQuestionQuery;
}

interface FetchJobScorecardQuestionIndexRequestParams {
  queryName: FetchJobScorecardQuestionIndexQueryName;
}

export interface JobScorecardQuestionRequestData {
  name: JobScorecardQuestionName;
  jobScorecardId: JobScorecardQuestionJobScorecardId;
}

export interface CreateJobScorecardQuestionDefaultRequestData
  extends JobScorecardQuestionRequestData {
  id?: JobScorecardQuestionId;
}

export type CreateJobScorecardQuestionRequestData =
  CreateJobScorecardQuestionDefaultRequestData;

export type UpdateJobScorecardQuestionRequestData = {
  name: JobScorecardQuestionName;
};

export interface CreateJobScorecardQuestionErrorResponse {
  message: CreateJobScorecardQuestionError;
}

export interface UpdateJobScorecardQuestionErrorResponse {
  message: UpdateJobScorecardQuestionError;
}

export interface DeleteJobScorecardQuestionErrorResponse {
  message: DeleteJobScorecardQuestionError;
}

export class JobScorecardQuestionsBffRequests extends BaseBffRequests {
  static fetchJobScorecardQuestions<T>(
    params: FetchJobScorecardQuestionsRequestParams
  ) {
    return this.fetchItems<T>(
      JobScorecardQuestionsBffRoutes.fetchJobScorecardQuestionsRoute(),
      params
    );
  }

  static fetchJobScorecardQuestionsIndex<T>(
    params: FetchJobScorecardQuestionsIndexRequestParams
  ) {
    return this.fetchItemsIndex<T>(
      JobScorecardQuestionsBffRoutes.fetchJobScorecardQuestionsIndexRoute(),
      params
    );
  }

  static fetchJobScorecardQuestion<T>(
    jobScorecardQuestionId: JobScorecardQuestionId,
    params: FetchJobScorecardQuestionRequestParams
  ) {
    return this.fetchItem<T>(
      JobScorecardQuestionsBffRoutes.fetchJobScorecardQuestionRoute(
        jobScorecardQuestionId
      ),
      params
    );
  }

  static fetchJobScorecardQuestionIndex<T>(
    jobScorecardQuestionUuId: JobScorecardQuestionUuid,
    params: FetchJobScorecardQuestionIndexRequestParams
  ) {
    return this.fetchItemIndex<T>(
      JobScorecardQuestionsBffRoutes.fetchJobScorecardQuestionIndexRoute(
        jobScorecardQuestionUuId
      ),
      params
    );
  }

  static createJobScorecardQuestion<T>(
    createJobScorecardQuestionData: CreateJobScorecardQuestionRequestData
  ) {
    return this.createItem<T, CreateJobScorecardQuestionRequestData>(
      JobScorecardQuestionsBffRoutes.createJobScorecardQuestionRoute(),
      createJobScorecardQuestionData
    );
  }

  static async updateJobScorecardQuestion<T>(
    updateJobScorecardQuestionId: JobScorecardQuestionId,
    updateJobScorecardQuestionData: UpdateJobScorecardQuestionRequestData
  ) {
    return this.updateItem<T, UpdateJobScorecardQuestionRequestData>(
      JobScorecardQuestionsBffRoutes.updateJobScorecardQuestionRoute(
        updateJobScorecardQuestionId
      ),
      updateJobScorecardQuestionData
    );
  }

  static async deleteJobScorecardQuestion<T>(
    deleteJobScorecardQuestionId: JobScorecardQuestionId
  ) {
    return this.deleteItem<T>(
      JobScorecardQuestionsBffRoutes.deleteJobScorecardQuestionRoute(
        deleteJobScorecardQuestionId
      )
    );
  }
}

import {
  useReactHookForm,
  ReactHookFormFieldError
} from '../../../../../../../../common/hooks/base/useReactHookForm';

import {
  JobFields,
  JobName,
  JobUserJobId,
  JobWorkplace,
  JobWorkAuthorization,
  JobNotes,
  JobEmployment,
  JobBaseSalary,
  JobExperience,
  JobSeniorityLevel,
  JobHiringManagerId,
  JobRecruitingCoordinatorId,
  JobInterviewerIds,
  JobCompanyIds,
  JobGeography,
  JobNumberOfOpenings,
  JobRecruiterId,
  JobCityIds,
  JobDisciplineId,
  JobJobFunctionId,
  JobDepartmentId,
  JobCertificationIds,
  JobStudyField,
  JobDegree,
  JobPaidTimeOff,
  JobSpecializationIds
} from '../../../../../../jobsTypes';

import { usePostJobIntakeFormValidationRules } from '../usePostJobIntakeFormValidationRules';

import {
  OnPostJobIntakeForm,
  OnPostJobIntakeFormError,
  PostJobIntakeFormData
} from '../../PostJobIntakeForm.types';

const defaultPostJobIntakeFormValues: PostJobIntakeFormData = {
  name: '' as JobName,
  departmentId: '' as JobDepartmentId,
  disciplineId: '' as JobDisciplineId,
  userJobId: '' as JobUserJobId,
  workplace: '' as JobWorkplace,
  workAuthorization: '' as JobWorkAuthorization,
  notes: '' as JobNotes,
  cityIds: null,
  hiringManagerId: null,
  recruitingCoordinatorId: null,
  interviewerIds: [] as JobInterviewerIds,
  companyIds: [] as JobCompanyIds,
  specializationIds: [] as JobSpecializationIds,
  employment: '' as JobEmployment,
  baseSalary: '' as JobBaseSalary,
  experience: '' as JobExperience,
  seniorityLevel: '' as JobSeniorityLevel,
  geography: '' as JobGeography,
  numberOfOpenings: '' as JobNumberOfOpenings,
  recruiterId: '' as JobRecruiterId,
  jobFunctionId: '' as JobJobFunctionId,
  certificationIds: [] as JobCertificationIds,
  studyField: '' as JobStudyField,
  degree: '' as JobDegree,
  paidTimeOff: '' as JobPaidTimeOff
};

interface PostJobIntakeFormOptions {
  defaultValues?: Partial<PostJobIntakeFormData>;
  onPostJobIntakeForm: OnPostJobIntakeForm;
  onPostJobIntakeFormError?: OnPostJobIntakeFormError;
}

function usePostJobIntakeForm({
  defaultValues = {},
  onPostJobIntakeForm,
  onPostJobIntakeFormError
}: PostJobIntakeFormOptions) {
  const {
    control,
    watch,
    errors,
    isLoading,
    isValid,
    isSubmitted,
    isSubmitSuccessful,
    errorMessage,
    register,
    resetForm,
    handleSubmitReactHookForm,
    setValue
  } = useReactHookForm<PostJobIntakeFormData>({
    defaultValues: { ...defaultPostJobIntakeFormValues, ...defaultValues }
  });

  const {
    jobNameRules,
    departmentIdRules,
    disciplineIdRules,
    workplaceRules,
    workAuthorizationRules,
    cityIdsRules,
    hiringManagerIdRules,
    recruitingCoordinatorIdRules,
    interviewerIdsRules,
    companyIdsRules,
    employmentRules,
    baseSalaryRules,
    experienceRules,
    seniorityLevelRules,
    jobFunctionIdRules,
    geographyRules,
    specializationIdsRules
  } = usePostJobIntakeFormValidationRules();

  return {
    control,
    watch,
    validationErrors: {
      nameValidationError: (errors.name as ReactHookFormFieldError)?.message,
      departmentIdValidationError: (
        errors.departmentId as ReactHookFormFieldError
      )?.message,
      disciplineIdValidationError: (
        errors.disciplineId as ReactHookFormFieldError
      )?.message,
      userJobIdValidationError: (errors.userJobId as ReactHookFormFieldError)
        ?.message,
      workplaceValidationError: (errors.workplace as ReactHookFormFieldError)
        ?.message,
      workAuthorizationValidationError: (
        errors.workAuthorization as ReactHookFormFieldError
      )?.message,
      notesValidationError: (errors.notes as ReactHookFormFieldError)?.message,
      cityIdsValidationError: (errors.cityIds as ReactHookFormFieldError)
        ?.message,
      hiringManagerIdValidationError: (
        errors.hiringManagerId as ReactHookFormFieldError
      )?.message,
      recruitingCoordinatorIdValidationError: (
        errors.recruitingCoordinatorId as ReactHookFormFieldError
      )?.message,
      interviewerIdsValidationError: (
        errors.interviewerIds as unknown as ReactHookFormFieldError
      )?.message,
      recruiterIdValidationError: (
        errors.recruiterId as unknown as ReactHookFormFieldError
      )?.message,
      companyIdsValidationError: (
        errors.companyIds as unknown as ReactHookFormFieldError
      )?.message,
      specializationIdsValidationError: (
        errors.specializationIds as unknown as ReactHookFormFieldError
      )?.message,
      employmentValidationError: (errors.employment as ReactHookFormFieldError)
        ?.message,
      geographyValidationError: (errors.geography as ReactHookFormFieldError)
        ?.message,
      baseSalaryValidationError: (errors.baseSalary as ReactHookFormFieldError)
        ?.message,
      experienceValidationError: (errors.experience as ReactHookFormFieldError)
        ?.message,
      seniorityLevelValidationError: (
        errors.seniorityLevel as ReactHookFormFieldError
      )?.message,
      jobFunctionIdValidationError: (
        errors.jobFunctionId as ReactHookFormFieldError
      )?.message,
      certificationIdsValidationError: (
        errors.certificationIds as ReactHookFormFieldError
      )?.message,
      degreeValidationError: (errors.degree as ReactHookFormFieldError)
        ?.message,
      studyFieldValidationError: (errors.studyField as ReactHookFormFieldError)
        ?.message,
      paidTimeOffValidationError: (
        errors.paidTimeOff as ReactHookFormFieldError
      )?.message
    },
    postJobIntakeFormIsLoading: isLoading,
    postJobIntakeFormIsValid: isValid,
    postJobIntakeFormIsSubmitted: isSubmitted,
    postJobIntakeFormIsSubmitSuccessful: isSubmitSuccessful,
    postJobIntakeFormErrorMessage: errorMessage,
    resetPostJobIntakeForm: resetForm,
    setPostJobIntakeFormValue: setValue,
    registerFields: {
      registerName: register(JobFields.NAME, jobNameRules),
      registerDepartmentId: register(
        JobFields.DEPARTMENT_ID,
        departmentIdRules
      ),
      registerDisciplineId: register(
        JobFields.DISCIPLINE_ID,
        disciplineIdRules
      ),
      registerUserJobId: register(JobFields.USER_JOB_ID),
      registerWorkplace: register(JobFields.WORKPLACE, workplaceRules),
      registerWorkAuthorization: register(
        JobFields.WORK_AUTHORIZATION,
        workAuthorizationRules
      ),
      registerNotes: register(JobFields.NOTES),
      registerCityIds: register(JobFields.CITY_IDS, cityIdsRules),
      registerHiringManagerId: register(
        JobFields.HIRING_MANAGER_ID,
        hiringManagerIdRules
      ),
      registerRecruitingCoordinatorId: register(
        JobFields.RECRUITING_COORDINATOR_ID,
        recruitingCoordinatorIdRules
      ),
      registerInterviewerIds: register(
        JobFields.INTERVIEWER_IDS,
        interviewerIdsRules
      ),
      registerCompanyIds: register(JobFields.COMPANY_IDS, companyIdsRules),
      registerSpecializationIds: register(
        JobFields.SPECIALIZATION_IDS,
        specializationIdsRules
      ),
      registerEmployment: register(JobFields.EMPLOYMENT, employmentRules),
      registerGeography: register(JobFields.GEOGRAPHY, geographyRules),
      registerBaseSalary: register(JobFields.BASE_SALARY, baseSalaryRules),
      registerExperience: register(JobFields.EXPERIENCE, experienceRules),
      registerSeniorityLevel: register(
        JobFields.SENIORITY_LEVEL,
        seniorityLevelRules
      ),
      registerJobFunctionId: register(
        JobFields.JOB_FUNCTION_ID,
        jobFunctionIdRules
      ),
      registerCertificationIds: register(JobFields.CERTIFICATION_IDS),
      registerStudyField: register(JobFields.STUDY_FIELD),
      registerDegree: register(JobFields.DEGREE),
      registerPaidTimeOff: register(JobFields.PAID_TIME_OFF)
    },
    handlePostJobIntakeForm: handleSubmitReactHookForm({
      onSubmit: (data) =>
        onPostJobIntakeForm?.({
          name: data.name as JobName,
          departmentId: data.departmentId as JobDepartmentId,
          disciplineId: data.disciplineId as JobDisciplineId,
          userJobId: data.userJobId as JobUserJobId,
          workplace: data.workplace as JobWorkplace,
          workAuthorization: data.workAuthorization as JobWorkAuthorization,
          notes: data.notes as JobNotes,
          cityIds: data.cityIds as JobCityIds,
          hiringManagerId: data.hiringManagerId as JobHiringManagerId,
          recruitingCoordinatorId:
            data.recruitingCoordinatorId as JobRecruitingCoordinatorId,
          interviewerIds: data.interviewerIds as JobInterviewerIds,
          companyIds: data.companyIds as JobCompanyIds,
          employment: data.employment as JobEmployment,
          baseSalary: data.baseSalary as JobBaseSalary,
          experience: data.experience as JobExperience,
          seniorityLevel: data.seniorityLevel as JobSeniorityLevel,
          geography: data.geography as JobGeography,
          numberOfOpenings: data.numberOfOpenings as JobNumberOfOpenings,
          recruiterId: data.recruiterId as JobRecruiterId,
          jobFunctionId: data.jobFunctionId as JobJobFunctionId,
          studyField: data.studyField as JobStudyField,
          degree: data.degree as JobDegree,
          certificationIds: data.certificationIds as JobCertificationIds,
          paidTimeOff: data.paidTimeOff as JobPaidTimeOff,
          specializationIds: data.specializationIds as JobSpecializationIds
        }),
      onError: onPostJobIntakeFormError
    })
  };
}

export default usePostJobIntakeForm;

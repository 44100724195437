import React from 'react';
import { Avatar, Flex, HStack, Stack } from '@chakra-ui/react';
import compact from 'lodash/compact';

import { IsLoading } from '../../../../types';

import { DotIcon } from '../../../../icons/DotIcon';
import { LicenseIcon } from '../../../../icons/Placeholders/LicenseIcon';

import { Heading } from '../../../../helpers/Heading';
import { Text } from '../../../../helpers/Text';
import { EditDeleteButtonGroup } from '../../../../helpers/EditDeleteButtonGroup';

import { DateUtils } from '../../../../utils/DateUtils';

import { EditDeleteCertificationItemCertification } from './EditDeleteCertificationItem.types';

interface EditDeleteJobCandidateExperienceItemProps {
  certification: EditDeleteCertificationItemCertification;
  deleteCertificationIsLoading: IsLoading;
  onEditCertification: () => void;
  onDeleteCertification: () => void;
  editable?: boolean;
  deletable?: boolean;
}

function EditDeleteCertificationItem({
  certification,
  deleteCertificationIsLoading,
  onEditCertification,
  onDeleteCertification,
  editable = true,
  deletable = true
}: EditDeleteJobCandidateExperienceItemProps) {
  return (
    <Flex gap={4} alignItems="center">
      <Avatar size="md" icon={<LicenseIcon />} />

      <Stack spacing={1}>
        <HStack spacing={4}>
          <Heading
            level="h5"
            color="gray.900"
            textTransform="capitalize"
            fontWeight={500}
          >
            {certification.name}
          </Heading>
          <Text textStyle="body2Regular" color="gray.600" ml="auto">
            {compact([
              DateUtils.formatMonth(certification.startDate),
              DateUtils.formatMonth(certification.endDate)
            ]).join(' - ')}
          </Text>
        </HStack>
        <HStack
          spacing={2}
          divider={<DotIcon h="4px" w="4px" color="gray.600" />}
        >
          {certification.company ? (
            <Text textStyle="body1Regular" color="gray.600">
              {certification.company?.name}
            </Text>
          ) : null}
          {certification.issueDate ? (
            <Text textStyle="body1Regular" color="gray.600">
              {DateUtils.formatMonth(certification.issueDate)}
            </Text>
          ) : null}
        </HStack>
      </Stack>
      <Flex ml="auto">
        {editable && deletable ? (
          <EditDeleteButtonGroup
            deleteDisabled={deleteCertificationIsLoading}
            onEdit={onEditCertification}
            onDelete={onDeleteCertification}
          />
        ) : null}

        {editable && !deletable ? (
          <EditDeleteButtonGroup
            onEdit={onEditCertification}
            withoutDeleteButton
          />
        ) : null}

        {deletable && !editable ? (
          <EditDeleteButtonGroup
            deleteDisabled={deleteCertificationIsLoading}
            onDelete={onDeleteCertification}
            withoutEditButton
          />
        ) : null}
      </Flex>
    </Flex>
  );
}

export default EditDeleteCertificationItem;

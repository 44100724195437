import { Avatar, Box, HStack, Stack, StackDivider } from '@chakra-ui/react';
import compact from 'lodash/compact';
import size from 'lodash/size';
import { AlertMessage } from '../../../../../../../../helpers/AlertMessage';
import { Heading } from '../../../../../../../../helpers/Heading';
import { Loader } from '../../../../../../../../helpers/Loader';
import { Text } from '../../../../../../../../helpers/Text';
import { LicenseIcon } from '../../../../../../../../icons/Placeholders/LicenseIcon';
import { DateUtils } from '../../../../../../../../utils/DateUtils';
import { CertificationsCache } from '../../../../../../../certifications/CertificationsCache';
import {
  CertificationEndDate,
  CertificationFields,
  CertificationId,
  CertificationIssueDate,
  CertificationName,
  CertificationStartDate,
  FetchCertificationsFilters,
  FetchCertificationsSort,
  MayBeCertificationCompany
} from '../../../../../../../certifications/certificationsTypes';
import { CertificationsListForm } from '../../../../../../../certifications/components/forms/CertificationsListForm';
import { usePaginatedCertifications } from '../../../../../../../certifications/hooks/usePaginatedCertifications';
import {
  FetchCertificationsResponse,
  fetchCertificationsQuery
} from '../../../../../../../certifications/queries/fetchCertifications.query';
import { JobCandidateId } from '../../../../../../../jobCandidates/jobCandidatesTypes';
import { JobResumeRecruiterId } from '../../../../../../../jobResumeRecruiters/jobResumeRecruitersTypes';
import { ViewJobCandidateDetailsPageTabSection } from '../../../../components/ViewJobCandidateDetailsPageTabSection';
import { ViewCandidateDetailsReactions } from '../ViewCandidateDetailsReactions';

interface ViewCandidateDetailsLicensesJobCandidateProps {
  jobCandidateId: JobCandidateId;
  jobResumeRecruiterId?: never;
}

interface ViewCandidateDetailsLicensesJobResumeRecruiterProps {
  jobCandidateId?: never;
  jobResumeRecruiterId: JobResumeRecruiterId;
}

type ViewCandidateDetailsLicensesProps = {
  isEditing?: boolean;
} & (
  | ViewCandidateDetailsLicensesJobCandidateProps
  | ViewCandidateDetailsLicensesJobResumeRecruiterProps
);

function ViewCandidateDetailsLicenses({
  isEditing,
  jobCandidateId,
  jobResumeRecruiterId
}: ViewCandidateDetailsLicensesProps) {
  const certificationsCacheKey =
    (jobCandidateId
      ? CertificationsCache.jobCandidateListCacheKey(jobCandidateId)
      : null) ||
    (jobResumeRecruiterId
      ? CertificationsCache.jobResumeRecruiterListCacheKey(jobResumeRecruiterId)
      : null) ||
    CertificationsCache.indexCacheKey();

  const {
    certifications,
    certificationsIsFetched,
    certificationsErrorMessage
  } = usePaginatedCertifications<FetchCertificationsResponse>({
    query: fetchCertificationsQuery,
    cacheKey: certificationsCacheKey,
    initialFilters: {
      ...(jobCandidateId
        ? {
            [CertificationFields.JOB_CANDIDATE_ID]: {
              operator: 'eq',
              value: jobCandidateId
            }
          }
        : {}),
      ...(jobResumeRecruiterId
        ? {
            [CertificationFields.JOB_RESUME_RECRUITER_ID]: {
              operator: 'eq',
              value: jobResumeRecruiterId
            }
          }
        : {})
    } as unknown as FetchCertificationsFilters,
    initialSort: {
      [CertificationFields.CREATED_AT]: {
        ascending: false
      }
    } as unknown as FetchCertificationsSort
  });

  return (
    <ViewJobCandidateDetailsPageTabSection title="Licenses">
      {isEditing ? (
        <>
          {jobCandidateId && (
            <CertificationsListForm
              jobCandidateId={jobCandidateId}
              withoutHeading
              withStackDivider
              alignButtonToRight
            />
          )}

          {jobResumeRecruiterId && (
            <CertificationsListForm
              jobResumeRecruiterId={jobResumeRecruiterId}
              withoutHeading
              withStackDivider
              alignButtonToRight
            />
          )}
        </>
      ) : (
        <>
          {certificationsErrorMessage && (
            <AlertMessage message={certificationsErrorMessage} />
          )}

          <Loader loaded={certificationsIsFetched}>
            {size(certifications) > 0 && (
              <Stack spacing={4} divider={<StackDivider />}>
                {certifications.map((certification) => (
                  <ViewCandidateDetailsCertificationItem
                    key={certification.id}
                    certification={certification}
                  />
                ))}

                {jobCandidateId && (
                  <ViewCandidateDetailsReactions hasComments />
                )}
              </Stack>
            )}
          </Loader>
        </>
      )}
    </ViewJobCandidateDetailsPageTabSection>
  );
}

export default ViewCandidateDetailsLicenses;

type ViewCandidateDetailsCertificationItemType = {
  id: CertificationId;
  name: CertificationName;
  company: MayBeCertificationCompany;
  issueDate: CertificationIssueDate;
  startDate: CertificationStartDate;
  endDate: CertificationEndDate;
};

interface ViewCandidateDetailsCertificationItemProps {
  certification: ViewCandidateDetailsCertificationItemType;
}

function ViewCandidateDetailsCertificationItem({
  certification
}: ViewCandidateDetailsCertificationItemProps) {
  return (
    <HStack as="article" spacing={4}>
      <Avatar icon={<LicenseIcon />} />

      <Stack spacing={0}>
        <Heading level="h5" fontWeight="medium" fontSize="md">
          {certification.name}
        </Heading>

        <HStack divider={<Box color="gray.600">•</Box>}>
          {certification.company?.name && (
            <Text color="gray.600" textStyle="body1Regular">
              {certification.company?.name}
            </Text>
          )}

          {(certification.issueDate ||
            certification.startDate ||
            certification.endDate) && (
            <Text color="gray.600" textStyle="body2Regular">
              {certification.issueDate
                ? DateUtils.formatMonth(certification.issueDate)
                : compact([
                    DateUtils.formatMonth(certification.startDate),
                    DateUtils.formatMonth(certification.endDate)
                  ]).join(' - ')}
            </Text>
          )}
        </HStack>
      </Stack>
    </HStack>
  );
}

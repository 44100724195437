import {
  MayBe,
  FieldBrand,
  ItemID,
  ItemUUID,
  ItemNanoId,
  ItemCreatedAt
} from '../../../types';

import {
  JobCandidateArrangement,
  JobCandidateCity,
  JobCandidateDisqualificationReason,
  JobCandidateId,
  JobCandidateImage,
  JobCandidateJobTitle,
  JobCandidateName,
  JobCandidateNanoId,
  JobCandidateState,
  JobCandidateSearchType,
  JobCandidateSearchTypeLabel,
  JobCandidateWorkflowStageTask,
  JobCandidateWorkflowStageTaskApplicationType,
  MayBeJobCandidateWorkflowStageTaskScheduledAt,
  MayBeJobCandidateDueDate,
  MayBeJobCandidateDisqualifiedAt,
  MayBeJobCandidateNewWorkflowStageTaskAt,
  MayBeJobCandidateViewedAt,
  JobCandidateUser,
  JobCandidateStatus,
  JobCandidateCreatedAt,
  JobCandidateUpdatedAt,
  JobCandidatePersonalPhone,
  JobCandidatePhone,
  JobCandidateCompanyPhone,
  JobCandidateCompany,
  JobCandidateLinkedinUrl,
  JobCandidateCandidateShares,
  JobCandidateWorkflowStageTaskId,
  JobCandidateCities
} from '../../jobCandidates/jobCandidatesTypes';

import { JobId } from '../../jobs/jobsTypes';

import {
  UserId,
  UserNanoId,
  UserName,
  UserImage
} from '../../users/usersTypes';

import {
  WorkflowStageTaskArrangement,
  WorkflowStageTaskDueDate,
  WorkflowStageTaskId,
  WorkflowStageTaskName,
  WorkflowStageTaskNanoId
} from '../../workflowStageTasks/workflowStageTasksTypes';

export type WorkflowStageId = FieldBrand<ItemID, 'WorkflowStageId'>;
export type MayBeWorkflowStageId = MayBe<WorkflowStageId>;
export type WorkflowStageUuid = FieldBrand<ItemUUID, 'WorkflowStageUuid'>;
export type MayBeWorkflowStageUuid = MayBe<WorkflowStageUuid>;
export type WorkflowStageNanoId = FieldBrand<ItemNanoId, 'WorkflowStageNanoId'>;
export type MayBeWorkflowStageNanoId = MayBe<WorkflowStageNanoId>;
export type WorkflowStageName = FieldBrand<string, 'WorkflowStageName'>;
export type WorkflowStageArrangement = FieldBrand<
  number,
  'WorkflowStageArrangement'
>;
export type WorkflowStageColor = FieldBrand<string, 'WorkflowStageColor'>;
export type WorkflowStageEditable = FieldBrand<
  boolean,
  'WorkflowStageEditable'
>;
export type WorkflowStageMovable = FieldBrand<boolean, 'WorkflowStageMovable'>;
export type WorkflowStageRemovable = FieldBrand<
  boolean,
  'WorkflowStageRemovable'
>;
export type WorkflowStageJobId = FieldBrand<JobId, 'WorkflowStageJobId'>;
export type WorkflowStageUserId = FieldBrand<UserId, 'WorkflowStageUserId'>;
export type WorkflowStageUser = FieldBrand<
  {
    id: UserId;
    nanoId: UserNanoId;
    name: UserName;
    image: UserImage;
  },
  'WorkflowStageUser'
>;
export type WorkflowStageWorkflowStageTasks = FieldBrand<
  {
    id: WorkflowStageTaskId;
    nanoId: WorkflowStageTaskNanoId;
    name: WorkflowStageTaskName;
    dueDate: WorkflowStageTaskDueDate;
    arrangement: WorkflowStageTaskArrangement;
  }[],
  'WorkflowStageWorkflowStageTasks'
>;
export type WorkflowStageJobCandidates = FieldBrand<
  {
    id: JobCandidateId;
    nanoId: JobCandidateNanoId;
    name: JobCandidateName;
    image: JobCandidateImage;
    jobTitle: JobCandidateJobTitle;
    user: JobCandidateUser;
    workflowStageTaskId: JobCandidateWorkflowStageTaskId;
    workflowStageTask: JobCandidateWorkflowStageTask;
    city: JobCandidateCity;
    cities: JobCandidateCities;
    state: JobCandidateState;
    arrangement: JobCandidateArrangement;
    searchType: JobCandidateSearchType;
    searchTypeLabel: JobCandidateSearchTypeLabel;
    disqualifiedAt: MayBeJobCandidateDisqualifiedAt;
    disqualificationReason: JobCandidateDisqualificationReason;
    workflowStageTaskScheduledAt: MayBeJobCandidateWorkflowStageTaskScheduledAt;
    newWorkflowStageTaskAt: MayBeJobCandidateNewWorkflowStageTaskAt;
    viewedAt: MayBeJobCandidateViewedAt;
    workflowStageTaskApplicationType: JobCandidateWorkflowStageTaskApplicationType;
    dueDate: MayBeJobCandidateDueDate;
    status: JobCandidateStatus;
    createdAt: JobCandidateCreatedAt;
    updatedAt: JobCandidateUpdatedAt;
    personalPhone: JobCandidatePersonalPhone;
    phone: JobCandidatePhone;
    companyPhone: JobCandidateCompanyPhone;
    company: JobCandidateCompany;
    linkedinUrl: JobCandidateLinkedinUrl;
    candidateShares: JobCandidateCandidateShares;
  }[],
  'WorkflowStageJobCandidates'
>;
export type WorkflowStageAllJobCandidates = WorkflowStageJobCandidates;
export type WorkflowStageCreatedAt = FieldBrand<
  ItemCreatedAt,
  'WorkflowStageCreatedAt'
>;

export const workflowStageColors = [
  '#0580A4',
  '#257dc9',
  '#507d7b',
  '#6871e4',
  '#eda403',
  '#ab55b2',
  '#0aad91',
  '#ba3724',
  '#128e6b',
  '#f1ca4d',
  '#56cdf2'
];

export const enum WorkflowStageFields {
  ID = 'id',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  NAME = 'name',
  ARRANGEMENT = 'arrangement',
  COLOR = 'color',
  EDITABLE = 'editable',
  MOVABLE = 'movable',
  REMOVABLE = 'removable',
  WORKFLOW_STAGE_TASKS = 'workflowStageTasks',
  JOB_CANDIDATES = 'jobCandidates',
  ALL_JOB_CANDIDATES = 'allJobCandidates',
  JOB_ID = 'jobId',
  USER_ID = 'userId',
  USER = 'user',
  CREATED_AT = 'createdAt'
}

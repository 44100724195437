import {
  FetchEmploymentsCacheKey,
  FetchEmploymentCacheKey,
  UpdateEmploymentNanoId,
  UpdateEmploymentErrorMessage,
  UpdateEmploymentIsLoading
} from '../../employmentsTypes';

import {
  EmploymentsBffRequests,
  UpdateEmploymentRequestData,
  UpdateEmploymentErrorResponse
} from '../../EmploymentsBffRequests';

import { useUpdateItem } from '../../../common/hooks/useUpdateItem';

interface UpdateEmploymentOptions {
  employmentNanoId: UpdateEmploymentNanoId;
  cacheKeys?: (FetchEmploymentsCacheKey | FetchEmploymentCacheKey)[];
}

function useUpdateEmployment({
  employmentNanoId,
  cacheKeys = []
}: UpdateEmploymentOptions) {
  const {
    updateItemData,
    updateItemError,
    updateItemErrorMessage,
    updateItemIsLoading,
    updateItem,
    updateItemReset
  } = useUpdateItem<UpdateEmploymentRequestData, UpdateEmploymentErrorResponse>(
    {
      mutationFn: (queryInput) =>
        EmploymentsBffRequests.updateEmployment(employmentNanoId, queryInput),
      cacheKeys
    }
  );

  return {
    updateEmploymentData: updateItemData,
    updateEmploymentError: updateItemError,
    updateEmploymentErrorMessage:
      updateItemErrorMessage as UpdateEmploymentErrorMessage,
    updateEmploymentIsLoading: updateItemIsLoading as UpdateEmploymentIsLoading,
    updateEmployment: updateItem,
    updateEmploymentReset: updateItemReset
  };
}

export default useUpdateEmployment;

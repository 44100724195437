import {
  FetchCertificationsCacheKey,
  FetchCertificationCacheKey,
  DeleteCertificationErrorMessage,
  DeleteCertificationIsLoading,
  DeleteCertificationNanoId
} from '../../certificationsTypes';

import { useDeleteItem } from '../../../common/hooks/useDeleteItem';

import {
  CertificationsBffRequests,
  DeleteCertificationErrorResponse
} from '../../CertificationsBffRequests';

interface DeleteCertificationOptions {
  certificationNanoId: DeleteCertificationNanoId;
  cacheKeys?: (FetchCertificationsCacheKey | FetchCertificationCacheKey)[];
}

function useDeleteCertification({
  certificationNanoId,
  cacheKeys = []
}: DeleteCertificationOptions) {
  const {
    deleteItemData,
    deleteItemError,
    deleteItemErrorMessage,
    deleteItemIsLoading,
    deleteItem,
    deleteItemReset
  } = useDeleteItem<unknown, DeleteCertificationErrorResponse>({
    mutationFn: () =>
      CertificationsBffRequests.deleteCertification(certificationNanoId),
    cacheKeys
  });

  return {
    deleteCertificationData: deleteItemData,
    deleteCertificationError: deleteItemError,
    deleteCertificationErrorMessage:
      deleteItemErrorMessage as DeleteCertificationErrorMessage,
    deleteCertificationIsLoading:
      deleteItemIsLoading as DeleteCertificationIsLoading,
    deleteCertification: deleteItem,
    deleteCertificationReset: deleteItemReset
  };
}

export default useDeleteCertification;

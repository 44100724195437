import { Flex, Skeleton, Stack, StackDivider } from '@chakra-ui/react';
import { JobResumeRecruiterNanoId } from '../../../jobResumeRecruiters/jobResumeRecruitersTypes';
import {
  fetchJobResumeRecruiterQuery,
  FetchJobResumeRecruiterResponse
} from '../../../jobResumeRecruiters/queries/fetchJobResumeRecruiter.query';

import { useCurrentUser } from '../../../../auth/hooks/useAuth';
import { useFetchJobResumeRecruiterByNanoId } from '../../../jobResumeRecruiters/hooks/useFetchJobResumeRecruiterByNanoId';

import { JobResumeRecruitersCache } from '../../../jobResumeRecruiters/JobResumeRecruitersCache';

import { HeaderAccountDropdownMenu } from '../../../../layouts/AppLayout/components/HeaderAccountDropdownMenu';

import { AlertMessage } from '../../../../helpers/AlertMessage';
import { NotificationsDropdown } from '../../../../helpers/NotificationsDropdown';

interface CareerSiteAccountDropdownProps {
  jobResumeRecruiterNanoId: JobResumeRecruiterNanoId;
  onSignOut: () => void;
}

function CareerSiteAccountDropdown({
  jobResumeRecruiterNanoId,
  onSignOut
}: CareerSiteAccountDropdownProps) {
  const currentUser = useCurrentUser();

  const { jobResumeRecruiterIsFetched, jobResumeRecruiterErrorMessage } =
    useFetchJobResumeRecruiterByNanoId<FetchJobResumeRecruiterResponse>({
      jobResumeRecruiterNanoId,
      query: fetchJobResumeRecruiterQuery,
      cacheKey: JobResumeRecruitersCache.showCacheKey()
    });

  return (
    <>
      <AlertMessage message={jobResumeRecruiterErrorMessage} />

      <Skeleton isLoaded={jobResumeRecruiterIsFetched}>
        <Stack
          ml="auto"
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={{ base: 4, md: 6 }}
          divider={<StackDivider borderColor="gray.200" />}
        >
          <Flex>
            <HeaderAccountDropdownMenu
              showProfile={false}
              currentUser={currentUser}
              onSignOut={onSignOut}
            />
          </Flex>

          <NotificationsDropdown />
        </Stack>
      </Skeleton>
    </>
  );
}

export default CareerSiteAccountDropdown;

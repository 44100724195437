import { Box, Flex, Stack } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { ReactNode, useEffect, useRef } from 'react';
import { PageHead } from '../../../../../../helpers/PageHead';
import { JobCandidateNanoId } from '../../../../../jobCandidates/jobCandidatesTypes';
import { WorkflowStagesCache } from '../../../../../workflowStages/WorkflowStagesCache';
import { usePaginatedWorkflowStages } from '../../../../../workflowStages/hooks/usePaginatedWorkflowStages';
import {
  FetchWorkflowStagesResponse,
  fetchWorkflowStagesQuery
} from '../../../../../workflowStages/queries/fetchWorkflowStages.query';
import {
  FetchWorkflowStagesFilters,
  FetchWorkflowStagesSort,
  WorkflowStageFields
} from '../../../../../workflowStages/workflowStagesTypes';
import { JobNanoId } from '../../../../jobsTypes';
import { useViewJobCandidateDetailsContext } from '../../contexts/ViewJobCandidateDetailsContext';
import {
  ViewJobCandidateDetailsCandidate,
  ViewJobCandidateDetailsJobType
} from '../../pages/ViewJobCandidateDetailsProfilePage/ViewJobCandidateDetailsProfilePage.types';
import ViewJobCandidateDetailsPageNav from '../ViewJobCandidateDetailsPageNav/ViewJobCandidateDetailsPageNav';
import { ViewJobCandidateDetailsProfileOverview } from '../ViewJobCandidateDetailsProfileOverview';
import { DepartmentNanoId } from '../../../../../departments/departmentsTypes';
import { IsDisabled } from '../../../../../../types';

interface ViewJobCandidateDetailsPageTabsProps {
  prospect?: boolean;
  isBasePath?: boolean;
  children?: ReactNode;
  jobNanoId?: JobNanoId;
  isDisabled?: IsDisabled;
  isJobDetailsPage?: boolean;
  isHiringPortalPath: boolean;
  departmentNanoId?: DepartmentNanoId;
  jobCandidateNanoId?: JobCandidateNanoId;
  jobCandidate: ViewJobCandidateDetailsCandidate;
  job: ViewJobCandidateDetailsJobType;
}

function ViewJobCandidateDetailsPageTabs({
  job,
  children,
  prospect,
  isDisabled,
  isBasePath,
  jobCandidate,
  departmentNanoId,
  isJobDetailsPage,
  isHiringPortalPath
}: ViewJobCandidateDetailsPageTabsProps) {
  const router = useRouter();
  const mainContentScrollRef = useRef<HTMLDivElement>(null);

  const { isProfileOverviewOpen, toggleProfileOverview } =
    useViewJobCandidateDetailsContext();

  const { workflowStages } =
    usePaginatedWorkflowStages<FetchWorkflowStagesResponse>({
      query: fetchWorkflowStagesQuery,
      cacheKey: WorkflowStagesCache.jobIndexCacheKey(job.nanoId),
      initialFilters: {
        [WorkflowStageFields.JOB_ID]: { operator: 'eq', value: job.id }
      } as unknown as FetchWorkflowStagesFilters,
      initialSort: {
        [WorkflowStageFields.ARRANGEMENT]: { ascending: true }
      } as unknown as FetchWorkflowStagesSort
    });

  useEffect(() => {
    !isBasePath &&
      mainContentScrollRef.current &&
      mainContentScrollRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
  }, [isBasePath, router.pathname]);

  const isActivitiesPage = router.pathname.includes('activities');

  return (
    <>
      <PageHead i18nTitle={`${jobCandidate.name}'s profile`} />

      {isJobDetailsPage ? null : (
        <ViewJobCandidateDetailsPageNav
          jobCandidateNanoId={jobCandidate.nanoId}
          isHiringPortalPath={isHiringPortalPath}
          departmentNanoId={departmentNanoId}
          jobNanoId={job.nanoId}
          isDisabled={isDisabled}
          isBasePath={isBasePath}
          prospect={prospect}
        />
      )}

      <Flex gap={4} h="100%" pos="relative">
        {isJobDetailsPage ? null : (
          <ViewJobCandidateDetailsProfileOverview
            isHiringPortalPage={isHiringPortalPath}
            isActivitiesPage={isActivitiesPage}
            departmentNanoId={departmentNanoId}
            onToggle={toggleProfileOverview}
            workflowStages={workflowStages}
            isOpen={isProfileOverviewOpen}
            jobCandidate={jobCandidate}
            isDisabled={isDisabled}
            prospect={prospect}
            job={job}
          />
        )}

        <Stack flex={1} spacing={0} pos="relative">
          <Box
            h={6}
            zIndex={99}
            opacity={0}
            pos="absolute"
            pointerEvents="none"
            ref={mainContentScrollRef}
            top="-120px"
            w="full"
          />

          {children}
        </Stack>
      </Flex>
    </>
  );
}

export default ViewJobCandidateDetailsPageTabs;

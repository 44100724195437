import {
  EmploymentTypes,
  EmploymentEmploymentType
} from '../../../../../employmentsTypes';

const jobTitleIdRules = {
  required: 'Job title is required'
};

const employmentTypeRules = {
  required: 'Employment type is required',
  validate: (value: EmploymentEmploymentType) =>
    value === EmploymentTypes.NONE ? 'Employment type is required' : true
};

const companyNameRules = {
  required: 'Company is required'
};

const startDateRules = {
  required: 'Start date is required'
};

const endDateRules = {
  required: 'End date is required'
};

const jobSatisfactionRules = {
  required: 'Job satisfaction is required'
};

function useAddEditEmploymentFormValidationRules() {
  return {
    jobTitleIdRules,
    jobSatisfactionRules,
    employmentTypeRules,
    companyNameRules,
    startDateRules,
    endDateRules
  };
}

export default useAddEditEmploymentFormValidationRules;

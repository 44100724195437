import { Flex, HStack, Stack, useDisclosure } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { Heading } from '../../../../../../helpers/Heading';
import { ViewJobCandidateDetailsCandidateUpdatedLabel } from '../ViewJobCandidateDetailsCandidateUpdatedLabel';

interface ViewJobCandidateDetailsPageTabSectionProps {
  title: string;
  children: ReactNode;
  updatedDate?: string;
  headerRightElement?: ReactNode;
}

function ViewJobCandidateDetailsPageTabSection({
  title,
  children,
  updatedDate,
  headerRightElement
}: ViewJobCandidateDetailsPageTabSectionProps) {
  const {
    isOpen: isHovered,
    onOpen: onMouseHover,
    onClose: onMouseLeave
  } = useDisclosure();

  return (
    <Stack
      p={4}
      gap={4}
      flex={1}
      bg="white"
      borderRadius="base"
      onMouseOver={onMouseHover}
      onMouseLeave={onMouseLeave}
    >
      <HStack>
        <Heading flex={1} p={0} level="h4" fontSize="md" margin={0}>
          {title}
        </Heading>

        {headerRightElement && (
          <Flex alignItems="center">{headerRightElement}</Flex>
        )}

        {updatedDate && (
          <ViewJobCandidateDetailsCandidateUpdatedLabel
            date={updatedDate}
            isHovered={isHovered}
          />
        )}
      </HStack>

      <Flex flexDirection="column">{children}</Flex>
    </Stack>
  );
}

export default ViewJobCandidateDetailsPageTabSection;

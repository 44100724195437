import { FetchEmploymentsCacheKey } from '../../employmentsTypes';

import { useCreateItem } from '../../../common/hooks/useCreateItem';

import {
  EmploymentsBffRequests,
  CreateEmploymentRequestData,
  CreateEmploymentErrorResponse
} from '../../EmploymentsBffRequests';

interface CreateEmploymentOptions {
  cacheKeys?: FetchEmploymentsCacheKey[];
}

function useCreateEmployment({ cacheKeys = [] }: CreateEmploymentOptions) {
  const {
    createItemData,
    createItemError,
    createItemErrorMessage,
    createItemIsLoading,
    createItem,
    createItemReset
  } = useCreateItem<CreateEmploymentRequestData, CreateEmploymentErrorResponse>(
    {
      mutationFn: (queryInput) =>
        EmploymentsBffRequests.createEmployment(queryInput),
      cacheKeys
    }
  );

  return {
    createEmploymentData: createItemData,
    createEmploymentError: createItemError,
    createEmploymentErrorMessage: createItemErrorMessage,
    createEmploymentIsLoading: createItemIsLoading,
    createEmployment: createItem,
    createEmploymentReset: createItemReset
  };
}

export default useCreateEmployment;

import uniq from 'lodash/uniq';

import {
  ReactHookFormFieldError,
  useReactHookForm
} from '../../../../../../../common/hooks/base/useReactHookForm';

import {
  EmploymentTypes,
  EmploymentFields,
  EmploymentCityId,
  EmploymentCompanyId,
  EmploymentCurrently,
  EmploymentDescription,
  EmploymentEmploymentType,
  EmploymentStartDate,
  EmploymentEndDate,
  EmploymentSpecializationIds,
  EmploymentIndustryIds,
  EmploymentJobTitleId,
  MayBeEmploymentSupervisorsName,
  MayBeEmploymentSupervisorsTitle,
  MayBeEmploymentReasonForLeaving,
  EmploymentJobFunctionId,
  EmploymentJobFunctionIds,
  EmploymentJobSatisfaction,
  MayBeEmploymentMoreAboutLeaving
} from '../../../../../employmentsTypes';

import { useAddEditEmploymentFormValidationRules } from '../useAddEditEmploymentFormValidationRules';

import { DateUtils } from '../../../../../../../utils/DateUtils';
import {
  AddEditEmploymentFormData,
  OnAddEditEmploymentForm
} from '../../AddEditEmploymentForm.types';

const defaultAddEditEmploymentFormValues: AddEditEmploymentFormData = {
  jobTitleId: '' as EmploymentJobTitleId,
  jobFunctionIds: [] as EmploymentJobFunctionIds,
  employmentType: EmploymentTypes.NONE as EmploymentEmploymentType,
  description: '' as EmploymentDescription,
  startDate: null,
  endDate: null,
  currently: false as EmploymentCurrently,
  companyId: null,
  cityId: null,
  specializationIds: [] as EmploymentSpecializationIds,
  industryIds: [] as EmploymentIndustryIds,
  supervisorsName: '' as MayBeEmploymentSupervisorsName,
  supervisorsTitle: '' as MayBeEmploymentSupervisorsTitle,
  reasonForLeaving: '' as MayBeEmploymentReasonForLeaving,
  moreAboutLeaving: '' as MayBeEmploymentMoreAboutLeaving,
  jobSatisfaction: '' as EmploymentJobSatisfaction
};

interface AddEditEmploymentFormOptions {
  defaultValues?: Partial<AddEditEmploymentFormData>;
  onAddEditEmploymentForm: OnAddEditEmploymentForm;
}

function useAddEditEmploymentForm({
  defaultValues = {},
  onAddEditEmploymentForm
}: AddEditEmploymentFormOptions) {
  const {
    control,
    watch,
    errors,
    isLoading,
    isValid,
    isSubmitted,
    isSubmitSuccessful,
    errorMessage,
    register,
    resetForm,
    handleSubmitReactHookForm,
    setValue
  } = useReactHookForm<AddEditEmploymentFormData>({
    defaultValues: {
      ...defaultAddEditEmploymentFormValues,
      ...defaultValues
    }
  });

  const { jobTitleIdRules, startDateRules, jobSatisfactionRules } =
    useAddEditEmploymentFormValidationRules();

  return {
    control,
    watch,
    validationErrors: {
      jobTitleIdValidationError: (errors.jobTitleId as ReactHookFormFieldError)
        ?.message,
      employmentTypeValidationError: (
        errors.employmentType as ReactHookFormFieldError
      )?.message,
      descriptionValidationError: (
        errors.description as ReactHookFormFieldError
      )?.message,
      startDateValidationError: (errors.startDate as ReactHookFormFieldError)
        ?.message,
      endDateValidationError: (errors.endDate as ReactHookFormFieldError)
        ?.message,
      currentlyValidationError: (errors.currently as ReactHookFormFieldError)
        ?.message,
      companyIdValidationError: (errors.companyId as ReactHookFormFieldError)
        ?.message,
      cityIdValidationError: (errors.cityId as ReactHookFormFieldError)
        ?.message,
      jobFunctionIdsValidationError: (
        errors.jobFunctionIds as ReactHookFormFieldError
      )?.message,
      reasonForLeavingValidationError: (
        errors.reasonForLeaving as ReactHookFormFieldError
      )?.message,
      moreAboutLeavingValidationError: (
        errors.moreAboutLeaving as ReactHookFormFieldError
      )?.message,
      supervisorsNameValidationError: (
        errors.supervisorsName as ReactHookFormFieldError
      )?.message,
      supervisorsTitleValidationError: (
        errors.supervisorsTitle as ReactHookFormFieldError
      )?.message,
      industryIdsValidationError: (
        errors.industryIds as ReactHookFormFieldError
      )?.message,
      specializationIdsValidationError: (
        errors.specializationIds as ReactHookFormFieldError
      )?.message,
      jobSatisfactionValidationError: (
        errors.jobSatisfaction as ReactHookFormFieldError
      )?.message
    },
    addEditEmploymentFormIsLoading: isLoading,
    addEditEmploymentFormIsValid: isValid,
    addEditEmploymentFormIsSubmitted: isSubmitted,
    addEditEmploymentFormIsSubmitSuccessful: isSubmitSuccessful,
    addEditEmploymentFormErrorMessage: errorMessage,
    resetAddEditEmploymentForm: resetForm,
    setAddEditEmploymentFormValue: setValue,
    registerFields: {
      registerJobtitleId: register(
        EmploymentFields.JOB_TITLE_ID,
        jobTitleIdRules
      ),
      registerEmploymentType: register(EmploymentFields.EMPLOYMENT_TYPE),
      registerDescription: register(EmploymentFields.DESCRIPTION),
      registerStartDate: register(EmploymentFields.START_DATE, startDateRules),
      registerEndDate: register(EmploymentFields.END_DATE), // endDateRules
      registerCurrently: register(EmploymentFields.CURRENTLY),
      registerJobFunctionIds: register(EmploymentFields.JOB_FUNCTION_IDS),
      registerCompanyId: register(EmploymentFields.COMPANY_ID),
      registerCityId: register(EmploymentFields.CITY_ID),
      registerSupervisorsName: register(EmploymentFields.SUPERVISORS_NAME),
      registerSupervisorsTitle: register(EmploymentFields.SUPERVISORS_TITLE),
      registerReasonForLeaving: register(EmploymentFields.REASON_FOR_LEAVING),
      registerMoreAboutLeaving: register(EmploymentFields.MORE_ABOUT_LEAVING),
      registerJobSatisfaction: register(
        EmploymentFields.JOB_SATISFACTION,
        jobSatisfactionRules
      )
    },
    handleAddEditEmploymentForm: handleSubmitReactHookForm({
      onSubmit: (data) =>
        onAddEditEmploymentForm?.({
          jobTitleId: data.jobTitleId as EmploymentJobTitleId,
          employmentType: data.employmentType as EmploymentEmploymentType,
          description: data.description as EmploymentDescription,
          startDate: DateUtils.formatDate(
            data.startDate
          ) as EmploymentStartDate,
          endDate: DateUtils.formatDate(data.endDate) as EmploymentEndDate,
          currently: data.currently as EmploymentCurrently,
          companyId: data.companyId as EmploymentCompanyId,
          cityId: data.cityId as EmploymentCityId,
          jobFunctionIds: uniq(data.jobFunctionIds) as EmploymentJobFunctionId,
          specializationIds: uniq(
            data.specializationIds
          ) as EmploymentSpecializationIds,
          industryIds: uniq(data.industryIds) as EmploymentIndustryIds,
          supervisorsName:
            data.supervisorsName as MayBeEmploymentSupervisorsName,
          supervisorsTitle:
            data.supervisorsTitle as MayBeEmploymentSupervisorsTitle,
          reasonForLeaving:
            data.reasonForLeaving as MayBeEmploymentReasonForLeaving,
          moreAboutLeaving:
            data.moreAboutLeaving as MayBeEmploymentMoreAboutLeaving,
          jobSatisfaction: data.jobSatisfaction as EmploymentJobSatisfaction
        })
    })
  };
}

export default useAddEditEmploymentForm;

import React, { ReactNode } from 'react';
import { TalentPoolFilterPopupSectionItem } from '../TalentPoolFilterPopupSectionItem';
import { Flex, Stack } from '@chakra-ui/react';
import { InputField } from '../../../../../../../helpers/forms/formFields/InputField';
import { CheckboxFieldProps } from '../../../../../../../helpers/forms/formFields/CheckboxField/CheckboxField';

interface TalentPoolFilterPopupSectionProps extends CheckboxFieldProps {
  title?: string;
  children?: ReactNode;
  isSearchable?: boolean;
  searchInputChange?: (value: string) => void;
}

export default function TalentPoolFilterPopupSection({
  title,
  children,
  isChecked,
  isSearchable,
  isIndeterminate,
  searchInputChange,
  ...props
}: TalentPoolFilterPopupSectionProps) {
  return (
    <Flex
      p={3}
      gap={2}
      flexDir="column"
      border="1px solid"
      borderColor="gray.200"
      borderRadius="lg"
    >
      {title && (
        <TalentPoolFilterPopupSectionItem
          fontSize="sm"
          label={title}
          fontWeight="medium"
          isChecked={isChecked}
          isIndeterminate={isIndeterminate}
          {...props}
        />
      )}

      {isSearchable && (
        <Flex>
          <InputField
            px={1}
            py={0.5}
            size="small"
            fontSize="sm"
            placeholder="Search"
            borderRadius="none"
            borderWidth={0}
            borderBottomWidth="1px"
            onChange={(e) => searchInputChange?.(e.target.value)}
          />
        </Flex>
      )}

      <Flex flexDir="column" overflowY="auto" maxH={52}>
        <Stack spacing={1.5}>{children}</Stack>
      </Flex>
    </Flex>
  );
}

import {
  JobScorecardQuestionItemBffID,
  FetchJobScorecardQuestionsBffURL,
  FetchJobScorecardQuestionBffURL,
  CreateJobScorecardQuestionBffURL,
  UpdateJobScorecardQuestionBffURL,
  DeleteJobScorecardQuestionBffURL,
  JobScorecardQuestionItemBffUuID
} from './jobScorecardQuestionsTypes';

import { BaseBffRoutes } from '../BaseBffRoutes';

export class JobScorecardQuestionsBffRoutes extends BaseBffRoutes {
  protected static route = 'jobScorecardQuestions';

  static fetchJobScorecardQuestionsRoute() {
    return this.fetchItemsRoute<FetchJobScorecardQuestionsBffURL>();
  }

  static fetchJobScorecardQuestionsIndexRoute() {
    return this.fetchItemsIndexRoute<FetchJobScorecardQuestionsBffURL>();
  }

  static fetchJobScorecardQuestionRoute(
    jobScorecardQuestionItemBffID: JobScorecardQuestionItemBffID
  ) {
    return this.fetchItemRoute<FetchJobScorecardQuestionBffURL>(
      jobScorecardQuestionItemBffID
    );
  }

  static fetchJobScorecardQuestionIndexRoute(
    jobScorecardQuestionItemBffUuID: JobScorecardQuestionItemBffUuID
  ) {
    return this.fetchItemIndexRoute<FetchJobScorecardQuestionBffURL>(
      jobScorecardQuestionItemBffUuID
    );
  }

  static createJobScorecardQuestionRoute() {
    return this.createItemRoute<CreateJobScorecardQuestionBffURL>();
  }

  static updateJobScorecardQuestionRoute(
    jobScorecardQuestionItemBffID: JobScorecardQuestionItemBffID
  ) {
    return this.updateItemRoute<UpdateJobScorecardQuestionBffURL>(
      jobScorecardQuestionItemBffID
    );
  }

  static deleteJobScorecardQuestionRoute(
    jobScorecardQuestionItemBffID: JobScorecardQuestionItemBffID
  ) {
    return this.deleteItemRoute<DeleteJobScorecardQuestionBffURL>(
      jobScorecardQuestionItemBffID
    );
  }
}

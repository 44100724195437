import { useCallback, useState } from 'react';
import { Stack, SystemProps } from '@chakra-ui/react';

import { FetchEducationsCacheKey } from '../../../educationsTypes';

import { useDeleteEducation } from '../../../../educations/hooks/useDeleteEducation';

import { EditDeleteEducationItem } from '../../../helpers/EditDeleteEducationItem';

import { AddEditEducationForm } from '../../forms/AddEditEducationForm';

import { AlertMessage } from '../../../../../helpers/AlertMessage';

import { EditEducationListItemEducation } from './EditEducationListItem.types';

interface EditEducationListItemProps {
  education: EditEducationListItemEducation;
  educationsCacheKey: FetchEducationsCacheKey;
  stackSpacing?: SystemProps['margin'];
  editable?: boolean;
  deletable?: boolean;
}

function EditEducationListItem({
  education,
  educationsCacheKey,
  stackSpacing = '6',
  editable = true,
  deletable = true
}: EditEducationListItemProps) {
  const {
    deleteEducation,
    deleteEducationErrorMessage,
    deleteEducationIsLoading
  } = useDeleteEducation({
    educationNanoId: education.nanoId,
    cacheKeys: [educationsCacheKey]
  });
  const [editEducationFormIsOpen, setEditEducationFormIsOpen] =
    useState<boolean>(false);

  const handleEditEducationFormOpen = useCallback(
    () => setEditEducationFormIsOpen(true),
    [setEditEducationFormIsOpen]
  );

  const handleEditEducationFormClose = useCallback(
    () => setEditEducationFormIsOpen(false),
    [setEditEducationFormIsOpen]
  );

  const handleDeleteEducation = useCallback<() => void>(
    () => deleteEducation({}),
    [deleteEducation]
  );

  return (
    <Stack spacing={stackSpacing}>
      <AlertMessage message={deleteEducationErrorMessage} />
      <EditDeleteEducationItem
        education={education}
        deleteEducationIsLoading={deleteEducationIsLoading}
        editable={editable}
        deletable={deletable}
        onDeleteEducation={handleDeleteEducation}
        onEditEducation={handleEditEducationFormOpen}
      />
      {editEducationFormIsOpen && (
        <AddEditEducationForm
          type="edit"
          jobCandidateId={education.jobCandidateId}
          education={education}
          educationsCacheKey={educationsCacheKey}
          isOpen={editEducationFormIsOpen}
          onClose={handleEditEducationFormClose}
          onSave={handleEditEducationFormClose}
        />
      )}
    </Stack>
  );
}

export default EditEducationListItem;

import React from 'react';
import { TalentPoolFilterPopupSection } from '../../TalentPoolFilterPopupSection';
import { TalentPoolFilterPopupSectionItem } from '../../TalentPoolFilterPopupSectionItem';

export default function TalentPoolFilterPopupRecruitersSection() {
  return (
    <TalentPoolFilterPopupSection title="Recruiters" isSearchable>
      <TalentPoolFilterPopupSectionItem label="Ken brown" />
      <TalentPoolFilterPopupSectionItem label="Kaely" />
      <TalentPoolFilterPopupSectionItem label="Sara Lopez" />
      <TalentPoolFilterPopupSectionItem label="Will Sato" />
      <TalentPoolFilterPopupSectionItem label="Kane Johnson" />
      <TalentPoolFilterPopupSectionItem label="Chapel Hill" />
    </TalentPoolFilterPopupSection>
  );
}

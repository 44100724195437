import React from 'react';
import { TalentPoolFilterPopupSection } from '../../TalentPoolFilterPopupSection';
import { TalentPoolFilterPopupSectionItem } from '../../TalentPoolFilterPopupSectionItem';

export default function TalentPoolFilterPopupJobStatusSection() {
  return (
    <TalentPoolFilterPopupSection title="Job Status" isSearchable>
      <TalentPoolFilterPopupSectionItem label="External" />
      <TalentPoolFilterPopupSectionItem label="Internal" />
      <TalentPoolFilterPopupSectionItem label="Unlisted" />
      <TalentPoolFilterPopupSectionItem label="Confidential" />
    </TalentPoolFilterPopupSection>
  );
}

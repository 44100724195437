import { Box, Flex, Stack } from '@chakra-ui/react';
import filter from 'lodash/filter';
import size from 'lodash/size';
import { Fragment } from 'react';
import { Text } from '../../../../../../../../helpers/Text';
import { DateUtils } from '../../../../../../../../utils/DateUtils';
import { SkillBadgeOnly } from '../../../../../../../skillBadges/helpers/SkillBadgeOnly';
import { SkillKinds } from '../../../../../../../skills/skillsTypes';
import { EditJobCandidateSkillsForm } from '../../../../../../components/modals/EditJobCandidateModal/tabs/EditJobCandidateModalProfileTab/components/forms/EditJobCandidateSkillsForm';
import { ViewJobCandidateDetailsPageTabSection } from '../../../../components/ViewJobCandidateDetailsPageTabSection';
import { ViewJobCandidateDetailsCandidate } from '../../ViewJobCandidateDetailsProfilePage.types';

interface ViewCandidateDetailsSkillsProps {
  isProspect: boolean;
  jobCandidate: ViewJobCandidateDetailsCandidate;
  isEditing?: boolean;
  showComputerSkills?: boolean;
  showIndustryKnowledge?: boolean;
}

function ViewCandidateDetailsSkills({
  isEditing,
  isProspect,
  jobCandidate,
  showComputerSkills = true,
  showIndustryKnowledge = true
}: ViewCandidateDetailsSkillsProps) {
  const { skillBadges } = jobCandidate;

  const computerSkills = filter(
    skillBadges,
    (skillBadge) => skillBadge.skill?.kind === SkillKinds.COMPUTER
  );

  const industrySkills = filter(
    skillBadges,
    (skillBadge) => skillBadge.skill?.kind === SkillKinds.INDUSTRY
  );

  return (
    <>
      {(size(computerSkills) > 0 || size(industrySkills) > 0) && (
        <ViewJobCandidateDetailsPageTabSection
          title="Skills and Knowledge"
          updatedDate={DateUtils.formatDate(jobCandidate.updatedAt) || ''}
        >
          {isEditing ? (
            <EditJobCandidateSkillsForm
              jobCandidate={jobCandidate}
              spacing={4}
            />
          ) : (
            <Stack spacing={4}>
              {showComputerSkills && size(computerSkills) > 0 ? (
                <Fragment>
                  <Text textStyle="upperCase1Regular" color="gray.600">
                    computer skills
                  </Text>

                  <Flex gap={3} flexFlow="row wrap">
                    {computerSkills.map((skillBadge) => (
                      <Fragment key={skillBadge.nanoId}>
                        {isProspect ? (
                          <SkillBadgeOnly
                            key={skillBadge.nanoId}
                            name={skillBadge.skill.name}
                            experience={skillBadge.experience}
                            rating={skillBadge.rating}
                            certified={!!skillBadge.skillLicense}
                            requirements={skillBadge.requirements}
                            skillLicense={skillBadge.skillLicense}
                            withoutPortal
                          />
                        ) : (
                          <Box
                            key={skillBadge.nanoId}
                            px={2}
                            py={0}
                            bg="white"
                            border="1px solid"
                            borderColor="gray.300"
                            borderRadius="base"
                          >
                            <Text textStyle="body1Regular" color="gray.600">
                              {skillBadge.skill?.name}
                            </Text>
                          </Box>
                        )}
                      </Fragment>
                    ))}
                  </Flex>
                </Fragment>
              ) : null}

              {showIndustryKnowledge && size(industrySkills) > 0 ? (
                <Fragment>
                  <Text textStyle="upperCase1Regular" color="gray.600">
                    industry knowledge
                  </Text>
                  <Flex gap={3} flexFlow="row wrap">
                    {industrySkills.map((skillBadge) => (
                      <Fragment key={skillBadge.nanoId}>
                        {isProspect ? (
                          <>
                            {skillBadge.experience === 'none' ? null : (
                              <SkillBadgeOnly
                                key={skillBadge.nanoId}
                                name={skillBadge.skill.name}
                                experience={skillBadge.experience}
                                rating={skillBadge.rating}
                                certified={!!skillBadge.skillLicense}
                                requirements={skillBadge.requirements}
                                skillLicense={skillBadge.skillLicense}
                                withoutPortal
                              />
                            )}
                          </>
                        ) : (
                          <Box
                            key={skillBadge.nanoId}
                            px={2}
                            py={0}
                            bg="white"
                            border="1px solid"
                            borderColor="gray.300"
                            borderRadius="base"
                          >
                            <Text textStyle="body1Regular" color="gray.600">
                              {skillBadge.skill?.name}
                            </Text>
                          </Box>
                        )}
                      </Fragment>
                    ))}
                  </Flex>
                </Fragment>
              ) : null}
            </Stack>
          )}
        </ViewJobCandidateDetailsPageTabSection>
      )}
    </>
  );
}

export default ViewCandidateDetailsSkills;

import {
  Box,
  ColorProps,
  Flex,
  FlexProps,
  FormLabel,
  Tooltip,
  useRadioGroup,
  UseRadioGroupProps
} from '@chakra-ui/react';
import { forwardRef, ReactNode } from 'react';
import { Text } from '../../../../../Text';
import { jobSatisfactionOptions } from '../../JobSatisfactionField.data';
import { JobSatisfactionFieldValues } from '../../JobSatisfactionField.types';
import { JobSatisfactionRadio } from '../JobSatisfactionRadio';

interface JobSatisfactionFieldControlProps
  extends Omit<FlexProps, 'children' | 'defaultValue' | 'onChange'>,
    UseRadioGroupProps {
  options?: {
    label?: ReactNode;
    value: string;
  }[];
  getColor?: (value: string) => ColorProps['color'];
  errorMessage?: string;
  label: string;
}

const JobSatisfactionFieldControl = forwardRef<
  HTMLDivElement,
  JobSatisfactionFieldControlProps
>(
  (
    {
      name,
      value,
      label,
      isNative,
      onChange,
      isDisabled,
      isFocusable,
      defaultValue,
      errorMessage,
      options = defaultOptions(value),
      getColor,
      ...props
    },
    ref
  ) => {
    const { getRootProps, getRadioProps } = useRadioGroup({
      name,
      value,
      onChange,
      isNative,
      isDisabled,
      isFocusable,
      defaultValue
    });

    const group = getRootProps();

    return (
      <Flex flexDir="column" gap={2}>
        {label && <FormLabel>{label}</FormLabel>}

        <Flex {...group} {...props} ref={ref}>
          {options.map((option) => {
            const radio = getRadioProps({ value: option.value });

            const iconColor = getColor ? getColor(option.value) : undefined;

            return (
              <JobSatisfactionRadio
                key={option.value}
                label={option.label}
                _checked={{
                  color: iconColor
                }}
                itemStyles={{
                  color: 'gray.500'
                }}
                {...radio}
              />
            );
          })}
        </Flex>

        {errorMessage && (
          <Text color="red.500" wordBreak="break-all">
            {errorMessage}
          </Text>
        )}
      </Flex>
    );
  }
);

JobSatisfactionFieldControl.displayName = 'JobSatisfactionFieldControl';

export default JobSatisfactionFieldControl;

const defaultOptions = (selectedValue?: string) => {
  return jobSatisfactionOptions.map(({ value, label, color, icon }) => ({
    value,
    label: (
      <Flex
        flexDir="column"
        alignItems="center"
        color={selectedValue === value ? color : ''}
      >
        <Tooltip label={label} placement="top">
          <Box>
            {icon({ color: selectedValue === value ? color : undefined })}
          </Box>
        </Tooltip>

        <Text
          textStyle="body1Medium"
          transitionDuration="slow"
          opacity={
            selectedValue !== value &&
            value !== JobSatisfactionFieldValues.STRESSFUL &&
            value !== JobSatisfactionFieldValues.GREAT
              ? 0
              : 1
          }
        >
          {label}
        </Text>
      </Flex>
    )
  }));
};

import { Box, Flex, Tag, Tooltip } from '@chakra-ui/react';
import { IconButton } from '../../../../../../../../helpers/buttons/IconButton';
import { CalendarIcon } from '../../../../../../../../icons/CalendarIcon';
import { ChevronRightIcon } from '../../../../../../../../icons/ChevronRightIcon';
import { EmailIcon } from '../../../../../../../../icons/EmailIcon';
import { ListItemIcon } from '../../../../../../../../icons/ListItemIcon';
import { PhoneIcon } from '../../../../../../../../icons/PhoneIcon';
import { ThumbDownIcon } from '../../../../../../../../icons/ThumbDownIcon';
import { UserVoiceIcon } from '../../../../../../../../icons/UserVoiceIcon';
import { useViewJobCandidateDetailsStageTasksWidgetContext } from '../../../../contexts/ViewJobCandidateDetailsStageTasksWidgetContext';
import { useViewJobCandidateDetailsSubmissionContext } from '../../../../contexts/ViewJobCandidateDetailsSubmissionContext';

function ViewJobCandidateDetailsTaskTools() {
  const { selectedTask, handleSelectTaskAction, onOpenDisqualificationPopup } =
    useViewJobCandidateDetailsStageTasksWidgetContext();

  const { openCandidateSubmission } =
    useViewJobCandidateDetailsSubmissionContext();

  return (
    <>
      <Flex gap={2.5} flex={1} alignItems="center" justifyContent="flex-start">
        <Tag bg="yellow.500" color="white" size="sm" borderRadius="base" pr={1}>
          <Box as="span">Start task</Box>
          <ChevronRightIcon w={4} h={4} />
        </Tag>

        {selectedTask === 'schedule' && (
          <Tooltip label="Schedule" textTransform="capitalize" hasArrow>
            <IconButton
              h={6}
              w={6}
              minW={0}
              minH={0}
              value="calendar"
              display="flex"
              aria-label=""
              hierarchy="unstyled"
              borderRadius="full"
              alignItems="center"
              justifyContent="center"
              onClick={handleSelectTaskAction}
              icon={<CalendarIcon w={3} h={3} fill="#507D7B" />}
              border="1px solid transparent"
              bg="#EDF2F1"
              _hover={{
                bg: '#DCE5E5',
                borderColor: '#507D7B'
              }}
            />
          </Tooltip>
        )}

        {selectedTask === 'debrief' && (
          <Tooltip label="Call" textTransform="capitalize" hasArrow>
            <IconButton
              h={6}
              w={6}
              minW={0}
              minH={0}
              value="phone"
              display="flex"
              aria-label=""
              hierarchy="unstyled"
              borderRadius="full"
              alignItems="center"
              justifyContent="center"
              onClick={handleSelectTaskAction}
              icon={<PhoneIcon w={2.5} h={2.5} fill="#287CC9" />}
              border="1px solid transparent"
              bg="#E9F1F9"
              _hover={{
                bg: '#D4E5F4',
                borderColor: '#287CC9'
              }}
            />
          </Tooltip>
        )}

        {selectedTask === 'feedback' && (
          <Tooltip label="Email" textTransform="capitalize" hasArrow>
            <IconButton
              h={6}
              w={6}
              minW={0}
              minH={0}
              value="email"
              display="flex"
              aria-label=""
              hierarchy="unstyled"
              borderRadius="full"
              alignItems="center"
              justifyContent="center"
              onClick={handleSelectTaskAction}
              icon={<EmailIcon w={3} h={3} fill="#1DC67D" />}
              border="1px solid transparent"
              bg="#E8F9F2"
              _hover={{
                bg: '#D2F4E5',
                borderColor: '#1DC67D'
              }}
            />
          </Tooltip>
        )}

        {selectedTask === 'submit-candidate' && (
          <Tooltip label="Send Submission" textTransform="capitalize" hasArrow>
            <IconButton
              h={6}
              w={6}
              minW={0}
              minH={0}
              display="flex"
              aria-label=""
              hierarchy="unstyled"
              borderRadius="full"
              alignItems="center"
              justifyContent="center"
              onClick={openCandidateSubmission}
              icon={<UserVoiceIcon w={4} h={4} fill="#1DC67D" />}
              border="1px solid transparent"
              bg="#E8F9F2"
              _hover={{
                bg: '#D2F4E5',
                borderColor: '#1DC67D'
              }}
            />
          </Tooltip>
        )}

        <Tooltip label="Assign" textTransform="capitalize" hasArrow>
          <IconButton
            h={6}
            w={6}
            minW={0}
            minH={0}
            value="tasks"
            display="flex"
            aria-label=""
            hierarchy="unstyled"
            borderRadius="full"
            alignItems="center"
            justifyContent="center"
            icon={<ListItemIcon w={3} h={3} fill="#AB56B2" />}
            border="1px solid transparent"
            bg="#EFF0FC"
            _hover={{
              bg: '#E1E3FA',
              borderColor: '#AB56B2'
            }}
          />
        </Tooltip>

        <Box ml="auto">
          <Tooltip label="Discontinue" textTransform="capitalize" hasArrow>
            <IconButton
              h={6}
              w={6}
              minW={0}
              minH={0}
              display="flex"
              aria-label=""
              hierarchy="unstyled"
              borderRadius="full"
              alignItems="center"
              justifyContent="center"
              onClick={onOpenDisqualificationPopup}
              icon={<ThumbDownIcon w={2.5} h={2.5} color="red.500" />}
              border="1px solid transparent"
              bg="red.100"
              _hover={{
                borderColor: 'red.500'
              }}
            />
          </Tooltip>
        </Box>
      </Flex>
    </>
  );
}

export default ViewJobCandidateDetailsTaskTools;

import {
  Avatar,
  Collapse,
  HStack,
  Input,
  Stack,
  StackDivider,
  useDisclosure
} from '@chakra-ui/react';
import { Button } from '../../../../../../../../helpers/Button';
import { Heading } from '../../../../../../../../helpers/Heading';
import { Text } from '../../../../../../../../helpers/Text';
import { IconButton } from '../../../../../../../../helpers/buttons/IconButton';
import { ChevronDownIcon } from '../../../../../../../../icons/ChevronDownIcon';
import { MessageIcon } from '../../../../../../../../icons/MessageIcon';
import { PictureIcon } from '../../../../../../../../icons/PictureIcon';
import { SmileyIcon } from '../../../../../../../../icons/SmileyIcon';
import { ThumbUpIcon } from '../../../../../../../../icons/ThumbUpIcon';
import { useElementWidth } from '../../../../../../../../common/hooks/utils/useElementWidth';

function ViewCandidateDetailsReactions({
  hasComments
}: {
  hasComments?: boolean;
}) {
  const { isOpen, onToggle } = useDisclosure();

  const [elementRef, width] = useElementWidth<HTMLDivElement>();

  const isSmallSize = width < 400;

  return (
    <Stack spacing={0} ref={elementRef}>
      <HStack color="gray.600" spacing={2}>
        <HStack spacing={1.5} color="gray.500">
          <Avatar w={6} h={6} src="/images/team-mate-2.png" />

          <ChevronDownIcon />
        </HStack>

        <HStack spacing={1}>
          <Button
            py={1.5}
            px={2}
            h="auto"
            minH={0}
            size="medium"
            boxShadow="none"
            color="text.600"
            hierarchy="ghost"
            leftIcon={<ThumbUpIcon color="gray.500" />}
          >
            <Text>Like</Text>
          </Button>

          {!isSmallSize && (
            <HStack
              as={Button}
              spacing={0.5}
              hierarchy="unstyled"
              onClick={onToggle}
            >
              <Avatar w={5} h={5} src="/images/recruiter-avatar-1.jpg" />
              <Avatar w={5} h={5} src="/images/recruiter-avatar-4.jpg" />
              <Avatar w={5} h={5} src="/images/recruiter-avatar-8.jpg" />
              <Text color="primary.500" textStyle="body1Medium">
                +4
              </Text>
            </HStack>
          )}
        </HStack>

        <Button
          px={2}
          py={1.5}
          h="auto"
          minH={0}
          size="medium"
          boxShadow="none"
          color="text.600"
          hierarchy="ghost"
          leftIcon={<MessageIcon fill="gray.500" />}
          onClick={onToggle}
        >
          <Text>Comment</Text>
        </Button>
      </HStack>

      <Collapse in={isOpen}>
        <Stack pt={3} spacing={3}>
          <HStack
            h={10}
            px={4}
            spacing={2}
            alignItems="center"
            border="1px solid"
            borderColor="gray.200"
            borderRadius="full"
          >
            <Input
              p={0}
              h="auto"
              size="md"
              border="none"
              placeholder="Add a comment..."
              borderRadius="none"
            />

            <IconButton
              h={6}
              w={6}
              ml="auto"
              aria-label=""
              boxShadow="none"
              color="gray.600"
              hierarchy="ghost"
              minW={0}
              minH={0}
            >
              <SmileyIcon w={4} h={4} />
            </IconButton>

            <IconButton
              h={6}
              w={6}
              ml="auto"
              aria-label=""
              boxShadow="none"
              color="gray.600"
              hierarchy="ghost"
              minW={0}
              minH={0}
            >
              <PictureIcon w={4} h={4} />
            </IconButton>
          </HStack>

          {hasComments && (
            <Stack>
              <Heading level="h5" fontSize="base" fontWeight="medium">
                Comments
              </Heading>

              <Stack spacing={3} divider={<StackDivider />}>
                <CommentItem />

                <CommentItem />

                <CommentItem />
              </Stack>
            </Stack>
          )}
        </Stack>
      </Collapse>
    </Stack>
  );
}

export default ViewCandidateDetailsReactions;

const CommentItem = () => {
  return (
    <Stack spacing={2}>
      <HStack>
        <HStack flex={1}>
          <Avatar w={6} h={6} src="/images/recruiter-avatar-3.jpg" />

          <Text>Miracle Dokidis</Text>
        </HStack>

        <Text color="gray.600" textStyle="body2Regular">
          Thu, May 16 at 09:00 am
        </Text>
      </HStack>

      <Text color="gray.600">
        This is a nice review from someone who loves this professional and
        thinks he is the best freaking professional in the planet but now he
        badly wants. This is a nice review from someone who loves this
        professional and thinks he is the best
      </Text>
    </Stack>
  );
};

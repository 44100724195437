import {
  Avatar,
  Collapse,
  Flex,
  Grid,
  HStack,
  Stack,
  StackDivider,
  useDisclosure
} from '@chakra-ui/react';
import compact from 'lodash/compact';
import find from 'lodash/find';
import map from 'lodash/map';
import size from 'lodash/size';
import { AlertMessage } from '../../../../../../../../helpers/AlertMessage';
import { Button } from '../../../../../../../../helpers/Button';
import { jobSatisfactionOptions } from '../../../../../../../../helpers/forms/formFields/JobSatisfactionField/JobSatisfactionField.data';
import { Heading } from '../../../../../../../../helpers/Heading';
import { Loader } from '../../../../../../../../helpers/Loader';
import { Rating } from '../../../../../../../../helpers/Rating';
import { Text } from '../../../../../../../../helpers/Text';
import { ExperienceIcon } from '../../../../../../../../icons/Placeholders/ExperienceIcon';
import { DateUtils } from '../../../../../../../../utils/DateUtils';
import { renderLocation } from '../../../../../../../common/utils/renderLocation';
import { EmploymentsListForm } from '../../../../../../../employments/components/forms/EmploymentsListForm';
import { EmploymentsCache } from '../../../../../../../employments/EmploymentsCache';
import {
  EmploymentCurrently,
  EmploymentDescription,
  EmploymentFields,
  EmploymentId,
  EmploymentJobFunctions,
  EmploymentJobSatisfaction,
  EmploymentJobTitle,
  EmploymentName,
  EmploymentNanoId,
  EmploymentSpecializations,
  FetchEmploymentsFilters,
  FetchEmploymentsSort,
  MayBeEmploymentCity,
  MayBeEmploymentCompany,
  MayBeEmploymentEndDate,
  MayBeEmploymentMoreAboutLeaving,
  MayBeEmploymentReasonForLeaving,
  MayBeEmploymentStartDate,
  MayBeEmploymentState
} from '../../../../../../../employments/employmentsTypes';
import { usePaginatedEmployments } from '../../../../../../../employments/hooks/usePaginatedEmployments';
import {
  FetchEmploymentsResponse,
  fetchEmploymentsQuery
} from '../../../../../../../employments/queries/fetchEmployments.query';
import { renderEmploymentJobTitle } from '../../../../../../../employments/utils/renderEmploymentJobTitle';
import { ImagesUrl } from '../../../../../../../images/ImagesUrl';
import { JobCandidateId } from '../../../../../../../jobCandidates/jobCandidatesTypes';
import { JobResumeRecruiterId } from '../../../../../../../jobResumeRecruiters/jobResumeRecruitersTypes';
import { reasonForLeavingOptions } from '../../../../../../jobs.data';
import { ViewJobCandidateDetailsPageTabSection } from '../../../../components/ViewJobCandidateDetailsPageTabSection';
import { ViewCandidateDetailsReactions } from '../ViewCandidateDetailsReactions';
import { useElementWidth } from '../../../../../../../../common/hooks/utils/useElementWidth';

interface ViewCandidateDetailsExperienceJobCandidateProps {
  jobCandidateId: JobCandidateId;
  jobResumeRecruiterId?: never;
}

interface ViewCandidateDetailsExperienceJobResumeRecruiterProps {
  jobCandidateId?: never;
  jobResumeRecruiterId: JobResumeRecruiterId;
}

type ViewCandidateDetailsExperienceProps = {
  isEditing?: boolean;
} & (
  | ViewCandidateDetailsExperienceJobCandidateProps
  | ViewCandidateDetailsExperienceJobResumeRecruiterProps
);

function ViewCandidateDetailsExperience({
  isEditing,
  jobCandidateId,
  jobResumeRecruiterId
}: ViewCandidateDetailsExperienceProps) {
  const employmentsCacheKey =
    (jobCandidateId
      ? EmploymentsCache.jobCandidateListCacheKey(jobCandidateId)
      : null) ||
    (jobResumeRecruiterId
      ? EmploymentsCache.jobResumeRecruiterListCacheKey(jobResumeRecruiterId)
      : null) ||
    EmploymentsCache.indexCacheKey();

  const { employments, employmentsIsFetched, employmentsErrorMessage } =
    usePaginatedEmployments<FetchEmploymentsResponse>({
      query: fetchEmploymentsQuery,
      cacheKey: employmentsCacheKey,
      initialFilters: {
        ...(jobCandidateId
          ? {
              [EmploymentFields.JOB_CANDIDATE_ID]: {
                operator: 'eq',
                value: jobCandidateId
              }
            }
          : {}),
        ...(jobResumeRecruiterId
          ? {
              [EmploymentFields.JOB_RESUME_RECRUITER_ID]: {
                operator: 'eq',
                value: jobResumeRecruiterId
              }
            }
          : {})
      } as unknown as FetchEmploymentsFilters,
      initialSort: {
        [EmploymentFields.START_DATE]: {
          ascending: false
        }
      } as unknown as FetchEmploymentsSort
    });

  return (
    <ViewJobCandidateDetailsPageTabSection title="Experience">
      {isEditing ? (
        <>
          {jobCandidateId ? (
            <EmploymentsListForm
              jobCandidateId={jobCandidateId}
              withoutHeading
              withStackDivider
              alignButtonToRight
              isEditing
            />
          ) : null}

          {jobResumeRecruiterId ? (
            <EmploymentsListForm
              jobResumeRecruiterId={jobResumeRecruiterId}
              withoutHeading
              withStackDivider
              alignButtonToRight
              isEditing
            />
          ) : null}
        </>
      ) : (
        <>
          {employmentsErrorMessage && (
            <AlertMessage message={employmentsErrorMessage} />
          )}

          <Loader loaded={employmentsIsFetched}>
            {size(employments) > 0 && (
              <Stack spacing={1} divider={<StackDivider />}>
                {employments.map((employment) => (
                  <ViewCandidateDetailsExperienceItem
                    key={employment.id}
                    employment={employment}
                    showReferences={false}
                  />
                ))}

                {jobCandidateId && (
                  <ViewCandidateDetailsReactions hasComments />
                )}
              </Stack>
            )}
          </Loader>
        </>
      )}
    </ViewJobCandidateDetailsPageTabSection>
  );
}

export default ViewCandidateDetailsExperience;

interface ViewCandidateDetailsExperienceItemType {
  id: EmploymentId;
  name: EmploymentName;
  description: EmploymentDescription;
  nanoId: EmploymentNanoId;
  jobTitle: EmploymentJobTitle;
  currently: EmploymentCurrently;
  company: MayBeEmploymentCompany;
  state: MayBeEmploymentState;
  city: MayBeEmploymentCity;
  jobFunctions: EmploymentJobFunctions;
  specializations: EmploymentSpecializations;
  reasonForLeaving: MayBeEmploymentReasonForLeaving;
  moreAboutLeaving: MayBeEmploymentMoreAboutLeaving;
  jobSatisfaction: EmploymentJobSatisfaction;
  startDate: MayBeEmploymentStartDate;
  endDate: MayBeEmploymentEndDate;
}

interface ViewCandidateDetailsExperienceItemProps {
  employment: ViewCandidateDetailsExperienceItemType;
  showReferences: boolean;
}

const ViewCandidateDetailsExperienceItem = ({
  employment,
  showReferences
}: ViewCandidateDetailsExperienceItemProps) => {
  const location = renderLocation(employment);

  const { isOpen: isOpenJobDetails, onToggle: toggleJobDetails } =
    useDisclosure();

  const {
    isOpen: isHovered,
    onOpen: onMouseHover,
    onClose: onMouseLeave
  } = useDisclosure();

  const { icon, label, color } =
    jobSatisfactionOptions.find(
      ({ value }) => value === employment.jobSatisfaction
    ) || {};

  const [elementRef, width] = useElementWidth<HTMLDivElement>();

  const isSmallSize = width < 400;

  return (
    <HStack
      py={3}
      as="aside"
      spacing={4}
      ref={elementRef}
      alignItems="flex-start"
      onMouseOver={onMouseHover}
      onMouseLeave={onMouseLeave}
    >
      <Avatar
        icon={<ExperienceIcon />}
        src={ImagesUrl.file(employment.company?.image) || undefined}
      />

      <Stack spacing={0} flex={1}>
        <Flex
          gap={isSmallSize ? 1 : 6}
          flex={1}
          flexDir={isSmallSize ? 'column' : 'row'}
          transitionDuration="slow"
        >
          <Stack spacing={0} flex={1}>
            <Heading
              level="h5"
              fontSize="md"
              fontWeight="medium"
              textTransform="capitalize"
            >
              {renderEmploymentJobTitle(employment)}
            </Heading>

            {(employment.company || location) && (
              <HStack
                flexWrap="wrap"
                spacing={2}
                divider={
                  <Flex border="none" color="gray.500">
                    &bull;
                  </Flex>
                }
              >
                {employment.company && (
                  <Text color="gray.600" textStyle="body1Regular">
                    {employment.company.name}
                  </Text>
                )}

                {location && (
                  <Text color="gray.600" textStyle="body1Regular">
                    {location}
                  </Text>
                )}
              </HStack>
            )}
          </Stack>

          {!isSmallSize && !isOpenJobDetails && (
            <Flex
              opacity={isHovered ? 1 : 0}
              visibility={isHovered ? 'visible' : 'hidden'}
              transitionDuration="slow"
            >
              <Button
                size="medium"
                lineHeight={6}
                hierarchy="link"
                fontWeight="normal"
                textDecoration="underline"
                onClick={toggleJobDetails}
                _hover={{
                  textDecoration: 'none'
                }}
              >
                Show more details
              </Button>
            </Flex>
          )}

          <Text
            color="gray.600"
            textStyle="body2Regular"
            ml={isSmallSize ? 0 : 'auto'}
          >
            {compact([
              DateUtils.formatMonth(employment.startDate),
              employment.currently
                ? 'PRESENT'
                : DateUtils.formatMonth(employment.endDate)
            ]).join(' - ')}
          </Text>
        </Flex>

        <Collapse in={isOpenJobDetails}>
          <Stack spacing={4} pt={2}>
            {size(employment.jobFunctions) && (
              <Grid gridTemplateColumns="150px 1fr">
                <Text textStyle="body1Medium">Job Function:</Text>

                <Text textStyle="body1Regular" color="gray.600">
                  {map(employment.jobFunctions, 'name').join(', ')}
                </Text>
              </Grid>
            )}

            {size(employment.specializations) > 1 && (
              <Grid gridTemplateColumns="150px 1fr">
                <Text textStyle="body1Medium">Specialization:</Text>

                <Text textStyle="body1Regular" color="gray.600">
                  {map(employment.specializations, 'name').join(', ')}
                </Text>
              </Grid>
            )}

            {employment.jobSatisfaction && (
              <Grid gridTemplateColumns="150px 1fr" alignItems="center">
                <Text textStyle="body1Medium">Job Satisfaction:</Text>

                <Flex alignItems="center" gap={1.5} color={color}>
                  <Flex>{icon && icon()}</Flex>

                  <Text textStyle="body1Medium">{label}</Text>
                </Flex>
              </Grid>
            )}

            {employment.reasonForLeaving && (
              <Grid gridTemplateColumns="150px 1fr">
                <Text textStyle="body1Medium">Reason for Leaving:</Text>

                <Text textStyle="body1Regular" color="gray.600">
                  {
                    find(reasonForLeavingOptions, {
                      value: employment.reasonForLeaving
                    })?.label
                  }
                </Text>
              </Grid>
            )}

            {employment.moreAboutLeaving && (
              <Grid gridTemplateColumns="150px 1fr">
                <Text textStyle="body1Medium">More about Leaving:</Text>

                <Text textStyle="body1Regular" color="gray.600">
                  {employment.moreAboutLeaving}
                </Text>
              </Grid>
            )}

            {showReferences ? (
              <Stack spacing={4}>
                <Heading level="h5">References</Heading>

                <Stack spacing={2}>
                  <HStack>
                    <HStack flex={1}>
                      <Avatar
                        w={8}
                        h={8}
                        src="/images/recruiter-avatar-3.jpg"
                      />

                      <Stack spacing={0}>
                        <Text>Miracle Dokidis</Text>

                        <HStack>
                          <Rating value={3} py={0.5} isDisabled />
                          <Text>Good fit</Text>
                        </HStack>
                      </Stack>
                    </HStack>

                    <Text color="gray.600" textStyle="body2Regular">
                      May 16, 2024
                    </Text>
                  </HStack>

                  <Text color="gray.600">
                    This is a nice review from someone who loves this
                    professional and thinks he is the best freaking professional
                    in the planet but now he badly wants. This is a nice review
                    from someone who loves this professional and thinks he is
                    the best
                  </Text>
                </Stack>

                <Stack spacing={2}>
                  <HStack>
                    <HStack flex={1}>
                      <Avatar
                        w={8}
                        h={8}
                        src="/images/recruiter-avatar-3.jpg"
                      />

                      <Stack spacing={0}>
                        <Text>Miracle Dokidis</Text>

                        <HStack>
                          <Rating value={3} py={0.5} isDisabled />
                          <Text>Good fit</Text>
                        </HStack>
                      </Stack>
                    </HStack>

                    <Text color="gray.600" textStyle="body2Regular">
                      May 16, 2024
                    </Text>
                  </HStack>

                  <Text color="gray.600">
                    This is a nice review from someone who loves this
                    professional and thinks he is the best freaking professional
                    in the planet but now he badly wants. This is a nice review
                    from someone who loves this professional and thinks he is
                    the best
                  </Text>
                </Stack>
              </Stack>
            ) : null}

            <Flex pt={1}>
              <Button
                size="medium"
                lineHeight={6}
                hierarchy="link"
                fontWeight="normal"
                textDecoration="underline"
                onClick={toggleJobDetails}
              >
                Show less details
              </Button>
            </Flex>
          </Stack>
        </Collapse>
      </Stack>
    </HStack>
  );
};

import { Flex } from '@chakra-ui/react';
import debounce from 'lodash/debounce';
import { useCurrentUser } from '../../../../../../../../auth/hooks/useAuth';
import { INITIAL_PAGE_SIZE } from '../../../../../../../../constants';
import { AlertMessage } from '../../../../../../../../helpers/AlertMessage';
import { Loader } from '../../../../../../../../helpers/Loader';
import { CitiesCache } from '../../../../../../../cities/CitiesCache';
import {
  CityFields,
  FetchCitiesFilters,
  FetchCitiesPageSize,
  FetchCitiesSort
} from '../../../../../../../cities/citiesTypes';
import { usePaginatedCities } from '../../../../../../../cities/hooks/usePaginatedCities';
import {
  FetchCitiesResponse,
  fetchCitiesQuery
} from '../../../../../../../cities/queries/fetchCities.query';
import { JobsCityFields } from '../../../../../../../jobs/jobsTypes';
import { TalentPoolFilterPopupSection } from '../../TalentPoolFilterPopupSection';
import { TalentPoolFilterPopupSectionItem } from '../../TalentPoolFilterPopupSectionItem';

export default function TalentPoolFilterPopupLocationsSection() {
  const currentUser = useCurrentUser();

  const {
    cities,
    citiesIsLoading,
    citiesIsFetched,
    citiesErrorMessage,
    changeCitiesFilters
  } = usePaginatedCities<FetchCitiesResponse>({
    query: fetchCitiesQuery,
    cacheKey: CitiesCache.signUpCacheKey(),
    initialPageSize: INITIAL_PAGE_SIZE as FetchCitiesPageSize,
    initialFilters: {
      ...(currentUser.city?.label
        ? {
            [CityFields.LABEL]: {
              operator: 'ilike',
              value: `%${currentUser.city?.label}%`
            }
          }
        : {})
    } as unknown as FetchCitiesFilters,
    initialSort: {
      [CityFields.NAME]: { ascending: true }
    } as unknown as FetchCitiesSort
  });

  const debouncedFilterCities = debounce<(updatedValue: string) => void>(
    (updatedValue) =>
      changeCitiesFilters(
        {
          [JobsCityFields.LABEL]: {
            operator: 'ilike',
            value: `%${updatedValue}%`
          }
        },
        [JobsCityFields.ID]
      ),
    500
  );

  return (
    <TalentPoolFilterPopupSection
      title="Locations"
      isSearchable
      searchInputChange={debouncedFilterCities}
    >
      {citiesIsLoading && (
        <Flex h={20} alignItems="center" justifyContent="center">
          <Loader size={12} loaded={!citiesIsLoading} />
        </Flex>
      )}

      {citiesErrorMessage && <AlertMessage message={citiesErrorMessage} />}

      {citiesIsFetched &&
        cities.map((city) => (
          <TalentPoolFilterPopupSectionItem key={city.id} label={city.label} />
        ))}
    </TalentPoolFilterPopupSection>
  );
}

import { Flex } from '@chakra-ui/react';
import { Button } from '../../../../../../../../helpers/Button';
import { useViewJobCandidateDetailsSubmissionContext } from '../../../../contexts/ViewJobCandidateDetailsSubmissionContext';
import { IsLoading } from '../../../../../../../../types';

interface CustomiseCandidateEvaluationNavProps {
  isLoading: IsLoading;
}

export default function CustomiseCandidateEvaluationNav({
  isLoading
}: CustomiseCandidateEvaluationNavProps) {
  const { closeCandidateEvaluation } =
    useViewJobCandidateDetailsSubmissionContext();

  const onCancel = () => {
    closeCandidateEvaluation();
  };

  const onSave = () => {
    closeCandidateEvaluation();
  };

  return (
    <Flex
      h={16}
      py={3}
      gap={6}
      left={0}
      w="full"
      bottom={0}
      bg="white"
      pos="fixed"
      zIndex={99}
      borderTop="1px solid"
      borderTopColor="gray.200"
      transitionDuration="fast"
      justifyContent="space-between"
      pl={{ lg: 20 }}
    >
      <Flex
        flex={1}
        pl={{ base: 4, xl: 12 }}
        pr={12}
        justifyContent="space-between"
      >
        <Button size="medium" px={8} hierarchy="secondary" onClick={onCancel}>
          Cancel
        </Button>

        <Button size="medium" px={8} onClick={onSave} isLoading={isLoading}>
          Save
        </Button>
      </Flex>
    </Flex>
  );
}

import {
  FetchEducationsCacheKey,
  FetchEducationCacheKey,
  UpdateEducationNanoId,
  UpdateEducationErrorMessage,
  UpdateEducationIsLoading
} from '../../educationsTypes';

import {
  EducationsBffRequests,
  UpdateEducationRequestData,
  UpdateEducationErrorResponse
} from '../../EducationsBffRequests';

import { useUpdateItem } from '../../../common/hooks/useUpdateItem';

interface UpdateEducationOptions {
  educationNanoId: UpdateEducationNanoId;
  cacheKeys?: (FetchEducationsCacheKey | FetchEducationCacheKey)[];
}

function useUpdateEducation({
  educationNanoId,
  cacheKeys = []
}: UpdateEducationOptions) {
  const {
    updateItemData,
    updateItemError,
    updateItemErrorMessage,
    updateItemIsLoading,
    updateItem,
    updateItemReset
  } = useUpdateItem<UpdateEducationRequestData, UpdateEducationErrorResponse>({
    mutationFn: (queryInput) =>
      EducationsBffRequests.updateEducation(educationNanoId, queryInput),
    cacheKeys
  });

  return {
    updateEducationData: updateItemData,
    updateEducationError: updateItemError,
    updateEducationErrorMessage:
      updateItemErrorMessage as UpdateEducationErrorMessage,
    updateEducationIsLoading: updateItemIsLoading as UpdateEducationIsLoading,
    updateEducation: updateItem,
    updateEducationReset: updateItemReset
  };
}

export default useUpdateEducation;

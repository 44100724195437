import { Icon, IconProps } from '@chakra-ui/icon';

export const SmileyIndifferentIcon = ({
  w = '16px',
  h = '16px',
  fill = 'currentColor',
  ...props
}: IconProps) => (
  <Icon viewBox="0 0 24 24" w={w} h={h} fill={fill} {...props}>
    <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z" />
    <circle cx="8.5" cy="10.5" r="1.5" />
    <circle cx="15.493" cy="10.493" r="1.493" />
    <path d="M7.974 15H16v2H7.974z" />
  </Icon>
);

import { Icon, IconProps } from '@chakra-ui/icon';

const ExperienceIcon = ({ w = '48px', h = '48px', ...props }: IconProps) => (
  <Icon viewBox="0 0 48 48" w={w} h={h} fill="none" {...props}>
    <rect width={48} height={48} rx={24} fill="#E1EAED" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34 35.4h1a1 1 0 0 0 1-1v-9a1 1 0 0 0-1-1h-1v11ZM14 34.4v-10h-1a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h1v-1ZM29 16.4h-3v-2h3v2Zm0 4h-3v-2h3v2Zm0 4h-3v-2h3v2Zm0 4h-3v-2h3v2Zm-7-12h-3v-2h3v2Zm0 4h-3v-2h3v2Zm0 4h-3v-2h3v2Zm0 4h-3v-2h3v2Zm9-17H17a1 1 0 0 0-1 1v22a1 1 0 0 0 1 1h6v-4h2v4h6a1 1 0 0 0 1-1v-22a1 1 0 0 0-1-1Z"
      fill="#98A5A9"
    />
  </Icon>
);

export default ExperienceIcon;

import { useDisclosure, Stack, Flex, ButtonGroup, Box } from '@chakra-ui/react';
import React, { useState } from 'react';
import { DeleteIcon } from '../../../../icons/DeleteIcon';
import { EditIcon } from '../../../../icons/EditIcon';
import { Text } from '../../../../helpers/Text';
import { IconButton } from '../../../../helpers/buttons/IconButton';
import { CheckIcon } from '../../../../icons/CheckIcon';
import { InputField } from '../../../../helpers/forms/formFields/InputField';
import {
  JobScorecardQuestionId,
  JobScorecardQuestionName,
  FetchJobScorecardQuestionCacheKey
} from '../../../jobScorecardQuestions/jobScorecardQuestionsTypes';
import { useUpdateJobScorecardQuestion } from '../../../jobScorecardQuestions/hooks/useUpdateJobScorecardQuestion';
import { JobScorecardsCache } from '../../JobScorecardsCache';
import { AlertMessage } from '../../../../helpers/AlertMessage';
import { useDeleteJobScorecardQuestion } from '../../../jobScorecardQuestions/hooks/useDeleteJobScorecardQuestion';
import { JobScorecardState } from '../../jobScorecardsTypes';
import { Rating } from '../../../../helpers/Rating';
import { JobId } from '../../../jobs/jobsTypes';

interface JobScorecardQuestionProps {
  state: JobScorecardState;
  edit?: boolean;
  id: JobScorecardQuestionId;
  name: JobScorecardQuestionName;
  jobId: JobId;
  isEditing?: boolean;
  onEditStart?: () => void;
  onEditEnd?: () => void;
}

const JobScorecardQuestion = ({
  state,
  edit,
  id,
  name,
  jobId,
  isEditing,
  onEditStart,
  onEditEnd
}: JobScorecardQuestionProps) => {
  const {
    updateJobScorecardQuestion,
    updateJobScorecardQuestionIsLoading,
    updateJobScorecardQuestionErrorMessage
  } = useUpdateJobScorecardQuestion({
    jobScorecardQuestionId: id,
    cacheKeys: [
      JobScorecardsCache.jobIndexCacheKey(
        jobId
      ) as unknown as FetchJobScorecardQuestionCacheKey
    ]
  });
  const {
    deleteJobScorecardQuestion,
    deleteJobScorecardQuestionIsLoading,
    deleteJobScorecardQuestionErrorMessage
  } = useDeleteJobScorecardQuestion({
    jobScorecardQuestionId: id,
    cacheKeys: [
      JobScorecardsCache.jobIndexCacheKey(
        jobId
      ) as unknown as FetchJobScorecardQuestionCacheKey
    ]
  });

  const {
    onOpen: onOpenEditTitle,
    isOpen: isOpenEditTitle,
    onClose: onCloseEditTitle
  } = useDisclosure();

  const [updatedTitle, setUpdatedTitle] = useState(name);

  const handleUpdateQuestion = async () => {
    if (name !== updatedTitle) {
      await updateJobScorecardQuestion({
        name: updatedTitle
      });
    }

    onCloseEditTitle();
  };

  return (
    <Stack>
      <AlertMessage
        message={
          updateJobScorecardQuestionErrorMessage ||
          deleteJobScorecardQuestionErrorMessage
        }
      />
      <Flex>
        <>
          {isOpenEditTitle || (!name && state === 'edit') ? (
            <>
              <InputField
                bg="white"
                placeholder="Enter question"
                defaultValue={updatedTitle}
                onChange={(e) => {
                  setUpdatedTitle(e.target.value as JobScorecardQuestionName);
                  onEditStart?.();
                }}
                onBlur={() => {
                  handleUpdateQuestion();
                  onEditEnd?.();
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.stopPropagation();
                    handleUpdateQuestion();
                    onEditEnd?.();
                  }
                }}
              />
            </>
          ) : (
            <>
              <Box width={edit ? 260 : 350}>
                <Text lineHeight={10} flex={1}>
                  {updatedTitle || name}
                </Text>
              </Box>

              <Stack
                direction={'row'}
                h={10}
                flex={state === 'edit' ? 1 : undefined}
              >
                <Rating iconSize={4} value={0} readonly />
              </Stack>
            </>
          )}
        </>

        {state === 'edit' && !isEditing && (
          <ButtonGroup ml="auto">
            <IconButton
              size="small"
              hierarchy="link"
              aria-label="Delete"
              isLoading={deleteJobScorecardQuestionIsLoading}
              icon={<DeleteIcon w={5} h={5} />}
              onClick={deleteJobScorecardQuestion}
            />

            {isOpenEditTitle || (!name && state === 'edit') ? (
              <IconButton
                size="small"
                hierarchy="link"
                aria-label="Edit"
                onClick={handleUpdateQuestion}
                isLoading={updateJobScorecardQuestionIsLoading}
                icon={<CheckIcon fill="gray.500" w={4} h={4} />}
              />
            ) : (
              <IconButton
                size="small"
                hierarchy="link"
                aria-label="Edit"
                onClick={onOpenEditTitle}
                icon={<EditIcon fill="gray.500" w={5} h={5} />}
              />
            )}
          </ButtonGroup>
        )}
      </Flex>
    </Stack>
  );
};

export default JobScorecardQuestion;
